/* eslint-disable @typescript-eslint/naming-convention */
export class righeComposizioni {
  public CODICE_COMPO: string;
  public RIGA_COMPO: number;
  public CODICE_ARTICOLO: string;
  public QUANTITA: number;
  public COLONNE: string;
  public DIML: number;
  public DIMA: number;
  public DIMP: number;
  public UM: string;
  public RDATA1: any;
  public RDATA2: any;
  public RDATA3: any;
  public RDATA4: any;
  public RDATA5: any;


  constructor(
    CODICE_COMPO: string,
    RIGA_COMPO: number,
    CODICE_ARTICOLO: string,
    QUANTITA: number,
    COLONNE: string,
    DIML: number,
    DIMA: number,
    DIMP: number,
    UM: string,
    RDATA1: any,
    RDATA2: any,
    RDATA3: any,
    RDATA4: any,
    RDATA5: any
  ){
    this.CODICE_COMPO = CODICE_COMPO;
    this.RIGA_COMPO = RIGA_COMPO;
    this.CODICE_ARTICOLO = CODICE_ARTICOLO;
    this.QUANTITA = QUANTITA;
    this.COLONNE = COLONNE;
    this.DIML = DIML;
    this.DIMA = DIMA;
    this.DIMP = DIMP;
    this.UM = UM;
    this.RDATA1 = RDATA1;
    this.RDATA2 = RDATA2;
    this.RDATA3 = RDATA3;
    this.RDATA4 = RDATA4;
    this.RDATA5 = RDATA5;
  }
}
