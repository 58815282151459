/* eslint-disable @typescript-eslint/naming-convention */
export class catalogoSingoloStruct {
  public ID_CATALOGO: string;
  public NOME_CATALOGO: string;
  public ID_SHOP: string;
  public WHO_SHOP: string;
  public EXPIRE_DATE: number;
  public TIPO_CATALOGO: number;
  public OWNER_CATALOGO: string;
  public TIMESTAMP_IN: number;
  public ABILITA: number;
  public TIMESTAMP_OUT: number;
  public ID_LISTINO_STORE: string;

  constructor(
    ID_CATALOGO: string,
    NOME_CATALOGO: string,
    ID_SHOP: string,
    WHO_SHOP: string,
    EXPIRE_DATE: number,
    TIPO_CATALOGO: number,
    OWNER_CATALOGO: string,
    TIMESTAMP_IN: number,
    ABILITA: number,
    TIMESTAMP_OUT: number,
    ID_LISTINO_STORE: string
  ) {
    this.ID_CATALOGO = ID_CATALOGO;
    this.NOME_CATALOGO = NOME_CATALOGO;
    this.ID_SHOP = ID_SHOP;
    this.WHO_SHOP = WHO_SHOP;
    this.EXPIRE_DATE = EXPIRE_DATE;
    this.TIPO_CATALOGO = TIPO_CATALOGO;
    this.OWNER_CATALOGO = OWNER_CATALOGO;
    this.TIMESTAMP_IN = TIMESTAMP_IN;
    this.ABILITA = ABILITA;
    this.TIMESTAMP_OUT = TIMESTAMP_OUT;
    this.ID_LISTINO_STORE = ID_LISTINO_STORE;
  }
}
