/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { PersonalizzazioniLTFORMService } from 'src/app/servizi/personalizzazioni-ltform.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-ltform-nuova-associazione',
  templateUrl: './ltform-nuova-associazione.component.html',
  styleUrls: ['./ltform-nuova-associazione.component.scss'],
})
export class LTFORMNuovaAssociazioneComponent implements OnInit {
  associazioni;

  CAT_LT: string;
  COL_PL: string;

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private personalizzazioniService: PersonalizzazioniLTFORMService
  ) { }

  async ngOnInit() {
    this.associazioni = await this.personalizzazioniService.getAssociazioni()
  }

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async doAssociazione(){
    console.log('dati inseriti : CAT_LT = ' + this.CAT_LT + ' E COL_PL = ' + this.COL_PL);
    const doAssocia = await this.personalizzazioniService.doAssociazione(this.CAT_LT, this.COL_PL);
  }

}
