/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { macroTestateStruct } from '../strutture/macroTestate.strutture';
import { righeMacroStruct } from '../strutture/righeMacro.strutture';
import { CommonFunctionService } from './common-function.service';

@Injectable({
  providedIn: 'root',
})
export class MacroService {
  constructor(private common: CommonFunctionService) {}

  //* testate

  async getTestate() {
    const testate = await this.common.getTestateMacro();
    return testate;
  }

  async deleteTestata(codice_macro: string) {
    const response = await this.common.eliminaMacroTestata(codice_macro);
    console.log('repsonse elimina testata', response);
  }

  async newTestata(macro_payload: macroTestateStruct) {
    const response = await this.common.nuovaMacroTestata(macro_payload);
    console.log('response nuova macro testata', response);
  }

  async editTestataMacro(codice_macro: string, des: string, diml: number, dima: number, dimp: number, colonne: number, codalt: string){
    const response = await this.common.editTestataMacro(codice_macro, des, diml, dima, dimp, colonne, codalt);
    console.log('response edit macro testata', response)
  }

  async simula(cod_macro: string, cod_listino: string) {
    const simula = await this.common.simulaMacro(cod_macro, cod_listino);
    return simula;
  }

  async getTestata(codice_testata: string) {
    const testata = await this.common.getTestata(codice_testata);
    console.log('testata', testata);
    return testata;
  }

  //* righe

  async getRighe(codice_testata: string) {
    const righe = await this.common.getMacroRighe(codice_testata);
    return righe;
  }

  async deleteRiga(cod_macro: string, riga_macro: string) {
    const response = await this.common.deleteRigaMacro(cod_macro, riga_macro);
    console.log('response cancella riga', response);
  }

  async doRiga(payload: righeMacroStruct[], codice_testata: string) {
    const response = await this.common.nuovaMacroRiga(payload, codice_testata);
  }

  // //* abilitazioni
  async abilitaMacroT(codice_macro: string) {
    const response = await this.common.doAbilitaMacro(codice_macro);
    console.log('abilita macro', response)
    this.getTestate()
  }

  async disabilitaMacroT(codice_macro: string) {
    const response = await this.common.doDisabilitaMacro(codice_macro);
    console.log('disabilita macro', response)
    this.getTestate()
  }

  async disabilitaTutteMacroT() {
    const response = await this.common.doDisabilitaTutteMacro();
    console.log('disabilita macro tutte', response)
    this.getTestate()
  }

  async abilitaTutteMacroT() {
    const response = await this.common.doAbilitaTutteMacro();
    console.log('abilita macro tutte', response)
    this.getTestate()
  }
}
