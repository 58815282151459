/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { APPID, INPRINT_API, LINGUA } from 'src/app/default-values';
// import { printLog } from 'src/app/lib';
import { CalcoliService } from 'src/app/servizi/calcoli.service';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { ListiniService } from 'src/app/servizi/listini.service';
import { StringheService } from 'src/app/servizi/stringhe.service';
import { calcoliStruct } from 'src/app/strutture/calcoli.strutture';
import { listiniStruct } from 'src/app/strutture/listini.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-nuovo-listino-calcolato',
  templateUrl: './nuovo-listino-calcolato.component.html',
  styleUrls: ['./nuovo-listino-calcolato.component.scss'],
})
export class NuovoListinoCalcolatoComponent implements OnInit {
  listini: listiniStruct[] = [];

  modo_prova: string;

  progressValue: number;
  showProgress = false;
  showForm = true;

  formDoNuovoListinoCalcolato: FormGroup;

  es;

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private formBuilder: FormBuilder,
    private calcoliService: CalcoliService,
    private listiniService: ListiniService,
    private alertCtr: AlertController,
    private globalVar: GlobalVariableService
  ) {}

  async ngOnInit() {
    this.listini = await this.listiniService.getListini();
    this.formDoNuovoListinoCalcolato = this.formBuilder.group({
      listino_start: ['', Validators.required],
      formula: ['', Validators.required],
      arrotondamento: ['', Validators.required],
      listino_end: ['', Validators.required],
      descrizione_list_end: ['', Validators.required],
      modalita_prova: ['', Validators.required],
    });
  }

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async checkCheckBoxvalue(event) {
    console.log(event.checked);
  }

  containsSpecialChars(str) {
    const specialChars = /[ `!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  async doNuovoListinoCalcolato() {
    const formulaCheck = this.formDoNuovoListinoCalcolato.get('formula').value;
    const formula = formulaCheck.replace(',', '.');
    const formulaControl = formula.substring(formula.indexOf("[") + 1, formula.lastIndexOf("]"))
    if(formulaControl == 'prezzo'){
      const checkModoProva =
      this.formDoNuovoListinoCalcolato.get('modalita_prova').value;
    if (checkModoProva === false) {
      this.modo_prova = 'off';
    } else {
      this.modo_prova = 'on';
    }

    const nome_listino =
      this.formDoNuovoListinoCalcolato.get('listino_end').value;
    if (this.containsSpecialChars(nome_listino)) {
      console.log('invalid name, gestisco l errore');
      const alert = await this.alertCtr.create({
        mode: 'ios',
        header: this.translate.instant('A004'),
        subHeader: this.translate.instant('A011'),
        message: this.translate.instant('A012'),
        buttons: [this.translate.instant('001')],
      });
      await alert.present();
    } else {
      const calc_payload = new calcoliStruct(
        formula,
        this.formDoNuovoListinoCalcolato.get('listino_start').value,
        this.formDoNuovoListinoCalcolato.get('arrotondamento').value,
        'ARTCOMB_' + nome_listino.toUpperCase(),
        1,
        this.formDoNuovoListinoCalcolato.get('descrizione_list_end').value,
        this.modo_prova,
        '',
        '',
        ''
      );
      console.log('calc_payload ', calc_payload);

      await this.listiniService.doListinoCalcolatoBg(calc_payload).then(()=>{
        this.modalCtr.dismiss()
      })
    }
    } else {
      const alert = await this.alertCtr.create({
        mode: 'ios',
        message: 'Non è possibile utilizzare le parentesi quadre per indicare elementi che non siano il prezzo',
        buttons: [
          {
            text: 'ok'
          }
        ]
      })
      await alert.present()
    }



  }
}




 /* procedura vecchia con progress */
    //   const chiamataApi =
    //     'https://api.price-list.it/inprintapi/api1/doListinoCalcolatoBackground.php?APPID=' +
    //     APPID +
    //     '&CALC_PAYLOAD=' +
    //     JSON.stringify(calc_payload) +
    //     '&LINGUA=' +
    //     LINGUA +
    //     '&ID_CATALOGO=' +
    //     this.globalVar.getIdCatalogoAttivo() +
    //     '&DEBUG_PROGRESS=off&UUID=' +
    //     this.globalVar.getUUID() +
    //     '';
    //   console.log('calc payload', JSON.stringify(calc_payload));
    //   console.log('api check ', chiamataApi);
    //   this.es = new EventSource(chiamataApi);
    //   console.log('es', this.es);
    //   console.log('url = ', this.es.url);
    //   this.es.addEventListener('message', (e) => {
    //     const result = JSON.parse(e.data);
    //     console.log('result', result);
    //   });
    //   this.es.addEventListener('message', (e) => {
    //     const result = JSON.parse(e.data);
    //     this.progressValue = result.progress / 100;
    //     console.log('result.message = ', result.message);
    //     const report = result.message.split('|').pop();
    //     if (e.lastEventId === 'CLOSE') {
    //       console.log('Received CLOSE closing');
    //       this.es.close();
    //       const pBar = document.getElementById(
    //         'progressbar'
    //       ) as HTMLInputElement;
    //       pBar.value = pBar.max;
    //       window.open(
    //         'https://api.price-list.it/reportPDF/' +
    //           this.globalVar.getIdCatalogoAttivo() +
    //           '/' +
    //           report,
    //         '_blank'
    //       );
    //       if (this.modo_prova === 'on') {
    //         this.showProgress = false;
    //         this.showForm = true;
    //       } else {
    //         this.modalCtr.dismiss();
    //       }
    //       this.listiniService.getListini();
    //     } else {
    //       const pBar = document.getElementById(
    //         'progressbar'
    //       ) as HTMLInputElement;
    //       pBar.value = result.progress;
    //       const perc = document.getElementById('perc');
    //       perc.innerHTML = result.progress + '%';
    //       perc.style.width =
    //         Math.floor(pBar.clientWidth * (result.progress / 100)) + 15 + 'px';
    //     }
    //   });
    //   this.es.addEventListener('error', (e) => {
    //     console.log('Error occurred');
    //     this.es.close();
    //   });
