/* eslint-disable @typescript-eslint/naming-convention */
export class utenteInPrint {
  public EMAIL: string;
  public NOME: string;
  public COGNOME: string;
  public INDIRIZZO: string;
  public CITTA: string;
  public CMB_PROVINCIE: string;
  public CMB_NAZIONI: string;
  public CMB_PROFILO: string;
  public TELEFONO: string;
  public LINGUA: string;
  public LISTINO: string;
  public MODVIEW: string;
  public HASHPWD: string;
  public SCADENZA: number;
  public PRICELIST_FLAG: number;
  public ABILITA_FLAG: number;
  public INVITE_ID: string;
  public NUMERO_INDIRIZZO: string;
  public INTERNO_INDIRIZZO: string;
  public POSTAL_CODE: string;
  public ID_UTENTE: string;
  public CATALOGO_ERRATA: string;
  public ID_CATALOGO_ERRATA: string;
  public ABILITA: string;
  public OWNER_CATALOGO: string;
  public TIPO_CATALOGO: string;
  public SALDO_RICARICHE: string;
  public FOLDER: string;
  public ID_AVATAR: string;
  public LIVELLO_NOTIFICHE: string;
  public UUID: string;
  public TIPO_AUTH: string;
  public INI_DB: string;
  public NOME_CATALOGO: string;
  public ID_SHOP: string;
  public WHO_SHOP: string;
  public TIMESTAMP_IN: string;
  public TIMESTAMP_OUT: string;
  public ID_LISTINO_STORE: string;
  public ID_CATALOGO: string;
  public EXPIRE_DATE: string;
  public TIME_EULA: number;
  public TIME_PRIVACY: number;

  constructor(
    EMAIL: string,
    NOME: string,
    COGNOME: string,
    INDIRIZZO: string,
    CITTA: string,
    CMB_PROVINCIE: string,
    CMB_NAZIONI: string,
    CMB_PROFILO: string,
    TELEFONO: string,
    LINGUA: string,
    LISTINO: string,
    MODVIEW: string,
    HASHPWD: string,
    SCADENZA: number,
    PRICELIST_FLAG: number,
    ABILITA_FLAG: number,
    INVITE_ID: string,
    NUMERO_INDIRIZZO: string,
    INTERNO_INDIRIZZO: string,
    POSTAL_CODE: string,
    ID_UTENTE: string,
    CATALOGO_ERRATA: string,
    ID_CATALOGO_ERRATA: string,
    ABILITA: string,
    OWNER_CATALOGO: string,
    TIPO_CATALOGO: string,
    SALDO_RICARICHE: string,
    FOLDER: string,
    ID_AVATAR: string,
    LIVELLO_NOTIFICHE: string,
    UUID: string,
    TIPO_AUTH: string,
    INI_DB: string,
    NOME_CATALOGO: string,
    ID_SHOP: string,
    WHO_SHOP: string,
    TIMESTAMP_IN: string,
    TIMESTAMP_OUT: string,
    ID_LISTINO_STORE: string,
    ID_CATALOGO: string,
    EXPIRE_DATE: string,
    TIME_EULA: number,
    TIME_PRIVACY: number
  ) {
    this.EMAIL = EMAIL;
    this.NOME = NOME;
    this.COGNOME = COGNOME;
    this.INDIRIZZO = INDIRIZZO;
    this.CITTA = CITTA;
    this.CMB_PROVINCIE = CMB_PROVINCIE;
    this.CMB_NAZIONI = CMB_NAZIONI;
    this.CMB_PROFILO = CMB_PROFILO;
    this.TELEFONO = TELEFONO;
    this.LINGUA = LINGUA;
    this.LISTINO = LISTINO;
    this.MODVIEW = MODVIEW;
    this.HASHPWD = HASHPWD;
    this.SCADENZA = SCADENZA;
    this.PRICELIST_FLAG = PRICELIST_FLAG;
    this.ABILITA_FLAG = ABILITA_FLAG;
    this.INVITE_ID = INVITE_ID;
    this.NUMERO_INDIRIZZO = NUMERO_INDIRIZZO;
    this.INTERNO_INDIRIZZO = INTERNO_INDIRIZZO;
    this.POSTAL_CODE = POSTAL_CODE;
    this.ID_UTENTE = ID_UTENTE;
    this.CATALOGO_ERRATA = CATALOGO_ERRATA;
    this.ID_CATALOGO_ERRATA = ID_CATALOGO_ERRATA;
    this.ABILITA = ABILITA;
    this.OWNER_CATALOGO = OWNER_CATALOGO;
    this.TIPO_CATALOGO = TIPO_CATALOGO;
    this.SALDO_RICARICHE = SALDO_RICARICHE;
    this.FOLDER = FOLDER;
    this.ID_AVATAR = ID_AVATAR;
    this.LIVELLO_NOTIFICHE = LIVELLO_NOTIFICHE;
    this.UUID = UUID;
    this.TIPO_AUTH = TIPO_AUTH;
    this.INI_DB = INI_DB;
    this.NOME_CATALOGO = NOME_CATALOGO;
    this.ID_SHOP = ID_SHOP;
    this.WHO_SHOP = WHO_SHOP;
    this.TIMESTAMP_IN = TIMESTAMP_IN;
    this.TIMESTAMP_OUT = TIMESTAMP_OUT;
    this.ID_LISTINO_STORE = ID_LISTINO_STORE;
    this.ID_CATALOGO = ID_CATALOGO;
    this.EXPIRE_DATE = EXPIRE_DATE;
    this.TIME_EULA = TIME_EULA;
    this.TIME_PRIVACY = TIME_PRIVACY;
  }
}
