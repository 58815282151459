/* eslint-disable @typescript-eslint/naming-convention */
export class macroTestateStruct{
  public COD_MACRO: string;
  public COLONNE_MACRO: string;
  public DES: string;
  public DIML: number;
  public DIMA: number;
  public DIMP: number;
  public CODALT: string;
  public ABILITA: number;
  constructor(
    COD_MACRO: string,
    COLONNE_MACRO: string,
    DES: string,
    DIML: number,
    DIMA: number,
    DIMP: number,
    CODALT: string,
    ABILITA: number
  ){
    this.COD_MACRO = COD_MACRO;
    this.COLONNE_MACRO = COLONNE_MACRO;
    this.DES = DES;
    this.DIML = DIML;
    this.DIMA = DIMA;
    this.DIMP = DIMP;
    this.CODALT = CODALT;
    this.ABILITA = ABILITA;
  }
}
