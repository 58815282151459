/* eslint-disable @typescript-eslint/naming-convention */
export class traduzioniStruct {
  public NOME: string;
  public COGNOME: string;
  public CODICE: string;
  constructor(
    NOME: string,
    COGNOME: string,
    CODICE: string
  ){
    this.NOME = NOME;
    this.COGNOME = COGNOME;
    this.CODICE = CODICE;
  }
}
