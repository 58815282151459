/* eslint-disable @typescript-eslint/naming-convention */
export class calcoliStruct {

  public FORMULA: string;
  public LISTINO_START: string;
  public ARROTONDAMENTO: number;
  public LISTINO_END: string;
  public BACKUP_FLAG: number;
  public DESCRIZIONE_LISTINO_END: string;
  public MODO_PROVA: string;
  public CUSTOM_FIELD_1: string;
  public CUSTOM_FIELD_2: string;
  public CUSTOM_FIELD_3: string;

  constructor(
    FORMULA: string,
    LISTINO_START: string,
    ARROTONDAMENTO: number,
    LISTINO_END: string,
    BACKUP_FLAG: number,
    DESCRIZIONE_LISTINO_END: string,
    MODO_PROVA: string,
    CUSTOM_FIELD_1: string,
    CUSTOM_FIELD_2: string,
    CUSTOM_FIELD_3: string,
  ){
    this.FORMULA = FORMULA;
    this.LISTINO_START = LISTINO_START;
    this.ARROTONDAMENTO = ARROTONDAMENTO;
    this.LISTINO_END = LISTINO_END;
    this.BACKUP_FLAG = BACKUP_FLAG;
    this.DESCRIZIONE_LISTINO_END = DESCRIZIONE_LISTINO_END;
    this.MODO_PROVA = MODO_PROVA;
    this.CUSTOM_FIELD_1 = CUSTOM_FIELD_1;
    this.CUSTOM_FIELD_2 = CUSTOM_FIELD_2;
    this.CUSTOM_FIELD_3 = CUSTOM_FIELD_3;
  }
}
