import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CodificatoreService } from 'src/app/servizi/codificatore.service';
import { codificatoreStruct } from 'src/app/strutture/codificatore.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-aggiungi-campi-codificatore',
  templateUrl: './aggiungi-campi-codificatore.component.html',
  styleUrls: ['./aggiungi-campi-codificatore.component.scss'],
})
export class AggiungiCampiCodificatoreComponent implements OnInit {

  fg: FormGroup;
  @Input() codice: string;
  @Input() descrizione: string;
  @Input() componenti: string;
  @Input() regola: string;

  constructor(public translate: TranslateService,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private cs: CodificatoreService
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      Diml: ['', Validators.required],
      Dima: ['', Validators.required],
      Dimp: ['', Validators.required],
      Pesol: ['', Validators.required],
      Peson: ['', Validators.required],
      Vol: ['', Validators.required],
      Colli: ['', Validators.required],
      Data1: ['', Validators.required],
      Data2: ['', Validators.required],
      Data3: ['', Validators.required],
      Data4: ['', Validators.required]
    })
  }

  async close(){
    await this.modalCtrl.dismiss()
  }

  async doCreaCodice(){
    const row_data = new codificatoreStruct(
      this.codice,
      this.descrizione,
      this.fg.get('Diml').value,
      this.fg.get('Dima').value,
      this.fg.get('Dimp').value,
      this.fg.get('Pesol').value,
      this.fg.get('Peson').value,
      this.fg.get('Vol').value,
      this.fg.get('Colli').value,
      this.fg.get('Data1').value,
      this.fg.get('Data2').value,
      this.fg.get('Data3').value,
      this.fg.get('Data4').value,
      this.componenti,
      0
    )
    console.log(JSON.stringify(row_data))
    await this.cs.creaCodiceCodificatore(this.regola, JSON.stringify(row_data))
    this.modalCtrl.dismiss()
  }

}
