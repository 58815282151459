/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { variantiStruct } from '../strutture/varianti.strutture';
import { CommonFunctionService } from './common-function.service';


@Injectable({
  providedIn: 'root'
})
export class VariantiService {

  varianti: BehaviorSubject<variantiStruct[]> = new BehaviorSubject([]);

constructor(
  private common: CommonFunctionService
) { }

async getAttivazioniVariantiArticolo(codart: string, codvar: string){
  const opzioni = await this.common.getAttivazioniVariantiArticoli(codart, codvar);
  console.log('opzioni arrivate', opzioni);
  return opzioni;
}

async setAttivazioniNeutro(codart: string, codvar: string, codopz: string){
  const response = await this.common.setAttivazioniNeutro(codart, codvar, codopz);
  console.log('response set attivazioni neutro', response);
}

async getVarianti(){
  const variantiDaServer = await this.common.getVarianti();
  this.varianti.next(variantiDaServer);
  return variantiDaServer;
 }

 async nuovaVariante(v_payload: variantiStruct){
  const responseNuovaVariante = await this.common.nuovaVariante(v_payload);
  console.log('response nuova variante', responseNuovaVariante);
  const risp = JSON.stringify(responseNuovaVariante).split(':').pop().replace('}', '');
  console.log(risp);
  if(risp === '"200"'){
    this.getVarianti();
  }
 }

 async getVariante(codvar: string){
  const varianteDaServer = await this.common.getVariante(codvar);
  console.log('response get variante', varianteDaServer);
  return varianteDaServer;
 }

 async editCV(cod_v: string, col_o: string){
  const responseEditCV = await this.common.editCV(cod_v, col_o);
  console.log('response edit cv', responseEditCV);
 }

 async  insCV(cod_v: string, cod_o: string, col: number, val: string){
  const responseInsCV = await this.common.insCV(cod_v, cod_o, col, val);
  console.log('response edit cv', responseInsCV);
 }



}
