import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-codificatore-importazione',
  templateUrl: './codificatore-importazione.component.html',
  styleUrls: ['./codificatore-importazione.component.scss'],
})
export class CodificatoreImportazioneComponent implements OnInit {

  constructor(public translate: TranslateService,) { }

  ngOnInit() {}

}
