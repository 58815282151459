/* eslint-disable @typescript-eslint/naming-convention */
export class utenteErrataStruct {
  public EMAIL: string;
  public NOME: string;
  public COGNOME: string;
  public INDIRIZZO: string;
  public CITTA: string;
  public CMB_PROVINCIE: string;
  public CMB_NAZIONI: string;
  public CMB_PROFILO: string;
  public TELEFONO: string;
  public LINGUA: string;
  public LISTINO: string;
  public MODVIEW: string;
  public HASHPWD: string;
  public SCADENZA: number;
  public PRICELIST_FLAG: number;
  public ABILITA_FLAG: number;
  public INVITE_ID: string;
  public NUMERO_INDIRIZZO: string;
  public INTERNO_INDIRIZZO: string;
  public POSTAL_CODE: string;
  public MULTICAT: number;
  public ID_AVATAR: number;
  public LIVELLO_NOTIFICHE: number;
  public UUID: string;
  public TIPO_AUTH: string;

  constructor(
    EMAIL: string,
    NOME: string,
    COGNOME: string,
    INDIRIZZO: string,
    CITTA: string,
    CMB_PROVINCIE: string,
    CMB_NAZIONI: string,
    CMB_PROFILO: string,
    TELEFONO: string,
    LINGUA: string,
    LISTINO: string,
    MODVIEW: string,
    HASHPWD: string,
    SCADENZA: number,
    PRICELIST_FLAG: number,
    ABILITA_FLAG: number,
    INVITE_ID: string,
    NUMERO_INDIRIZZO: string,
    INTERNO_INDIRIZZO: string,
    POSTAL_CODE: string,
    MULTICAT: number,
    ID_AVATAR: number,
    LIVELLO_NOTIFICHE: number,
    UUID: string,
    TIPO_AUTH: string
  ) {
    this.EMAIL = EMAIL;
    this.NOME = NOME;
    this.COGNOME = COGNOME;
    this.INDIRIZZO = INDIRIZZO;
    this.CITTA = CITTA;
    this.CMB_PROVINCIE = CMB_PROVINCIE;
    this.CMB_NAZIONI = CMB_NAZIONI;
    this.CMB_PROFILO = CMB_PROFILO;
    this.TELEFONO = TELEFONO;
    this.LINGUA = LINGUA;
    this.LISTINO = LISTINO;
    this.MODVIEW = MODVIEW;
    this.HASHPWD = HASHPWD;
    this.SCADENZA = SCADENZA;
    this.PRICELIST_FLAG = PRICELIST_FLAG;
    this.ABILITA_FLAG = ABILITA_FLAG;
    this.INVITE_ID = INVITE_ID;
    this.NUMERO_INDIRIZZO = NUMERO_INDIRIZZO;
    this.INTERNO_INDIRIZZO = INTERNO_INDIRIZZO;
    this.POSTAL_CODE = POSTAL_CODE;
    this.MULTICAT = MULTICAT;
    this.ID_AVATAR = ID_AVATAR;
    this.LIVELLO_NOTIFICHE = LIVELLO_NOTIFICHE;
    this.UUID = UUID;
    this.TIPO_AUTH = TIPO_AUTH;
  }
}
