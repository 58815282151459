import { Injectable } from '@angular/core';
import { CommonFunctionService } from './common-function.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalizzazioniDvoService {

constructor(private common: CommonFunctionService) { }

 async esportazioneDVO(da_modello: string, a_modello: string){
  const response = this.common.esportazioneDVO(da_modello, a_modello)
  console.log('response esportazione DVO', response)
 }

}
