import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
// import { printLog } from 'src/app/lib';
import { RicercaService } from 'src/app/servizi/ricerca.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-ricerca-articoli',
  templateUrl: './ricerca-articoli.component.html',
  styleUrls: ['./ricerca-articoli.component.scss'],
})
export class RicercaArticoliComponent implements OnInit {

  showSearch = false;
  resetSearch = new Subject<void>();

  searchQuery = '';

  results;


  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private ricercaService: RicercaService
  ) { }

  ngOnInit() {}

  async close() {
    const closeModal = '';
    await this.modalCtr.dismiss(closeModal);
  }

  async inputRicerca(e){
    this.ricercaService.ricerca(this.searchQuery).then(response => {
      this.showSearch = true;
      // printLog('response ricerca articoli', response.CODART);
      this.results = response.CODART;
    }).catch(error => {
      this.showSearch = false;
      // printLogerror);
    });
  }

  async codSelected(codice: string){
    this.modalCtr.dismiss(codice);
  }



}
