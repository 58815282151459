/* eslint-disable @typescript-eslint/naming-convention */
export class invitoStruct {
  public ID_INVITO: string;
  public NUMERO_INVITO: number;
  public MAIL_INVITO: string;
  public NOME: string;
  public COGNOME: string;
  public TIMESTAMP_IN: number;
  public TIMESTAMP_OUT: number;
  public STATO: number;
  public ID_USER_INVITO: string;
  public CATALOGO_ERRATA_INVITO: string;

  constructor(
    ID_INVITO: string,
    NUMERO_INVITO: number,
    MAIL_INVITO: string,
    NOME: string,
    COGNOME: string,
    TIMESTAMP_IN: number,
    TIMESTAMP_OUT: number,
    STATO: number,
    ID_USER_INVITO: string,
    CATALOGO_ERRATA_INVITO: string
  ) {
    this.ID_INVITO = ID_INVITO;
    this.NUMERO_INVITO = NUMERO_INVITO;
    this.MAIL_INVITO = MAIL_INVITO;
    this.NOME = NOME;
    this.COGNOME = COGNOME;
    this.TIMESTAMP_IN = TIMESTAMP_IN;
    this.TIMESTAMP_OUT = TIMESTAMP_OUT;
    this.STATO = STATO;
    this.ID_USER_INVITO = ID_USER_INVITO;
    this.CATALOGO_ERRATA_INVITO = CATALOGO_ERRATA_INVITO;
  }
}
