<app-loader></app-loader>
<ion-app>

  <ng-container *ngIf="(isMobile$ | async) === false">

    <ion-split-pane [disabled]="showSideMenu === false" contentId="main-content">

      <ion-menu contentId="main-content">
        <ion-content>
          <ion-list id="inbox-list" class="ion-text-center">
            <div *ngIf="this.utenteArrivato === true && this.utente.MULTICAT === 1">
              <div *ngFor="let page of navigateNoMulticat; let i = index">
                <ion-icon slot="start" color="light" #rla="routerLinkActive" [name]="rla.isActive ? page.iconFilled : page.iconOutline" routerDirection="root" [routerLink]="[page.url]"
                [routerLinkActive]="['active']"></ion-icon>
              </div>
            </div>

            <div *ngIf="this.utenteArrivato === true && this.utente.MULTICAT > 1">
              <div *ngFor="let page of navigateMulticat; let i = index" [matTooltip]="page.title" matTooltipPosition="right" matTooltipClass="custom-tooltip">
                <ion-icon slot="start" color="light" #rla="routerLinkActive" [name]="rla.isActive ? page.iconFilled : page.iconOutline" routerDirection="root" [routerLink]="[page.url]"
                [routerLinkActive]="['active']"></ion-icon>
              </div>
            </div>
          </ion-list>
        </ion-content>
      </ion-menu>

      <ion-router-outlet id="main-content">
      </ion-router-outlet>
    </ion-split-pane>
  </ng-container>
  <ng-container *ngIf="isMobile$ | async">
    <ion-router-outlet id="mobile-content">
    </ion-router-outlet>
  </ng-container>
</ion-app>
