/* eslint-disable @typescript-eslint/naming-convention */
export class comuniStruct {
  public POSTAL_CODE: string;
  public COMUNE_DESCRIZIONE: string;
  public PROVINCIA: string;
  public NAZIONE: string;

  constructor(
    POSTAL_CODE: string,
    COMUNE_DESCRIZIONE: string,
    PROVINCIA: string,
    NAZIONE: string
  ) {
    this.POSTAL_CODE = POSTAL_CODE;
    this.COMUNE_DESCRIZIONE = COMUNE_DESCRIZIONE;
    this.PROVINCIA = PROVINCIA;
    this.NAZIONE = NAZIONE;
  }
}
