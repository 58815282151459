/* eslint-disable @typescript-eslint/naming-convention */
export class errataProgettoStruct {
  public ID_TESTATA_ERRATA: number;
  public ID_CATALOGO_ERRATA: string;
  public ERRATA_JOB: string;
  public DATA_OPEN: number;
  public DATA_CLOSE: number;
  public NOMEFILE: string;
  public FOLDER: number;
  public UUID_USER_OPEN: string;
  public UUID_USER_CLOSE: string;
  public NOTA_TESTATA: string;
  public USER_OPEN: string;
  public USER_CLOSE: string;
  public CHIARIMENTI_APERTI: number;
  public PERCENTUALE_COMPLETAMENTO: number;

  constructor(
    ID_TESTATA_ERRATA: number,
    ID_CATALOGO_ERRATA: string,
    ERRATA_JOB: string,
    DATA_OPEN: number,
    DATA_CLOSE: number,
    NOMEFILE: string,
    FOLDER: number,
    UUID_USER_OPEN: string,
    UUID_USER_CLOSE: string,
    NOTA_TESTATA: string,
    USER_OPEN: string,
    USER_CLOSE: string,
    CHIARIMENTI_APERTI: number,
    PERCENTUALE_COMPLETAMENTO: number
  ) {
    this.ID_TESTATA_ERRATA = ID_TESTATA_ERRATA;
    this.ID_CATALOGO_ERRATA = ID_CATALOGO_ERRATA;
    this.ERRATA_JOB = ERRATA_JOB;
    this.DATA_OPEN = DATA_OPEN;
    this.DATA_CLOSE = DATA_CLOSE;
    this.NOMEFILE = NOMEFILE;
    this.FOLDER = FOLDER;
    this.UUID_USER_OPEN = UUID_USER_OPEN;
    this.UUID_USER_CLOSE = UUID_USER_CLOSE;
    this.NOTA_TESTATA = NOTA_TESTATA;
    this.USER_OPEN = USER_OPEN;
    this.USER_CLOSE = USER_CLOSE;
    this.CHIARIMENTI_APERTI = CHIARIMENTI_APERTI;
    this.PERCENTUALE_COMPLETAMENTO = PERCENTUALE_COMPLETAMENTO;
  }
}
