import { data } from './strutture/changeLog.strutture';
/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes,NavigationStart,NavigationEnd } from '@angular/router';
import { AuthGuard } from './guards/auths-guard.service';


const routes: Routes = [

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'scelta-catalogo',
    loadChildren: () => import('./pages/scelta-catalogo/scelta-catalogo.module').then( m => m.SceltaCatalogoPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'listini',
    loadChildren: () => import('./pages/listini/listini.module').then( m => m.ListiniModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'knowledge-base',
    loadChildren: () => import('./pages/knowledge-base/knowledge-base.module').then( m => m.KnowledgeBasePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'team',
    loadChildren: () => import('./pages/team/team.module').then( m => m.TeamPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'articoli',
    loadChildren: () => import('./pages/articoli/articoli.module').then( m => m.ArticoliPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'macro',
    loadChildren: () => import('./pages/macro/macro.module').then( m => m.MacroPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'macro-righe',
    loadChildren: () => import('./pages/macro/macro-righe/macro-righe.module').then( m => m.MacroRighePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'personalizzazioni',
    loadChildren: () => import('./pages/personalizzazioni/personalizzazioni.module').then( m => m.PersonalizzazioniPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'nuova-intestazione',
    loadChildren: () => import('./dialogs/nuova-intestazione/nuova-intestazione.module').then( m => m.NuovaIntestazionePageModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'traduzione-articoli',
    loadChildren: () => import('./pages/traduzione-articoli/traduzione-articoli.module').then( m => m.TraduzioneArticoliPageModule)
  },
  {
    path: 'composizioni',
    loadChildren: () => import('./pages/composizioni/composizioni.module').then( m => m.ComposizioniPageModule)
  },
  {
    path: 'composizioni-righe',
    loadChildren: () => import('./pages/composizioni/composizioni-righe/composizioni-righe.module').then( m => m.ComposizioniRighePageModule)
  },
  {
    path: 'nuovo-articolo',
    loadChildren: () => import('./dialogs/nuovo-articolo/nuovo-articolo.module').then( m => m.NuovoArticoloPageModule)
  },
  {
    path: 'modelli-dashboard',
    loadChildren: () => import('./pages/deepspace/modelli/modelli-dashboard/modelli-dashboard.module').then( m => m.ModelliDashboardPageModule)
  },
  {
    path: 'opzioni',
    loadChildren: () => import('./pages/deepspace/varianti-e-opzioni/opzioni/opzioni.module').then( m => m.OpzioniPageModule)
  },
  {
    path: 'varianti',
    loadChildren: () => import('./pages/deepspace/varianti-e-opzioni/varianti/varianti.module').then( m => m.VariantiPageModule)
  },
  {
    path: 'deepspace-dashboard',
    loadChildren: () => import('./pages/deepspace/deepspace-dashboard/deepspace-dashboard.module').then( m => m.DeepspaceDashboardPageModule)
  },
  {
    path: 'modelli-init-dashboard',
    loadChildren: () => import('./pages/deepspace/modelli/modelli-init-dashboard/modelli-init-dashboard.module').then( m => m.ModelliInitDashboardPageModule)
  },
  {
    path: 'traduzione-intestazioni',
    loadChildren: () => import('./pages/traduzione-intestazioni/traduzione-intestazioni.module').then( m => m.TraduzioneIntestazioniPageModule)
  },
  {
    path: 'codificatore',
    loadChildren: () => import('./pages/codificatore/codificatore.module').then( m => m.CodificatorePageModule)
  },
  {
    path: 'downloads',
    loadChildren: () => import('./pages/downloads/downloads.module').then( m => m.DownloadsPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'search-result',
    loadChildren: () => import('./pages/search-result/search-result.module').then( m => m.SearchResultPageModule)
  },
  {
    path: 'codici-articolo',
    loadChildren: () => import('./components/search-results-all/codici-articolo/codici-articolo.module').then( m => m.CodiciArticoloPageModule)
  },
  {

    path: 'scheda-articolo',
    loadChildren: () => import('./components/search-results-all/scheda-articolo/scheda-articolo.module').then( m => m.SchedaArticoloPageModule),data: {
      shouldReuse: false
    }
  },
  {
    path: 'descrizioni-articoli',
    loadChildren: () => import('./components/search-results-all/descrizioni-articoli/descrizioni-articoli.module').then( m => m.DescrizioniArticoliPageModule)
  },
  {
    path: 'codici-intestazione',
    loadChildren: () => import('./components/search-results-all/codici-intestazione/codici-intestazione.module').then( m => m.CodiciIntestazionePageModule)
  },
  {
    path: 'descrizioni-intestazioni',
    loadChildren: () => import('./components/search-results-all/descrizioni-intestazioni/descrizioni-intestazioni.module').then( m => m.DescrizioniIntestazioniPageModule)
  },
  {
    path: 'scheda-intestazione',
    loadChildren: () => import('./components/search-results-all/scheda-intestazione/scheda-intestazione.module').then( m => m.SchedaIntestazionePageModule)
  },
  {
    path: 'prezzi',
    loadChildren: () => import('./components/search-results-all/prezzi/prezzi.module').then( m => m.PrezziPageModule)
  },
  {
    path: 'testate-macro',
    loadChildren: () => import('./components/search-results-all/testate-macro/testate-macro.module').then( m => m.TestateMacroPageModule)
  },
  {
    path: 'righe-macro',
    loadChildren: () => import('./components/search-results-all/righe-macro/righe-macro.module').then( m => m.RigheMacroPageModule)
  },
  {
    path: 'testate-composizioni',
    loadChildren: () => import('./components/search-results-all/testate-composizioni/testate-composizioni.module').then( m => m.TestateComposizioniPageModule)
  },
  {
    path: 'righe-composizioni',
    loadChildren: () => import('./components/search-results-all/righe-composizioni/righe-composizioni.module').then( m => m.RigheComposizioniPageModule)
  },
  {
    path: 'blank',
    loadChildren: () => import('./pages/blank/blank.module').then( m => m.BlankPageModule)
  },
  {
    path: 'vault-configuration',
    loadChildren: () => import('./dialogs/vault-configuration/vault-configuration.module').then( m => m.VaultConfigurationPageModule)
  },
  {
    path: 'wpconfig',
    loadChildren: () => import('./pages/wpconfig/wpconfig.module').then( m => m.WpconfigPageModule)
  },
  {
    path: 'rest',
    loadChildren: () => import('./pages/rest/rest.module').then( m => m.RestPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
