<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root"
      [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>{{this.translate.instant('183')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid>
    <ion-row>
      <ion-col>
        <form [formGroup]="fg">
          <ion-item>
            <ion-label>{{this.translate.instant('255')}}</ion-label>
            <ion-input labelPlacement="stacked" formControlName="da"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>{{this.translate.instant('256')}}</ion-label>
            <ion-input labelPlacement="stacked" formControlName="a"></ion-input>
          </ion-item>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-row>
    <ion-col size="12" text-center>
      <ion-button mode='ios' color="primary"  [hidden]="!fg.valid"
        (click)="prosegui()">{{this.translate.instant('160')}}</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
