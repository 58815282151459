<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" (click)="close()"></ion-icon>
    <ion-title>Ricerca articoli</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
       <ion-item>
        <ion-label>Codice articolo</ion-label>
        <ion-input labelPlacement="stacked" type="search" (change)="inputRicerca($event)" [(ngModel)]="searchQuery"></ion-input>
       </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="showSearch === true">
    <ion-row *ngFor="let result of results">
      <ion-col>
        <ion-card (click)="codSelected(result.cod)">
          <ion-card-header>
            {{result.cod}}
          </ion-card-header>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
