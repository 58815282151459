/* eslint-disable @typescript-eslint/naming-convention */
export class righeMacroStruct {
  public CODICE_MACRO: string;
  public RIGA_MACRO: number;
  public CODICE_ARTICOLO: string;
  public QUANTITA: number;
  public COLONNA: string;
  public OPERATORE: string;
  public CODALT: string;
  constructor(
    CODICE_MACRO: string,
    RIGA_MACRO: number,
    CODICE_ARTICOLO: string,
    QUANTITA: number,
    COLONNA: string,
    OPERATORE: string,
    CODALT: string
  ){
    this.CODICE_MACRO = CODICE_MACRO;
    this.RIGA_MACRO = RIGA_MACRO;
    this.CODICE_ARTICOLO = CODICE_ARTICOLO;
    this.QUANTITA = QUANTITA;
    this.COLONNA = COLONNA;
    this.OPERATORE = OPERATORE;
    this.CODALT = CODALT;
  }
}
