import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ControlloFileComposizioniSLComponent } from '../controllo-file-composizioni-sl/controllo-file-composizioni-sl.component';
import { ModalController } from '@ionic/angular';
import { PersonalizzazioniSantaluciaService } from 'src/app/servizi/personalizzazioni-santalucia.service';
import { SantaluciaControlloFileComponent } from '../santalucia-controllo-file/santalucia-controllo-file.component';

@Component({
  selector: 'app-importa-composizioni-sl',
  templateUrl: './importa-composizioni-sl.component.html',
  styleUrls: ['./importa-composizioni-sl.component.scss'],
})
export class ImportaComposizioniSLComponent implements OnInit {
  fileToImport: File;
  setListini: setListini[] = [];

  sceltaListino: boolean = false;

  listinoSelezionato: string = '';

  showBtn: boolean = false;

  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    private personalizzazioni: PersonalizzazioniSantaluciaService
  ) {}

  async ngOnInit() {
    this.setListini = await this.personalizzazioni.getSetListini();
  }

  async importaFile($event: any){
    this.fileToImport = $event.target.files[0];
    this.sceltaListino = true;
    this.showBtn = true;
  }

  async prosegui(){
    const modal = await this.modalCtrl.create({
      component: ControlloFileComposizioniSLComponent,
      backdropDismiss: false,
      componentProps: {
        file: this.fileToImport,
        codListino: this.listinoSelezionato
      }
    });
    await modal.present();
  }
}

export class setListini {
  public CODSET: string;
  public DES: string;

  constructor(CODSET: string, DES: string) {
    this.CODSET = CODSET;
    this.DES = DES;
  }
}

