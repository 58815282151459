<ion-header>
  <ion-toolbar>
    <ion-title>Privacy</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <iframe *ngIf="lingua === 'IT'" #iframe src="https://www.iubenda.com/privacy-policy/64982479" id="iframe"></iframe>
  <iframe *ngIf="lingua === 'UK'" #iframe src="https://www.iubenda.com/privacy-policy/69647388" id="iframe"></iframe>
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-text-center">
    <ion-button (click)="accetta()">Accetta</ion-button>
  </ion-toolbar>
</ion-footer>
