import { Injectable } from '@angular/core';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';

@Injectable({
  providedIn: 'root',
})
export class PersonalizzazioniSantaluciaService {
  constructor(
    private commons: CommonFunctionService,
    private globalVar: GlobalVariableService
  ) {}

  async getSetListini() {
    const listini = await this.commons.getSetListiniSantalucia();
    return listini;
  }

  async setSetListini(cod_set: string, des_set: string) {
    const response = await this.commons.setSetListiniSantalucia(
      cod_set,
      des_set
    );
    return response;
  }

  async doImportaPreflightSANTALUCIA(file: File, cod_listino: string) {
    const responseImporta = await this.commons.doImportaPreflightSANTALUCIA(
      file,
      cod_listino
    );
    // printLog('response importa preflight LTFORM', responseImporta);
    console.log(responseImporta['MESSAGGI'].length);
    if (responseImporta['MESSAGGI'].length === 0) {
      // printLog('non ci sono errori, proseguo con l importazione');
      this.globalVar.setFILE_NAME_IMPORTAZIONE(responseImporta['FILE_IMPORT']);
      console.log('FILENAME set', responseImporta['FILE_IMPORT']);
    } else {
      this.globalVar.setFILE_NAME_IMPORTAZIONE(responseImporta['FILE_IMPORT']);
      // printLog('FILENAME set', responseImporta['FILENAME']);
      this.globalVar.setERRORE_IMPORTAZIONE(responseImporta['MESSAGGI']);
      responseImporta['MESSAGGI'].forEach((element) => {
        console.log(element['FLAG_IMPORT']);
        console.log(element['MESSAGGIO']);
        if (element['FLAG_IMPORT'] !== 'C') {
          // printLog('non ci sono errori critici, posso proseguire');
        } else {
          // printLog('ci sono errori critici, blocco tutto');
        }
      });
    }
  }

  async getAssociazioni() {
    const associazioniArrivateDaApi =
      await this.commons.getAssociazioniSANTALUCIA();
    // printLog('load associazioni ltform', associazioniArrivateDaApi);

    return associazioniArrivateDaApi;
  }

  async doAssociazione(catLT: string, colPL: string) {
    const result = await this.commons.doAssociazioneSANTALUCIA(catLT, colPL);
    // printLog('result do associazione', result);
  }

  async delAssociazione(catLT: string) {
    const result = await this.commons.delAssociazioneSANTALUCIA(catLT);
    // printLog('result del associazione', result);
  }

  async preflightImportaComposizioniSANTALUCIA(file: File) {
    const responseImporta =
      await this.commons.preflightImportaComposizioniSANTALUCIA(file);
    // printLog('response importa preflight LTFORM', responseImporta);
    console.log(responseImporta['MESSAGGI'].length);
    if (responseImporta['MESSAGGI'].length === 0) {
      // printLog('non ci sono errori, proseguo con l importazione');
      this.globalVar.setFILE_NAME_IMPORTAZIONE(responseImporta['FILE_IMPORT']);
      console.log('FILENAME set', responseImporta['FILE_IMPORT']);
    } else {
      this.globalVar.setFILE_NAME_IMPORTAZIONE(responseImporta['FILE_IMPORT']);
      // printLog('FILENAME set', responseImporta['FILENAME']);
      this.globalVar.setERRORE_IMPORTAZIONE(responseImporta['MESSAGGI']);
      responseImporta['MESSAGGI'].forEach((element) => {
        console.log(element['FLAG_IMPORT']);
        console.log(element['MESSAGGIO']);
        if (element['FLAG_IMPORT'] !== 'C') {
          // printLog('non ci sono errori critici, posso proseguire');
        } else {
          // printLog('ci sono errori critici, blocco tutto');
        }
      });
    }
  }
}
