<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root" [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>{{this.translate.instant('243')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col>
      <form autocomplete="off" [formGroup]="fg" (ngSubmit)="doNuovoUtente()">
        <ion-item-group>
          <ion-item-divider>
            <ion-label>{{this.translate.instant('231')}}</ion-label>
          </ion-item-divider>
          <ion-item>
            <ion-label>{{this.translate.instant('232')}}</ion-label>
            <ion-input labelPlacement="stacked" formControlName="mail"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>{{this.translate.instant('233')}}</ion-label>
            <ion-input labelPlacement="stacked" formControlName="nome"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>{{this.translate.instant('234')}}</ion-label>
            <ion-input labelPlacement="stacked" formControlName="cognome"></ion-input>
          </ion-item>
        </ion-item-group>

        <ion-item-group>
          <ion-item-divider>
            <ion-label>{{this.translate.instant('235')}}</ion-label>
          </ion-item-divider>
          <ion-item>
            <ion-label>{{this.translate.instant('236')}}</ion-label>
            <ion-select mode="ios" interface="popover" formControlName="nazione" (ionChange)="getProvince()" [(ngModel)]="nazione_select">
              <ion-select-option *ngFor="let n of nazioni" [value]="n.CODICE_NAZIONE">{{n.DESCRIZIONE_NAZIONE}}</ion-select-option>
            </ion-select>
          </ion-item>
          <div *ngIf="attiva === true">
            <ion-item *ngIf="nazione_select === 'IT' && nazione_select !== 'US'">
              <ion-label>Regione</ion-label>
              <ion-select interface="popover" mode="ios" formControlName="regione">
                <ion-select-option *ngFor="let p of province" [value]="p.CODICE_STATE">{{p.DESCRIZIONE_STATE}}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item *ngIf="nazione_select !== 'IT' && nazione_select === 'US'">
              <ion-label>{{this.translate.instant('242')}}</ion-label>
              <ion-select mode="ios" formControlName="provincia">
                <ion-select-option *ngFor="let p of province" [value]="p.CODICE_STATE">{{p.DESCRIZIONE_STATE}}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item *ngIf="nazione_select !== 'US'">
              <ion-label>Provincia</ion-label>
              <ion-input labelPlacement="stacked" formControlName="provincia"></ion-input>
            </ion-item>
          </div>
          <ion-item>
            <ion-label>{{this.translate.instant('237')}}</ion-label>
            <ion-input labelPlacement="stacked" formControlName="indirizzo"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>{{this.translate.instant('238')}}</ion-label>
            <ion-input labelPlacement="stacked" formControlName="numero_indirizzo"></ion-input>
            <ion-label>{{this.translate.instant('239')}}</ion-label>
            <ion-input labelPlacement="stacked" formControlName="int_indirizzo"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>{{this.translate.instant('240')}}</ion-label>
            <ion-input labelPlacement="stacked" formControlName="citta"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>{{this.translate.instant('241')}}</ion-label>
            <ion-input labelPlacement="stacked" formControlName="cap"></ion-input>
          </ion-item>
        </ion-item-group>
      </form>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' type="submit" color="primary" [disabled]="!fg.valid" (click)="doNuovoUtente()">{{this.translate.instant('160')}}</ion-button>
  </ion-toolbar>
</ion-footer>
