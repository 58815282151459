import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController } from '@ionic/angular';
import { PersonalizzazioniSantaluciaService } from 'src/app/servizi/personalizzazioni-santalucia.service';

@Component({
  selector: 'app-crea-set-santalucia',
  templateUrl: './crea-set-santalucia.component.html',
  styleUrls: ['./crea-set-santalucia.component.scss'],
})
export class CreaSetSantaluciaComponent implements OnInit {
  cod_set: string = '';
  des_set: string = '';

  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    private personalizzazioni: PersonalizzazioniSantaluciaService,
    private alertCtrl: AlertController
  ) {}

  async ngOnInit() {}

  async creaSet() {
    console.log(
      'mi arriva codice ' + this.cod_set + ' con descrizione ' + this.des_set
    );
    const result = await this.personalizzazioni.setSetListini(
      this.cod_set,
      this.des_set
    );
    console.log('result', result['RESULT']);
    if (result['RESULT'] == '200') {
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: '',
        message: this.translate.instant('A023'),
        buttons: ['Ok']
      });
      await alert.present();
      this.modalCtrl.dismiss();
    } else {
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: '',
        message: this.translate.instant('E300'),
        buttons: ['Ok']
      });
      await alert.present();
      this.cod_set = '';
      this.des_set = '';
    }
  }
}
