import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { NuovoListinoCalcolatoComponent } from '../nuovo-listino-calcolato/nuovo-listino-calcolato.component';
import { NuovoListinoComponent } from '../nuovo-listino/nuovo-listino.component';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-scelta-tipo-nuovo-listino',
  templateUrl: './scelta-tipo-nuovo-listino.component.html',
  styleUrls: ['./scelta-tipo-nuovo-listino.component.scss'],
})
export class SceltaTipoNuovoListinoComponent implements OnInit {

  modalDataResponse: any;

  constructor(public translate: TranslateService,
    private modalCtr: ModalController
  ) { }

  ngOnInit() {}

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async sceltaTipoListino(tipo: string){
    // printLog('tipo listino : ', tipo);
    if(tipo === 'vuoto'){
      const modal = await this.modalCtr.create({
        component: NuovoListinoComponent
      });
      modal.onDidDismiss().then((modalDataResponse) => {
        if (modalDataResponse !== null){
          this.modalDataResponse = modalDataResponse.data;
          console.log('Modal Sent Data : ' + modalDataResponse.data);
          this.modalCtr.dismiss()
        }
      });
      return await modal.present();
    } else {
      const modal = await this.modalCtr.create({
        component: NuovoListinoCalcolatoComponent,
        cssClass: 'big'
      });
      modal.onDidDismiss().then((modalDataResponse) => {
        if (modalDataResponse !== null){
          this.modalDataResponse = modalDataResponse.data;
          console.log('Modal Sent Data : ' + modalDataResponse.data);
          this.modalCtr.dismiss()
        }
      });
      return await modal.present();
    }
  }

}
