import { environment } from '../../environments/environment';

export const printLog = (msg: string, params = null) => {
  if (environment.printDebug) {
    if (params !== null) {
      console.log(msg, params);
    } else {
      console.log(msg);
    }
  }
};
