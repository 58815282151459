/* eslint-disable @typescript-eslint/naming-convention */
export class stringheStruct {
  public ID_STRINGA: string;
  public STRINGA: string;
  public LINGUA: string;

  constructor(ID_STRINGA: string, STRINGA: string, LINGUA: string) {
    this.ID_STRINGA = ID_STRINGA;
    this.STRINGA = STRINGA;
    this.LINGUA = LINGUA;
  }
}
