<ion-header>
  <ion-toolbar>
    <ion-title>Nuova composizione</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="formDoNuovaT" (ngSubmit)="doNuovaT()">
    <ion-item>
      <ion-label>Numero ordine</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="cod_compo"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Descrizione</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="des"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Larghezza</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="diml"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Altezza</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="dima"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Profondità</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="dimp"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Data 1</ion-label>
      <ion-textarea formControlName="d1"></ion-textarea>
    </ion-item>
    <ion-item>
      <ion-label>Data 2</ion-label>
      <ion-textarea formControlName="d2"></ion-textarea>
    </ion-item>
    <ion-item>
      <ion-label>Data 3</ion-label>
      <ion-textarea formControlName="d3"></ion-textarea>
    </ion-item>
    <ion-item>
      <ion-label>Data 4</ion-label>
      <ion-textarea formControlName="d4"></ion-textarea>
    </ion-item>
    <ion-item>
      <ion-label>Data 5</ion-label>
      <ion-textarea formControlName="d5"></ion-textarea>
    </ion-item>
      <ion-button mode='ios' type="submit" [disabled]="!formDoNuovaT.valid" color="primary">Crea</ion-button>
  </form>
</ion-content>
