/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { APPID, LINGUA } from 'src/app/default-values';
// import { printLog } from 'src/app/lib';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { ImportaService } from 'src/app/servizi/importa.service';
import { Messaggi } from 'src/app/strutture/importa.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-controllo-file-import',
  templateUrl: './controllo-file-import.component.html',
  styleUrls: ['./controllo-file-import.component.scss'],
})
export class ControlloFileImportComponent implements OnInit, OnDestroy {

  file;

  prosegui: boolean;

  flag_errore: string;
  msg_errore: string;

  c_error: boolean = false;
  w_error: boolean = false;

  errorLabel: string;

  erroriArrivati: Messaggi[] = [];

  file_import: string;
  codListino: string;

  showProgress = false;
  progressValue: number;

  es;

  chiamataApiPreflight;
  chiamataApiImportazione;

  tipoImportazione;

  handlerMessage = '';
  roleMessage = '';

  private destroy$ = new Subject();

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private importService: ImportaService,
    private globalVar: GlobalVariableService,
    private alertCtrl: AlertController
  ) { }

  async ngOnInit() {
    await this.fileControllo();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async close(message = 'Modal Closed') {
    this.modalCtr.dismiss();
  }

  async fileControllo(){
    switch (this.tipoImportazione){
      case 'articoli':
        this.chiamataApiPreflight = this.importService.importaArtPreflight(this.file);
      break;
      case 'prezzi':
        this.chiamataApiPreflight = this.importService.importaPrezziPreflight(this.file, this.codListino);
      break;
      case 'intestazioni':
        this.chiamataApiPreflight = this.importService.importaIntestazioniPreflight(this.file);
      break;
      case 'padreFiglio':
        this.chiamataApiPreflight = this.importService.importaPadreFiglioPreflight(this.file);
      break;
    }
    console.log(this.chiamataApiPreflight);
    await this.chiamataApiPreflight;
    this.erroriArrivati = this.globalVar.getERRORE_IMPORTAZIONE();
    console.log('errori arrivati', this.erroriArrivati)
    if(this.erroriArrivati === null){
      this.errorLabel = this.translate.instant('199');
      this.prosegui = true;
      this.globalVar.setERRORE_IMPORTAZIONE(null);
      this.ngOnDestroy()
    } else {
      this.erroriArrivati.forEach(element=> {
        this.prosegui = false;
        this.flag_errore = element.FLAG_IMPORT;
        if(this.flag_errore === 'C'){
          this.errorLabel = this.translate.instant('197');
          this.c_error = true;
          this.w_error = false;
          this.globalVar.setERRORE_IMPORTAZIONE(null);
          this.ngOnDestroy()
        } else {
          this.errorLabel = this.translate.instant('198');
          this.w_error = true;
          this.c_error = false;
          this.prosegui = true;
          this.globalVar.setERRORE_IMPORTAZIONE(null);
          this.ngOnDestroy()
        }
      });
    }
  }

  async doImporta(){
    this.file_import = this.globalVar.getFILE_NAME_IMPORTAZIONE();
    console.log(this.file_import);
    this.showProgress = true;

    switch(this.tipoImportazione){
      case 'articoli':
        this.chiamataApiImportazione = 'https://api.price-list.it/inprintapi/api1/doImportArtDes.php?APPID=' + APPID + '&ID_CATALOGO=' + this.globalVar.getIdCatalogoAttivo() + '&LINGUA=' + LINGUA + '&FILE_IMPORT=' + this.file_import  +'&UUID=' + this.globalVar.getUUID() + '&DEBUG_PROGRESS=0';
        break;
      case 'prezzi':
        this.chiamataApiImportazione = 'https://api.price-list.it/inprintapi/api1/doImportPrezzi2.php?APPID=' + APPID + '&ID_CATALOGO=' + this.globalVar.getIdCatalogoAttivo() + '&LINGUA=' + LINGUA + '&COD_LISTINO=' + this.codListino + '&FILE_IMPORT=' + this.file_import  +'&UUID=' + this.globalVar.getUUID() + '&DEBUG_PROGRESS=0';
      break;
      case 'intestazioni':
        this.chiamataApiImportazione = 'https://api.price-list.it/inprintapi/api1/doImportIntestazioni.php?APPID=' + APPID + '&ID_CATALOGO=' + this.globalVar.getIdCatalogoAttivo() + '&LINGUA=' + LINGUA + '&FILE_IMPORT=' + this.file_import  +'&UUID=' + this.globalVar.getUUID() + '&DEBUG_PROGRESS=0';
      break;
      case 'padreFiglio':
        this.chiamataApiImportazione = 'https://api.price-list.it/inprintapi/api1/doImportPadreFiglio.php?APPID=' + APPID + '&ID_CATALOGO=' + this.globalVar.getIdCatalogoAttivo() + '&LINGUA=' + LINGUA + '&FILE_IMPORT=' + this.file_import  +'&UUID=' + this.globalVar.getUUID() + '&DEBUG_PROGRESS=0';
      break;
    }
    this.es = new EventSource(this.chiamataApiImportazione);
    console.log(this.es);
    this.es.addEventListener('message', (e)=> {
      const result = JSON.parse(e.data);
      console.log(result);
    });
      this.es.addEventListener('message', async (e) => {
        const result = JSON.parse(e.data);
        this.progressValue = result.progress / 100;
        console.log('to fixed method',(this.progressValue / 100).toFixed(2));
        // printLogresult.message);
    if(e.lastEventId === 'CLOSE') {
        // printLog('Received CLOSE closing');
        this.es.close();
        this.modalCtr.dismiss();
        const pBar = document.getElementById('progressor') as HTMLInputElement;
        pBar.value = pBar.max;
        this.ngOnDestroy();
        const alert = await this.alertCtrl.create({
        mode: 'ios',
          header: this.translate.instant('A007'),
          buttons: [
            {
              text : this.translate.instant('001'),
              role: 'conferma'
            }
          ]
        });
        await alert.present();
        await alert.onDidDismiss().then(()=>{
          this.modalCtr.dismiss()
        })
    } else {
        const pBar = document.getElementById('progressor') as HTMLInputElement;
        pBar.value = result.progress;
        const perc = document.getElementById('percentage');
        if((result.progress).toFixed(0) == 100){
          perc.innerHTML = 'Completamento...'
        } else {
          perc.innerHTML = (result.progress).toFixed(0) + '%';
          perc.style.width = (Math.floor(pBar.clientWidth * (result.progress/100)) + 15) + 'px';
        }
    }
    });

    this.es.addEventListener('error', (e:Error) => {
        console.log('Error occurred', e);
        this.es.close();
    });
  }

}
