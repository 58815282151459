import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { EULAComponent } from '../dialogs/eula/eula.component';
import { PRIVACYComponent } from '../dialogs/privacy/privacy.component';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UtentiService {

constructor(
  private commons:CommonFunctionService,
  private globalvar:GlobalVariableService,
  private modalCtrl:ModalController,
  private router: Router,
  private alertController:AlertController,
  private cookieService: CookieService,
  public translate: TranslateService
  ) { }


async doLogin(username: string, password: string):Promise<boolean> {
  const user = await this.commons.login(username, password);
  this.globalvar.ricordami(username, password);
      console.log('user', user);
      if(user.NOME !== 'error') {
        var check = 0;
        if(user.NOME === 'E411'){
          console.log('controller EULA')
          const modal = await this.modalCtrl.create({
            mode: 'ios',
            component: EULAComponent,
            componentProps: {
              uuid : user.UUID
            }
          })
          await modal.present()
          await modal.onDidDismiss().then(async ()=>{
            await this.doLogin(username, password)
          })
          // dismiss => rilancio il login
        } else {
          check = check + 1;
        }

        if(check === 1){
          if(user.NOME === 'E410'){
            console.log('controller PRIVACY')
            //dismiss => rilancio il login
            const modal = await this.modalCtrl.create({
              mode: 'ios',
              component: PRIVACYComponent,
              componentProps: {
                uuid : user.UUID
              }
            })
            await modal.present()
            await modal.onDidDismiss().then(async ()=>{
              await this.doLogin(username, password)
            })
          } else {
            check = check + 1;
          }
        }

        //this.globalvar.ricordami(username, password);
        if(check === 2){
          this.globalvar.setUTENTE_LOGIN1(user);
          return true
        }
        return true


      } else {
        console.log('credenziali errate')

        return false
      }
}

async presentAlert() {
  const alert = await this.alertController.create({
    mode: 'ios',
    header: 'Attenzione',
    message: 'Credenziali errate, riprova',
    buttons: [{
      text: 'Riprova!',
      role: 'riprova'
  }],
  });

  await alert.present();
  await alert.onDidDismiss().then(() => {
    this.cookieService.deleteAll()
    this.router.navigate(['login'])
  })

}

  userMoveIntoApp() {
  console.log('prosegui il login')
      let user = this.globalvar.getUTENTE_LOGIN()
      if (user.MULTICAT > 1) {
        this.router.navigate(['scelta-catalogo']);
        console.log('passo alla vista multicatalogo')
      } else {
        this.router.navigate(['scelta-catalogo']);
        const catalogoArrivato = user.CATALOGHI_ABILITATI[0];
        console.log('catalogoArrivato',catalogoArrivato);
        this.globalvar.setCATALOGO_ARRIVATO(catalogoArrivato);
      }
}
}


