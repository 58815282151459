<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root" [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>{{this.translate.instant('161')}} {{cod_macro}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-label>{{this.translate.instant('113')}}</ion-label>
      <!-- select listino con pulsante 'simula'-->
    <ion-select interface="popover" placeholder="Seleziona un listino" [(ngModel)]="listinoSelected" (ionChange)="getListinoSelect($event)">
      <ion-select-option *ngFor="let listino of listini">{{listino.COD_LISTINO}}</ion-select-option>
    </ion-select>
    </ion-item>
  </ion-list>
  <!-- mostro la simulazione con campi 'colonna', 'prezzo', 'distinta', 'listino' -->
  <ion-grid *ngIf="simulaResult === true">
    <ion-row class="header-row">
      <ion-col size="2"><b>{{this.translate.instant('099')}}</b></ion-col>
      <ion-col size="2"><b>{{this.translate.instant('102')}}</b></ion-col>
      <ion-col size="4"><b>{{this.translate.instant('114')}}</b></ion-col>
      <ion-col size="4"><b>{{this.translate.instant('101')}}</b></ion-col>
    </ion-row>
    <ion-row *ngFor="let result of results; let i = index;" class="coloredk">
      <ion-col size="2">{{result.COMB}}</ion-col>
      <ion-col size="2">{{result.PREZZO}}</ion-col>
      <ion-col size="4">{{result.DISTINTA}}</ion-col>
      <ion-col size="4">{{result.LISTINO}}</ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
