<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root" [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>Nuova opzione</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="fg" (ngSubmit)="doNuovaOpzione()">

    <ion-item>
      <ion-label>Codice</ion-label>
      <ion-input labelPlacement="stacked" formControlName="CODOPZ"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Descrizione</ion-label>
      <ion-input labelPlacement="stacked" formControlName="DESOPZ"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Materiale</ion-label>
      <ion-select formControlName="MATERIAL">
        <ion-select-option value="LAC">Laccato opaco</ion-select-option>
        <ion-select-option value="LAC">Laccato lucido</ion-select-option>
        <ion-select-option value="CHR">Metallo cromato</ion-select-option>
        <ion-select-option value="MET">Metallo opaco</ion-select-option>
        <ion-select-option value="WOD">Legno</ion-select-option>
        <ion-select-option value="MAR">Marmo/granito</ion-select-option>
        <ion-select-option value="TES">Tessuto</ion-select-option>
        <ion-select-option value="PEL">Pelle</ion-select-option>
        <ion-select-option value="VTR">Vetro</ion-select-option>
        <ion-select-option value="COL">Colore</ion-select-option>
      </ion-select>
    </ion-item>

    <!-- ? in base alla selezione esce una gallery o un picker, nel momento in cui seleziono passo il dato in 'DATA_MATERIAL'-->

    <ion-button mode='ios' type="submit" color="primary" [disabled]="!fg.valid">Prosegui</ion-button>
  </form>
</ion-content>
