/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { composizioniStruct } from 'src/app/strutture/composizioni.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-nuova-testata-composizioni',
  templateUrl: './nuova-testata-composizioni.component.html',
  styleUrls: ['./nuova-testata-composizioni.component.scss'],
})
export class NuovaTestataComposizioniComponent implements OnInit {

  formDoNuovaT: FormGroup;
  modalDataResponse: any;

  constructor(public translate: TranslateService,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private globalVar: GlobalVariableService,
    private router: Router
  ) { }

  ngOnInit() {
    this.formDoNuovaT = this.formBuilder.group({
      cod_compo : ['', Validators.required],
      des : ['', Validators.required],
      diml : ['', Validators.required],
      dima : ['', Validators.required],
      dimp : ['', Validators.required],
      d1: [''],
      d2: [''],
      d3: [''],
      d4: [''],
      d5: ['']
    });
  }

  async close(message = 'Modal Closed') {
    await this.modalCtrl.dismiss(message);
  }


  doNuovaT(){
    const testata = new composizioniStruct(
      this.formDoNuovaT.get('cod_compo').value,
      this.formDoNuovaT.get('des').value,
      this.formDoNuovaT.get('diml').value,
      this.formDoNuovaT.get('dima').value,
      this.formDoNuovaT.get('dimp').value,
      1,
      this.formDoNuovaT.get('d1').value,
      this.formDoNuovaT.get('d2').value,
      this.formDoNuovaT.get('d3').value,
      this.formDoNuovaT.get('d4').value,
      this.formDoNuovaT.get('d5').value
    );
    // printLog('testata composizione', testata);
    this.globalVar.setTESTATA_COMPOSIZIONE(testata);
    this.globalVar.setCODICE_COMPO_T(this.formDoNuovaT.get('cod_compo').value);
    this.close();
    this.router.navigate(['composizioni-righe']);
  }

}
