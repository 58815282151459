import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ViewEulaComponent } from 'src/app/dialogs/view-eula/view-eula.component';
import { ViewPrivacyComponent } from 'src/app/dialogs/view-privacy/view-privacy.component';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import * as defaults from '../../default-values';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  @Input() utente: any;
  @Input() catalogo: string;


  private destroy$ = new Subject();

  currentURL = document.URL

  n_versione = defaults.NUMERO_VERSIONE

  catName: string = ''
  catId: string = ''
  catImg: string = ''

  constructor(public translate: TranslateService,
    private popoverCtrl: PopoverController,
    private globalvar: GlobalVariableService,
    private router: Router,
    private cookieService: CookieService,
    private modalCtrl: ModalController
  ) { }

  async ngOnInit() {
    console.log('current url', this.currentURL)
    console.log('utente', this.utente)
    console.log('utente', this.catalogo)

    this.globalvar.CATALOGO_ATTIVO$.pipe(takeUntil(this.destroy$)).subscribe(async (catalogo) => {
      this.catName = catalogo.UTENTE.NOME_CATALOGO;
        this.catId = catalogo.UTENTE.ID_CATALOGO_ERRATA;
        this.catImg =
          'https://api.price-list.it/images/avatar/' +
          this.catId +
          '.jpg';
    });
  }

  async logout(){
    this.router.navigate(['blank']);
    this.globalvar.setUTENTE_LOGIN1(null);
    this.cookieService.deleteAll();
    this.router.navigate(['login']).then(()=>{
      window.location.reload()
    })
  }

  async eulaModal(){
    const modal = await this.modalCtrl.create({
      mode: 'ios',
      component: ViewEulaComponent
    })
    await modal.present()
  }

  async privacyModal(){
    const modal = await this.modalCtrl.create({
      mode: 'ios',
      component: ViewPrivacyComponent
    })
    await modal.present()
  }

}
