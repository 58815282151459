import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-codificatore-controllo-file',
  templateUrl: './codificatore-controllo-file.component.html',
  styleUrls: ['./codificatore-controllo-file.component.scss'],
})
export class CodificatoreControlloFileComponent implements OnInit {

  constructor(public translate: TranslateService,) { }

  ngOnInit() {}

}
