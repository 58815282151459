import { Component, OnInit } from '@angular/core';
import { ChangelogService } from 'src/app/servizi/changelog.service';
import { CommonFunctionService } from 'src/app/servizi/common-function.service';
import { LayoutService } from 'src/app/servizi/layout.service';
import { changeLogStruct } from 'src/app/strutture/changeLog.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {

  isMobile$ = this.layoutService.isMobile$;
  showSideMenu: boolean = false;
  showTabsMenu: boolean = false;

  catalogoAttivo: boolean = false;
  catalogoImg: string;
  catalogoName: string;

  utente;
  utenteArrivato: boolean = false;

  menuPers: boolean;

  changelog: changeLogStruct[];

  iconsNavigate = [
    {
      title : 'Notifiche',
      url   : '/notifications',
      icon  : 'notifications-outline'
    },
    {
      title : 'Cambia catalogo',
      url   : '/scelta-catalogo',
      icon  : 'grid-outline'
    },
    {
      title : 'Team',
      url   : '/team',
      icon  : 'people-outline'
    },
    {
      title : 'Knowledge base',
      url   : '/knowledge-base',
      icon  : 'help-circle-outline'
    },
    {
      title : 'Impostazioni',
      url   : '/settings',
      icon  : 'cog-outline'
    }
  ];

  iconsNavigateNoMulticat = [
    {
      title : 'Notifiche',
      url   : '/notifications',
      icon  : 'notifications-outline'
    },
    {
      title : 'Team',
      url   : '/team',
      icon  : 'people-outline'
    },
    {
      title : 'Knowledge base',
      url   : '/knowledge-base',
      icon  : 'help-circle-outline'
    },
    {
      title : 'Impostazioni',
      url   : '/settings',
      icon  : 'cog-outline'
    }
  ];

  navigate =
    [
      {
        title : 'Dashboard',
        url   : '/dashboard',
        icon  : 'home'
      },
      {
        title : 'Personalizzazioni',
        url : '/personalizzazioni',
        icon : 'hammer-outline'
      },
      {
        title: 'Files',
        url: '/downloads',
        icon:'folder-outline'
      }
    ];

    navigateNoPers =
    [
      {
        title : 'Dashboard',
        url   : '/dashboard',
        icon  : 'home'
      },
      {
        title: 'Files',
        url: '/downloads',
        icon:'folder-outline'
      },
    ];

  constructor(public translate: TranslateService,
    private layoutService: LayoutService,
    public common: CommonFunctionService,
    private changelogService: ChangelogService
  ) { }

  async ngOnInit() {
    this.changelog = await this.changelogService.getChangelog();
  }

}
