/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { printLog } from '../lib';
import { calcoliStruct } from '../strutture/calcoli.strutture';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';

@Injectable({
  providedIn: 'root'
})
export class CalcoliService {
  catalogoAttivo = null;
  private destroy = new Subject();


  constructor(
    private globalVars: GlobalVariableService,
    private commons: CommonFunctionService
  ) {
    globalVars.CATALOGO_ATTIVO$.pipe(takeUntil(this.destroy)).subscribe(async (catalogoAttivo) => {
      if (catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
        this.catalogoAttivo = catalogoAttivo;
        // printLog('catalogoAttivo', catalogoAttivo);
      } else {
        this.catalogoAttivo = null;
      }
    });
   }

   async doListinoCalcolato(calcoli_payload: calcoliStruct){
    if(this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA){
      const responseListinoCalcolato = await this.commons.doListinoCalcolato(calcoli_payload);
      // printLog('response listino calcolato ', responseListinoCalcolato);
      console.log(responseListinoCalcolato['message'].length);
      // if(responseImportaArticoli['MESSAGGI'].length === 0){
      //   // printLog('non ci sono errori, proseguo con l importazione');
      //   this.globalVar.setFILE_NAME_IMPORTAZIONE(responseImportaArticoli['FILE_IMPORT']);
      // } else {
      //   this.globalVar.setERRORE_IMPORTAZIONE(responseImportaArticoli['MESSAGGI']);
      //   responseImportaArticoli['MESSAGGI'].forEach(element => {
      //     console.log(element['FLAG_IMPORT']);
      //     console.log(element['MESSAGGIO']);
      //     if(element['FLAG_IMPORT'] !== 'C') {
      //       // printLog('non ci sono errori critici, posso proseguire');
      //     } else {
      //       // printLog('ci sono errori critici, blocco tutto');
      //     }
      //   });
      // }
    }
   }
}
