import { Component, OnInit } from '@angular/core';
import { ModalController, ViewDidEnter } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { ListiniService } from 'src/app/servizi/listini.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';
import { MartedesignControlloFileComponent } from '../martedesign-controllo-file/martedesign-controllo-file.component';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-martedesign-importazione',
  templateUrl: './martedesign-importazione.component.html',
  styleUrls: ['./martedesign-importazione.component.scss'],
})
export class MartedesignImportazioneComponent implements OnInit, ViewDidEnter {

  fileToImport: File;
  showBtn: boolean;

  modalDataResponse: any;

  sceltaListino;
  listini: listiniStruct[] = []
  listinoSelezionato: string;

  showForm: boolean;

  private destroy$ = new Subject();

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private listiniService: ListiniService
  ) { }
  ionViewDidEnter(): void {
    this.fileToImport = null;
  }

  async ngOnInit() {
    this.fileToImport = null;
    this.showForm = true;
    this.showBtn = false;
    this.sceltaListino = false;
    this.listinoSelezionato = null;
    console.log('file to import', this.fileToImport)
    this.listini = await this.listiniService.getListini()
  }

  async close() {
    await this.modalCtr.dismiss();
  }

  async showButton(){
    if(this.fileToImport !== undefined){
        console.log(this.listinoSelezionato);
        if(this.listinoSelezionato !== undefined){
          console.log(this.listinoSelezionato);
          this.showBtn = true;
        }
      } else {
        this.showBtn = true;
      }
  }

  async selezionaListino($event: any) {
    this.listinoSelezionato = $event.detail.value.COD_LISTINO;
    // printLog('listino selezionato ', this.listinoSelezionato);
    this.showButton();
  }

  async importaFile($event: any){
    this.fileToImport = $event.target.files[0];
    //printLog('file', this.fileToImport);
    this.showButton();
    this.sceltaListino = true;
  }

  async prosegui(){
    console.log(this.fileToImport)
    const modal = await this.modalCtr.create({
      component: MartedesignControlloFileComponent,
      componentProps: {
        file: this.fileToImport,
        codListino: this.listinoSelezionato
      },
      backdropDismiss: false
    });

    await modal.present().then(()=>{
      this.showForm = false;
      this.showBtn = false;
    })
    modal.onDidDismiss().then((modalDataResponse)=>{
      console.log('data respo', modalDataResponse)
      if(modalDataResponse.data == 'upload'){
        this.modalCtr.dismiss()
      }
      this.ngOnDestroy()
      this.ngOnInit()
      this.fileToImport = null;
    })
  }

  async ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
