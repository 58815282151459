/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { printLog } from '../lib';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';
import { StringheService } from './stringhe.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class IntestazioniService {

  header = '';
  message = '';

  catalogoAttivo = null;

  private destroy = new Subject();

  constructor(
    private commons: CommonFunctionService,
    private globalVars: GlobalVariableService,
    private alertCtrl: AlertController,
    private str: StringheService,
    public translate: TranslateService
  ) {
    // globalVars.CATALOGO_ATTIVO$.pipe(takeUntil(this.destroy)).subscribe(async (catalogoAttivo) => {
    //   if (catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
    //     this.catalogoAttivo = catalogoAttivo;
    //     // printLog('catalogoAttivo', catalogoAttivo);
    //   } else {
    //     this.catalogoAttivo = null;
    //   }
    // });
  }

  async setDescrizioneIntestazione(codiceIntestazione: string, codiceDescrizione: string, valore_des: string, codiceCatalogoStandalone: string = null, uuidStandalone: string = null){
    // if(this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA){
      const responseSetDescrizioneIntestazione = await this.commons.setLinguaIntestazione(codiceIntestazione, codiceDescrizione, valore_des, codiceCatalogoStandalone, uuidStandalone);
      // printLog('response set descrizione intestazione', responseSetDescrizioneIntestazione);
      console.log('response set descrizione intestazione', responseSetDescrizioneIntestazione)
      return responseSetDescrizioneIntestazione;
    // }
  }

  async doCopiaintestazione(cod_start: string, cod_end: string){
    //if(this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA){
      const responseDoCopiaIntestazione = await this.commons.doCopiaIntestazione(cod_start, cod_end);
      // printLog('response do copia intestazione', responseDoCopiaIntestazione);
      const risp = JSON.stringify(responseDoCopiaIntestazione).split(':').pop().replace('}', '');
      if(risp === '"200"'){
        const alert = await this.alertCtrl.create({
mode: 'ios',
          header: this.translate.instant('A019'),
          buttons: [
            {
              text: this.translate.instant('001'),
              role: 'confirm',
            },
          ],
        });
        await alert.present();
      }
      return responseDoCopiaIntestazione;
    //}
  }
}
