<ion-header>
  <ion-toolbar>
    <ion-title>Eula</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <iframe *ngIf="lingua === 'IT'" #iframe src="https://www.iubenda.com/termini-e-condizioni/64982479" id="iframe"></iframe>
  <iframe *ngIf="lingua === 'UK'" #iframe src="https://www.iubenda.com/terms-and-conditions/69647388" id="iframe"></iframe>
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-text-center">
    <ion-text>Eula accettata il: {{utente.TIME_EULA*1000 | date: 'dd/MM/yyyy hh:mm'}}</ion-text>
  </ion-toolbar>
</ion-footer>
