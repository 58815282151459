import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import {
  ArticoliService,
  PrezzoArticolo,
} from '../../servizi/articoli.service';
// import { printLog } from '../../lib';
import { ResultResponse } from '../../servizi/common-function.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-nuovo-prezzo-articolo',
  templateUrl: './nuovo-prezzo-articolo.component.html',
  styleUrls: ['./nuovo-prezzo-articolo.component.scss'],
})
export class NuovoPrezzoArticoloComponent implements OnInit {
  @Input() codiceArticolo: string;
  @Input() codiceListino: string;
  @Input() prezzoArticolo: PrezzoArticolo = null;
  @Input() edit = false;

  formNuovoArticolo: FormGroup;

  constructor(public translate: TranslateService,
    private articoliService: ArticoliService,
    private modalCtr: ModalController,
    private formBuilder: FormBuilder,
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    this.formNuovoArticolo = this.formBuilder.group({
      listino: [this.prezzoArticolo?.LISTINO ?? ''],
      distinta: [this.prezzoArticolo?.DISTINTA ?? ''],
      colonna: [this.prezzoArticolo?.COMB ?? ''],
      prezzo: [this.prezzoArticolo?.PREZZO ?? ''],
    });
  }

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async setPrezzoArticolo() {
    console.log('setPrezzoArticolo');
    const listino = this.formNuovoArticolo.get('listino').value;
    const distinta = this.formNuovoArticolo.get('distinta').value;
    const colonna = this.formNuovoArticolo.get('colonna').value;
    const prezzo = this.formNuovoArticolo.get('prezzo').value;

    if(colonna === '999' || colonna === '9999'){
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: this.translate.instant('A004'),
        message: this.translate.instant('A013'),
        buttons: [
          {
            text: this.translate.instant('001')
          }
        ]
      })
      await (await alert).present();

    } else {
      this.articoliService
      .setPrezzoArticolo(
        this.codiceArticolo,
        this.codiceListino,
        colonna,
        prezzo,
        listino,
        distinta
      )
      .then(async (response) => {
        if (response.RESULT === '200') {
          // printLog('preferenza salvata');
          const alert = await this.alertCtrl.create({
            mode: 'ios',
            header:
              'Prezzo articolo ' +
              this.codiceArticolo +
              ' salvato correttamente',
            buttons: [
              {
                text: 'Conferma',
                role: 'confirm',
              },
            ],
          });
          await alert.present();
          await alert.onDidDismiss();
          this.close('saved');
        } else {
          await this.mostraModaleErrore(response.RESULT);
        }
      })
      .catch(async (error) => {
        await this.mostraModaleErrore(error);
      });
    }


  }

  private async mostraModaleErrore(errore) {
    // printLog('errore');
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      header: 'Errore durante l\'operazione ' + errore,
      buttons: [
        {
          text: 'Conferma',
          role: 'confirm',
        },
      ],
    });
    await alert.present();
  }
}
