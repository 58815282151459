import { Injectable } from '@angular/core';
import { DataManagerService } from './data-manager.service';
import { CommonFunctionService } from './common-function.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectMillaService {
  constructor(private commons: CommonFunctionService) {}

  async setMailFooter(footerTxt: string) {
    const responseSetFooter = await this.commons.setFooterMail(footerTxt);
    console.log('response set milla footer mail', responseSetFooter);
  }

  async getFooterMail() {
    const footer = await this.commons.getFooterMail();
    if (footer != null) {
      return footer['value'];
    } else {
      return '';
    }
  }

  async setWebsiteUrl(website_url: string) {
    const responsesetWebsiteURL = await this.commons.setWebsiteUrl(website_url);
    console.log('response set website url', responsesetWebsiteURL);
  }

  async getWebsiteUrl() {
    const website_url = await this.commons.getWebsiteUrl();
    if (website_url != null) {
      return website_url['value'];
    } else {
      return '';
    }
  }

  async getUtentiAttivi() {
    const utenti = await this.commons.getUtentiAttivi();
    return utenti;
  }

  async editListinoAssegnato(listino: string, code: string, data_validita) {
    const responseEditListino = await this.commons.editListinoAssegnato(
      listino,
      code,
      data_validita
    );
    console.log('response edit listino milla', responseEditListino);
  }

  async removeActivation(code: string) {
    const responseRemoveActivation = await this.commons.removeActivation(code);
    console.log('response edit listino milla', responseRemoveActivation);
  }

  async millaActivation(
    mail: string,
    name: string,
    surname: string,
    listino: string,
    lingua: string,
    rest_key: string,
    data_validita: number,
    modo: string
  ) {
    const responseNuovaAttivazione = await this.commons.millaActivation(
      mail,
      name,
      surname,
      listino,
      lingua,
      rest_key,
      data_validita,
      modo
    );
    console.log('response nuova attivazione milla', responseNuovaAttivazione);
    return responseNuovaAttivazione;
  }

  async doRestKey() {
    const responsedoRestKey = await this.commons.doRestKey();
    console.log('response do rest key', responsedoRestKey);
  }

  async checkRestExsist() {
    const responsecheckRestExsist = await this.commons.checkRestExsist();
    console.log('response check rest key', responsecheckRestExsist);
    return responsecheckRestExsist;
  }

  async doEditUser(nome: string, cognome: string, mail: string, code: string) {
    const responseEditUser = await this.commons.doEditUser(
      nome,
      cognome,
      mail,
      code
    );
    console.log('response edit user', responseEditUser);
  }

  async getUrlSite(){
    const site = await this.commons.getUrlSite();
    return site['value'];
  }

  async getSalesPage(){
    const site = await this.commons.getSalesPage();
    return site['value'];
  }

  async getSaldo(){
    const saldo = await this.commons.getSaldo();
    return saldo;
  }

  async getCPName(){
    const cp_name = await this.commons.getCpName();
    return cp_name['value'];
  }

  async setCpName(cp_name: string){
    const responsesetCpName = await this.commons.setCpName(cp_name);
    console.log('response set cp name', responsesetCpName);
  }

  async checkMailExist(mail: string){
    const responseCheckMail = await this.commons.checkMailExist(mail);
    console.log('response check mail', responseCheckMail);
    return responseCheckMail;
  }
}
