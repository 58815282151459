/* eslint-disable @typescript-eslint/naming-convention */
export class variantiStruct {
  public CODVAR: string;
  public DESVAR: string;
  public ALIAS: string;
  public TRADGEST: string;
  public TIPO_VARIANTE: number;
  public OBBLIGATORIA: number;
  public NOTA: string;
  public ORDINAMENTO: number;
  public OPZ_FIXED: string;
  public COLOPZ: string;

  constructor(
    CODVAR: string,
    DESVAR: string,
    ALIAS: string,
    TRADGEST: string,
    TIPO_VARIANTE: number,
    OBBLIGATORIA: number,
    NOTA: string,
    ORDINAMENTO: number,
    OPZ_FIXED: string,
    COLOPZ: string
  ) {
    this.CODVAR = CODVAR;
    this.DESVAR = DESVAR;
    this.ALIAS = ALIAS;
    this.TRADGEST = TRADGEST;
    this.TIPO_VARIANTE = TIPO_VARIANTE;
    this.OBBLIGATORIA = OBBLIGATORIA;
    this.NOTA = NOTA;
    this.ORDINAMENTO = ORDINAMENTO;
    this.OPZ_FIXED = OPZ_FIXED;
    this.COLOPZ = COLOPZ;
  }
}
