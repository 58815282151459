import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { righeMacroStruct } from 'src/app/strutture/righeMacro.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-modifica-riga',
  templateUrl: './modifica-riga.component.html',
  styleUrls: ['./modifica-riga.component.css']
})
export class ModificaRigaComponent implements OnInit {

  fg!: FormGroup;
  @Input()
  codice_testata: string;
  @Input()
  codice_articolo: string;
  @Input()
  riga_macro: number;
  @Input()
  riga: righeMacroStruct;

  constructor(public translate: TranslateService,
    private fb: FormBuilder,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      qta: [this.riga.QUANTITA, Validators.required],
      colonna: [this.riga.COLONNA, Validators.required],
      operatore: [this.riga.OPERATORE, Validators.required]
    });
  }

  async editRiga(){
    const riga = new righeMacroStruct(
      this.codice_testata,
      this.riga_macro,
      this.codice_articolo,
      this.fg.get('qta')?.value,
      this.fg.get('colonna')?.value,
      this.fg.get('operatore')?.value,
      this.riga.CODALT
    );
    console.log('riga da passare', riga);
    this.modalCtrl.dismiss(riga)
  }

}
