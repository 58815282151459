<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>{{'Copia testata' + ' ' + this.cod_start}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <form [formGroup]="fg">
    <ion-item>
      <ion-label>Testata in cui copiare</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="cod_end"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>{{this.translate.instant('090')}}</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="des_macro"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>{{this.translate.instant('085')}}</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="codalt"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>{{this.translate.instant('107')}}</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="diml"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>{{this.translate.instant('108')}}</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="dima"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>{{this.translate.instant('109')}}</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="dimp"></ion-input>
    </ion-item>
  </form>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' type="submit" [disabled]="!fg.valid" (click)="copiaTestata()">Copia</ion-button>
  </ion-toolbar>
</ion-footer>
