/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalizzazioniAleaService {

  catalogoAttivo = null;
  private destroy = new Subject();

  constructor(
    private common: CommonFunctionService,
    private globalVar: GlobalVariableService
  ) {
    globalVar.CATALOGO_ATTIVO$.pipe(takeUntil(this.destroy)).subscribe(async (catalogoAttivo) => {
      if (catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
        this.catalogoAttivo = catalogoAttivo;
        // printLog('catalogoAttivo', catalogoAttivo);
      } else {
        this.catalogoAttivo = null;
      }
    });
   }

   async doImportaPreflightALEA(file: File, cod_listino: string){
    if(this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA){
      const responseImporta = await this.common.doImportaPreflightALEA(file, cod_listino);
      // printLog('response importa preflight LTFORM', responseImporta);
      console.log(responseImporta['MESSAGGI'].length);
      if(responseImporta['MESSAGGI'].length === 0){
        // printLog('non ci sono errori, proseguo con l importazione');
        this.globalVar.setFILE_NAME_IMPORTAZIONE(responseImporta['FILE_IMPORT']);
        // printLog('FILENAME set', responseImporta['FILENAME']);
      } else {
        this.globalVar.setFILE_NAME_IMPORTAZIONE(responseImporta['FILE_IMPORT']);
        // printLog('FILENAME set', responseImporta['FILENAME']);
        this.globalVar.setERRORE_IMPORTAZIONE(responseImporta['MESSAGGI']);
        responseImporta['MESSAGGI'].forEach(element => {
          console.log(element['FLAG_IMPORT']);
          console.log(element['MESSAGGIO']);
          if(element['FLAG_IMPORT'] !== 'C') {
            // printLog('non ci sono errori critici, posso proseguire');
          } else {
            // printLog('ci sono errori critici, blocco tutto');
          }
        });
      }
    }
   }
}
