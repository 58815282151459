/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import {
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import {
  PreferenzaCatalogo,
  PreferenzeService,
} from 'src/app/servizi/preferenze.service';
import { righeComposizioni } from 'src/app/strutture/righeComposizioni.strutture';
import { RicercaArticoliComponent } from '../ricerca-articoli/ricerca-articoli.component';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-nuova-riga-composizioni',
  templateUrl: './nuova-riga-composizioni.component.html',
  styleUrls: ['./nuova-riga-composizioni.component.scss'],
})
export class NuovaRigaComposizioniComponent implements OnInit {
  fg: FormGroup;
  numero_colonne: number[] = [];

  colonneCatalogo: any;

  codice_arrivato: any;
  codice: boolean = false;

  cod_testata: string = '';

  constructor(
    public translate: TranslateService,
    private modalCtrl: ModalController,
    private preferenzeService: PreferenzeService,
    private fb: FormBuilder,
    private globalVar: GlobalVariableService
  ) {}

  ngOnInit() {
    this.fg = this.fb.group({
      qta: [''],
      colonne: this.fb.array([]),
      diml: [''],
      dima: [''],
      dimp: [''],
      um: [''],
      d1: [''],
      d2: [''],
      d3: [''],
      d4: [''],
      d5: ['']
    });

    this.getNumeroColonne();
    this.getColonneAttive();
  }

  async getColonneAttive() {
    let preferenzeCatalogo =
      await this.preferenzeService.getPreferenzeCatalogo();
    //console.log('preferenze catalogo',preferenzeCatalogo);
    preferenzeCatalogo.forEach(preferenza => {
      if(preferenza.COD_PREFERENZA == 'COLONNE_PRICELIST'){
        let colonne = preferenza.VALORE_PREFERENZA
        //console.log('colonne catalogo', colonne)

        let col = colonne.split(',')
        //console.log('colonne divise', this.colonneCatalogo)



        const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })
        this.colonneCatalogo = col.sort(sortAlphaNum)
      }
    })

  }

  async getNumeroColonne() {
    let preferenze: PreferenzaCatalogo[] =
      await this.preferenzeService.getPreferenzeCatalogo();
    preferenze.forEach((el) => {
      if (el.COD_PREFERENZA === 'QTA_COLONNE_COMPOSIZIONI') {
        console.log('colonne arrivate', el.VALORE_PREFERENZA);
        this.numero_colonne = Array.from(
          { length: Number(el.VALORE_PREFERENZA) },
          (_, index) => index + 1
        );
        //console.log('numero colonne arrivate', this.numero_colonne);

        for (let i = 0; i < this.numero_colonne.length; i++) {
          this.addColonne();
        }
      }
    });
  }

  get campiColonneArray() {
    return this.fg.get('colonne') as FormArray;
  }

  addColonne() {
    const col = this.fb.control('');
    this.campiColonneArray.push(col);
  }

  async close() {
    const closeModal = 'Modal Closed';
    await this.modalCtrl.dismiss(closeModal);
  }

  async ricercaArticoli() {
    const modal = await this.modalCtrl.create({
      component: RicercaArticoliComponent,
    });
    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.codice_arrivato = modalDataResponse.data;
        this.codice = true;
        console.log('Modal Sent Data : ' + modalDataResponse.data);
      }
    });
    return await modal.present();
  }

  async prepareNuovaRiga() {

    if (this.fg.valid) {
      console.log('Dati arrivati:', this.fg.value);

    } else {
      console.log('Form non valida');
    }

    // // printLog('form nuovaR', nuovaR);
  }

  doNuovaRiga(){
    this.cod_testata = this.globalVar.getCODICE_COMPO_T();
    console.log('codice testata', this.cod_testata);
    const nuovaR = new righeComposizioni(
      this.cod_testata,
      0,
      this.codice_arrivato,
      this.fg.get('qta').value,
      this.fg.get('colonne').value,
      this.fg.get('diml').value,
      this.fg.get('dima').value,
      this.fg.get('dimp').value,
      this.fg.get('um').value,
      this.fg.get('d1').value,
      this.fg.get('d2').value,
      this.fg.get('d3').value,
      this.fg.get('d4').value,
      this.fg.get('d5').value
    );
    console.log('payload riga', nuovaR);
    this.modalCtrl.dismiss(nuovaR)
  }


}
