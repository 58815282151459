import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PreferenzaCatalogo, PreferenzaInPrint, PreferenzeService } from '../../servizi/preferenze.service';
// import { printLog } from '../../lib';


import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-edit-preferenza',
  templateUrl: './edit-preferenza.component.html',
  styleUrls: ['./edit-preferenza.component.scss'],
})
export class EditPreferenzaComponent implements OnInit {

  @Input() preferenza: PreferenzaCatalogo;
  @Input() preferenzaInPrint: PreferenzaInPrint;

  formEditPreferenza: FormGroup;

  textarea: boolean = false;
  text: boolean = false;
  timezone: boolean = false;
  list1: boolean = false;

  arrayFromString: any;
  valoreToAdd: string = '';

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private formBuilder: FormBuilder,
    private preferenzeService: PreferenzeService
  ) { }

  ngOnInit() {
    console.log(this.preferenzaInPrint);
    switch(this.preferenzaInPrint.GET_TIPO_GEST){
      case 'TEXTAREA':
        this.textarea = true;
      break;
      case 'TEXT':
        this.text = true;
      break;
      case 'TIMEZONE':
        this.timezone = true;
      break;
      case 'LIST1':
        this.list1 = true;
        this.getArrayFromString();
      break;
    }
    this.formEditPreferenza = this.formBuilder.group({
      valore: [this.preferenza.VALORE_PREFERENZA ?? '', Validators.required],
    });
  }

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async editPreferenza() {
    const modulo = this.preferenzaInPrint.MODULO ?? 'HOME';

    let arrayToStringify = [this.formEditPreferenza.get('valore').value];
    console.log('arrtostring', arrayToStringify);

    await this.preferenzeService.setPreferenzaCatalogo(this.preferenza.COD_PREFERENZA, JSON.stringify(arrayToStringify), modulo, this.preferenzaInPrint.SET_TIPO_GEST).then(()=>{this.modalCtr.dismiss()})
  }

  getArrayFromString(){
    let string = this.preferenza.VALORE_PREFERENZA.replace('"', '').replace('"', '')
    this.arrayFromString = string.split(',')
  }

  async aggiungiValore(){
    if(this.arrayFromString.includes(this.valoreToAdd)){
      console.log('ERRORE! Il campo esiste già')
      this.valoreToAdd = ''
    } else {
      console.log('SUCCESS! Posso pubblicare il campo')
      this.arrayFromString.push(this.valoreToAdd)
      let stringToPass = this.arrayFromString.join(',');
      console.log('string to pass', stringToPass)
      this.valoreToAdd = ''
      let array = [stringToPass];
      console.log('json str of arr', JSON.stringify(array))

      const modulo = this.preferenzaInPrint.MODULO ?? 'HOME';
      await this.preferenzeService.setPreferenzaCatalogo(this.preferenza.COD_PREFERENZA, JSON.stringify(array), modulo, this.preferenzaInPrint.SET_TIPO_GEST).then(()=>{this.modalCtr.dismiss()})
    }
  }

  async eliminaCampo(index: number){
    this.arrayFromString.splice(index, 1);
    console.log(this.arrayFromString)
    let stringToPass = this.arrayFromString.join(',');
    console.log(stringToPass)
    let array = [stringToPass]
    console.log(array)

    const modulo = this.preferenzaInPrint.MODULO ?? 'HOME';
    await this.preferenzeService.setPreferenzaCatalogo(this.preferenza.COD_PREFERENZA, JSON.stringify(array), modulo, this.preferenzaInPrint.SET_TIPO_GEST).then(()=>{this.modalCtr.dismiss()})
  }
}


