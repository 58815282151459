/* eslint-disable @typescript-eslint/naming-convention */
export class changeLogStruct {
  public VERSIONE: string;
  public DATA_RILASCIO: data;
  public NOTE_RILASCIO: string;
  public FLAG_MAIL: number;
  public NUMERO_VERSIONE: number;
  public TIPO: number;

  constructor(
    VERSIONE: string,
    DATA_RILASCIO: data,
    NOTE_RILASCIO: string,
    FLAG_MAIL: number,
    NUMERO_VERSIONE: number,
    TIPO: number
  ) {
    this.VERSIONE = VERSIONE;
    this.DATA_RILASCIO = DATA_RILASCIO;
    this.NOTE_RILASCIO = NOTE_RILASCIO;
    this.FLAG_MAIL = FLAG_MAIL;
    this.NUMERO_VERSIONE = NUMERO_VERSIONE;
    this.TIPO = TIPO;
  }
}

export class data {
  public date: string;
  public timezone_type: number;
  public timezone: string;

  constructor(date: string, timezone_type: number, timezone: string) {
    this.date = date;
    this.timezone_type = timezone_type;
    this.timezone = timezone;
  }
}
