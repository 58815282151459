import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ArticoliService, PrezzoArticolo } from 'src/app/servizi/articoli.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-prezzi-articolo-distinta',
  templateUrl: './prezzi-articolo-distinta.component.html',
  styleUrls: ['./prezzi-articolo-distinta.component.scss'],
})
export class PrezziArticoloDistintaComponent implements OnInit {

  @Input() prezzo: PrezzoArticolo
  @Input() listinoSelezionato: string;
  @Input() codiceArticolo: string;

  fg: FormGroup;

  constructor(public translate: TranslateService,private articoliService: ArticoliService, private modalCtrl: ModalController, private fb: FormBuilder) { }

  ngOnInit() {
    this.fg = this.fb.group({
      distinta: [this.prezzo.DISTINTA]
    })
  }

  close(){
    this.modalCtrl.dismiss()
  }

  async modifica(){
    await this.articoliService.setPrezzoArticolo(this.codiceArticolo, this.listinoSelezionato, this.prezzo.COMB, this.prezzo.PREZZO, this.prezzo.LISTINO, this.fg.get('distinta').value)
    this.close()
  }

}
