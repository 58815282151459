import { Component, OnInit } from '@angular/core';
import { ViewDidEnter } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ListiniService } from 'src/app/servizi/listini.service';
import { PreferenzeService } from 'src/app/servizi/preferenze.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';

@Component({
  selector: 'app-lt-form-esportazione',
  templateUrl: './lt-form-esportazione.component.html',
  styleUrls: ['./lt-form-esportazione.component.scss'],
})
export class LtFormEsportazioneComponent implements OnInit, ViewDidEnter {
  listini: listiniStruct[] = [];
  listinoSelezionato: string = '';

  lingue: string[] = [];
  lingueSelezionate: string[] = [];
  lingueDaInviare: string = '';

  checked: boolean = false;

  showBtn: boolean = false;

  constructor(
    public translate: TranslateService,
    private listiniService: ListiniService,
    private preferenzeService: PreferenzeService
  ) {}

  ngOnInit() {}

  ionViewDidEnter(): void {
    this.getListini();
    this.getLingue();
    this.gestionePulsante();
  }

  async getListini() {
    this.listini = await this.listiniService.getListini();
  }

  getListinoSelezionato(e: any) {
    this.listinoSelezionato = e.detail.value;
    this.gestionePulsante()
  }

  async getLingue() {
    let preferenze = await this.preferenzeService.getPreferenzeCatalogo();
    preferenze.forEach((el) => {
      if (el.COD_PREFERENZA === 'CAMPI_LINGUA') {
        this.lingue = el.VALORE_PREFERENZA.split(',');
      }
    });
  }

  addLingua(e: any, lingua: string) {
    if (e.detail.checked == true) {
      this.lingueSelezionate.push(lingua);
    } else {
      this.lingueSelezionate.forEach((value, index) => {
        if (value == lingua) {
          this.lingueSelezionate.splice(index, 1);
        }
      });
    }
    this.lingueDaInviare = this.lingueSelezionate.join(',');
    this.gestionePulsante()
  }

  gestionePulsante(){
    if(this.listinoSelezionato != undefined && this.lingueDaInviare != ''){
      this.showBtn = true;
    }
  }

  inviaDati(){
    console.log('dati da passare:', this.listinoSelezionato + ' - ' + this.lingueDaInviare)
  }
}
