import { Injectable } from '@angular/core';
import { CommonFunctionService } from './common-function.service';

@Injectable({
  providedIn: 'root'
})
export class ChangelogService {

  constructor(
    private common: CommonFunctionService
  ) { }

  async getChangelog(){
    const changelog = await this.common.getChangelog();
    console.log('changelog', changelog)
    return changelog
  }
}
