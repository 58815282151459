import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CodificatoreService } from 'src/app/servizi/codificatore.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-codificatore-nuova-riga',
  templateUrl: './codificatore-nuova-riga.component.html',
  styleUrls: ['./codificatore-nuova-riga.component.scss'],
})
export class CodificatoreNuovaRigaComponent implements OnInit {

  fg: FormGroup
  @Input() testata: string

  constructor(public translate: TranslateService,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private cs: CodificatoreService
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      cod: ['', Validators.required],
      des: ['', Validators.required],
      valore_in_cod: ['', Validators.required],
      valore_in_des: ['', Validators.required]
    })
  }

  async doNuovaRiga(){
    const codice = this.fg.get('cod').value
    const descrizione = this.fg.get('des').value
    const valore_in_cod = this.fg.get('valore_in_cod').value
    const valore_in_des = this.fg.get('valore_in_des').value

    console.log('dati da passare', this.testata + ' | ' + codice + ' | ' + descrizione + ' | ' + valore_in_cod + ' | ' + valore_in_des);
    await this.cs.doNuovaRigaTipologia(this.testata, codice, descrizione, valore_in_cod, valore_in_des)
    this.modalCtrl.dismiss();
  }

  async close(){
    this.modalCtrl.dismiss()
  }

}
