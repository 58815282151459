import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { TeamService } from 'src/app/servizi/team.service';
import { TraduzioniService } from 'src/app/servizi/traduzioni.service';
import { traduzioniStruct } from 'src/app/strutture/traduzioni.strutture';
import { utenteInPrint } from 'src/app/strutture/utenteInPrint.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-utenti-traduzioni',
  templateUrl: './utenti-traduzioni.component.html',
  styleUrls: ['./utenti-traduzioni.component.scss'],
})
export class UtentiTraduzioniComponent implements OnInit {
  @Input() tipo: string;
  @Input() codice: string;

  mail_to: string = '';

  utentiArrivati: utenteInPrint[] = [];

  payload: traduzioniStruct;

  fg: FormGroup;

  constructor(public translate: TranslateService,
    private traduzioniService: TraduzioniService,
    private alertCtrl: AlertController,
    private teamService: TeamService,
    private fb: FormBuilder,
    private globalVar: GlobalVariableService,
    private modalCtrl: ModalController
  ) {}

  async ngOnInit() {
    this.fg = this.fb.group({
      mail_to: ['', Validators.required],
    });
    this.utentiArrivati = await this.teamService.getUtenti();
    let nome = this.globalVar.getNOME_UTENTE_LOGIN();
    let cognome = this.globalVar.getCOGNOME_UTENTE_LOGIN();
    this.payload = new traduzioniStruct(nome, cognome, this.codice);
  }

  async sendTradRequest() {
    await this.traduzioniService
      .sendTradRequest(this.payload, this.mail_to, this.tipo)
      .then(() => {
        this.presentAlert();
        this.modalCtrl.dismiss();
      });
  }

  async getMailDaLista(mail: string){
    this.mail_to = mail;
    console.log(this.mail_to)
    this.fg = this.fb.group({
      mail_to: [this.mail_to, Validators.required],
    });
  }



  async presentAlert() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      header: this.translate.instant('A015'),
      message: this.translate.instant('A016'),
      buttons: ['ok'],
    });
    await alert.present();
    await alert.onDidDismiss();
  }
}
