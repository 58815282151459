/* eslint-disable @typescript-eslint/naming-convention */
export class chiarimentiStruct {
  public ID: number;
  public ID_ROW_EC: number;
  public UUID_IN: string;
  public UUID_OUT: string;
  public TESTO: string;
  public TIME_IN: number;
  public UTENTE_OUT: number;
  public TIME_OUT: number;
  public DESTINATARIO_CHIARIMENTO: string;
  public TELEFONO_DESTINATARIO: string;
  public MAIL_UTENTE_IN: string;
  public NOME_UTENTE_IN: string;
  public COGNOME_UTENTE_IN: string;
  public MAIL_UTENTE_OUT: string;
  public COGNOME_UTENTE_OUT: string;
  public NOME_UTENTE_OUT: string;

  constructor(
    ID: number,
    ID_ROW_EC: number,
    UUID_IN: string,
    UUID_OUT: string,
    TESTO: string,
    TIME_IN: number,
    UTENTE_OUT: number,
    TIME_OUT: number,
    DESTINATARIO_CHIARIMENTO: string,
    TELEFONO_DESTINATARIO: string,
    MAIL_UTENTE_IN: string,
    NOME_UTENTE_IN: string,
    COGNOME_UTENTE_IN: string,
    MAIL_UTENTE_OUT: string,
    COGNOME_UTENTE_OUT: string,
    NOME_UTENTE_OUT: string
  ) {
    this.ID = ID;
    this.ID_ROW_EC = ID_ROW_EC;
    this.UUID_IN = UUID_IN;
    this.UUID_OUT = UUID_OUT;
    this.TESTO = TESTO;
    this.TIME_IN = TIME_IN;
    this.UTENTE_OUT = UTENTE_OUT;
    this.TIME_OUT = TIME_OUT;
    this.DESTINATARIO_CHIARIMENTO = DESTINATARIO_CHIARIMENTO;
    this.TELEFONO_DESTINATARIO = TELEFONO_DESTINATARIO;
    this.MAIL_UTENTE_IN = MAIL_UTENTE_IN;
    this.NOME_UTENTE_IN = NOME_UTENTE_IN;
    this.COGNOME_UTENTE_IN = COGNOME_UTENTE_IN;
    this.MAIL_UTENTE_OUT = MAIL_UTENTE_OUT;
    this.COGNOME_UTENTE_OUT = COGNOME_UTENTE_OUT;
    this.NOME_UTENTE_OUT = NOME_UTENTE_OUT;
  }
}
