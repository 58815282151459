/* eslint-disable @typescript-eslint/naming-convention */
export class inPrintLogStruct {
  public ID_LOG: number;
  public UUID: string;
  public LOG_MSG_IT: string;
  public LOG_MSG_UK: string;
  public LOG_MSG_FR: string;
  public LOG_MSG_ES: string;
  public TIMESTAMP_IN: number;
  public CATALOGO: string;
  public PROGETTO: string;
  public ID_ROW_EC: number;
  public SUBSYS: string;
  public ID_CATALOGO_ERRATA: string;

  constructor(
    ID_LOG: number,
    UUID: string,
    LOG_MSG_IT: string,
    LOG_MSG_UK: string,
    LOG_MSG_FR: string,
    LOG_MSG_ES: string,
    TIMESTAMP_IN: number,
    CATALOGO: string,
    PROGETTO: string,
    ID_ROW_EC: number,
    SUBSYS: string,
    ID_CATALOGO_ERRATA: string
  ) {
    this.ID_LOG = ID_LOG;
    this.UUID = UUID;
    this.LOG_MSG_IT = LOG_MSG_IT;
    this.LOG_MSG_UK = LOG_MSG_UK;
    this.LOG_MSG_FR = LOG_MSG_FR;
    this.LOG_MSG_ES = LOG_MSG_ES;
    this.TIMESTAMP_IN = TIMESTAMP_IN;
    this.CATALOGO = CATALOGO;
    this.PROGETTO = PROGETTO;
    this.ID_ROW_EC = ID_ROW_EC;
    this.SUBSYS = SUBSYS;
    this.ID_CATALOGO_ERRATA = ID_CATALOGO_ERRATA;
  }
}
