/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { APPID, LINGUA } from 'src/app/default-values';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { PersonalizzazioniAleaService } from 'src/app/servizi/personalizzazioni-alea.service';
import { PersonalizzazioniMartedesignService } from 'src/app/servizi/personalizzazioni-martedesign.service';
import { Messaggi } from 'src/app/strutture/importa.strutture';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-alea-controllo-file',
  templateUrl: './alea-controllo-file.component.html',
  styleUrls: ['./alea-controllo-file.component.scss'],
})
export class AleaControlloFileComponent implements OnInit, OnDestroy {

  showProgress = false;
  progressValue: number;

  file;
  codListino: string;

  es;

  prosegui: boolean;

  flag_errore: string;
  msg_errore: string;

  c_error: boolean;
  w_error: boolean;

  errorLabel: string;

  erroriArrivati: Messaggi[] = [];

  file_import: string;

  private destroy$ = new Subject();

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private personalizzazioniService: PersonalizzazioniAleaService,
    private globalVar: GlobalVariableService,
  ) { }

  async ngOnInit() {
    await this.fileControllo();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async fileControllo() {
    this.erroriArrivati = [];
    await this.personalizzazioniService.doImportaPreflightALEA(
      this.file,
      this.codListino
    );
    this.erroriArrivati = this.globalVar.getERRORE_IMPORTAZIONE();
    console.log(this.erroriArrivati);
    if (this.erroriArrivati === null) {
      this.errorLabel = this.translate.instant('199');
      // printLog('mostro il pulsante per proseguire limportazione');
      this.prosegui = true;
      this.globalVar.setERRORE_IMPORTAZIONE(null);
    } else {
      this.erroriArrivati.forEach((element) => {
        this.prosegui = false;
        this.flag_errore = element.FLAG_IMPORT;
        if (this.flag_errore === 'C') {
          this.errorLabel = this.translate.instant('197');
          this.c_error = true;
          this.w_error = false;
          this.globalVar.setERRORE_IMPORTAZIONE(null);
          this.ngOnDestroy();
        } else {
          this.errorLabel = this.translate.instant('199');
          this.w_error = true;
          this.c_error = false;
          this.prosegui = true;
          this.globalVar.setERRORE_IMPORTAZIONE(null);
          this.ngOnDestroy();
        }
      });
    }
  }

  async doImporta() {
    this.file_import = this.globalVar.getFILE_NAME_IMPORTAZIONE();
    console.log(this.file_import);
    this.showProgress = true;
    const chiamataApi =
      'https://api.price-list.it/inprintapi/api1/pers/alea/doImportaAlea2.php?APPID=' +
      APPID +
      '&ID_CATALOGO=' +
      this.globalVar.getIdCatalogoAttivo() +
      '&LINGUA=' +
      LINGUA +
      '&FILE_IMPORT=' +
      this.file_import +
      '&UUID=' +
      this.globalVar.getUUID() +
      '&DEBUG_PROGRESS=0&COD_LISTINO=' +
      this.codListino;
    this.es = new EventSource(chiamataApi);
    this.es.addEventListener('message', (e) => {
      const result = JSON.parse(e.data);
      console.log(result);
    });
    this.es.addEventListener('message', (e) => {
      const result = JSON.parse(e.data);
      this.progressValue = result.progress / 100;
      console.log(this.progressValue / 100);
      // printLogresult.message);
      if (e.lastEventId === 'CLOSE') {
        // printLog('Received CLOSE closing');
        this.es.close();
        this.modalCtr.dismiss();
        const pBar = document.getElementById('progressor') as HTMLInputElement;
        pBar.value = pBar.max;
      } else {
        const pBar = document.getElementById('progressor') as HTMLInputElement;
        pBar.value = result.progress;
        const perc = document.getElementById('percentage');
        perc.innerHTML = (result.progress).toFixed(0) + '%';
        perc.style.width =
          Math.floor(pBar.clientWidth * (result.progress / 100)) + 15 + 'px';
      }
    });
    this.es.addEventListener('error', (e) => {
      // printLog('Error occurred');
      this.es.close();
    });
  }

}
