/* eslint-disable @typescript-eslint/naming-convention */
export class utenteComponentsStruct {
  public UTENTE: string;
  public FIELD: string;
  public CONTENT: string;

  constructor(UTENTE: string, FIELD: string, CONTENT: string) {
    this.UTENTE = UTENTE;
    this.FIELD = FIELD;
    this.CONTENT = CONTENT;
  }
}
