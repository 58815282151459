<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root" [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>Aggiungi campi</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col>
      <form autocomplete="off" [formGroup]="fg" (ngSubmit)="doCreaCodice()">
        <ion-item-group>
          <ion-item-divider>
            <ion-label>Dimensioni</ion-label>
          </ion-item-divider>
          <ion-item>
            <ion-label>Larghezza</ion-label>
            <ion-input labelPlacement="stacked" formControlName="Dima"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Altezza</ion-label>
            <ion-input labelPlacement="stacked" formControlName="Diml"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Profondità</ion-label>
            <ion-input labelPlacement="stacked" formControlName="Dimp"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Peso lordo</ion-label>
            <ion-input labelPlacement="stacked" formControlName="Pesol"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Peso netto</ion-label>
            <ion-input labelPlacement="stacked" formControlName="Peson"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Volume</ion-label>
            <ion-input labelPlacement="stacked" formControlName="Vol"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Colli</ion-label>
            <ion-input labelPlacement="stacked" formControlName="Colli"></ion-input>
          </ion-item>
        </ion-item-group>

        <ion-item-group>
          <ion-item-divider>
            <ion-label>Data</ion-label>
          </ion-item-divider>
          <ion-item>
            <ion-label>Data 1</ion-label>
            <ion-input labelPlacement="stacked" formControlName="Data1"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Data2</ion-label>
            <ion-input labelPlacement="stacked" formControlName="Data2"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Data3</ion-label>
            <ion-input labelPlacement="stacked" formControlName="Data3"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Data4</ion-label>
            <ion-input labelPlacement="stacked" formControlName="Data4"></ion-input>
          </ion-item>
        </ion-item-group>


      </form>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' type="submit" color="primary" [disabled]="!fg.valid" (click)="doCreaCodice()">Prosegui</ion-button>
  </ion-toolbar>
</ion-footer>
