import {
  Component,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { RicercaService } from '../../servizi/ricerca.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observer, Subject } from 'rxjs';
import { state } from '@angular/animations';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { SearchResultComponent } from '../search-result-old/search-result-old.component';
import { IonGrid, IonSearchbar } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctionService } from 'src/app/servizi/common-function.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Output()
  showSearchResults = new EventEmitter<boolean>();

  searchQuery: string = '';

  show: boolean = false;

  destroy$ = new Subject();

  constructor(
    public translate: TranslateService,
    private ricercaService: RicercaService,
    private router: Router,
    private common: CommonFunctionService,
    private globalvar: GlobalVariableService
  ) {
    this.router.events.subscribe((val)=>{
      if(val instanceof NavigationStart === true){
      }
    })
  }

  ngOnInit() {
  }

  async ricerca() {
    setTimeout(() => {
      this.show = true;
      console.log('search query',this.searchQuery);
      this.common.ricerca2(this.searchQuery).subscribe((risultatoRicerca)=>{
        console.log('risultato ricerca', risultatoRicerca)

        if(risultatoRicerca){
          console.log('Browser is Safari', window.navigator.userAgent.includes('Safari'))
          if(window.navigator.userAgent.includes('Safari') && this.router.url.includes('search-result')){
            console.log('Sono sulla stessa route con Safari')
            this.router.navigate(['dashboard']).then(()=>{
              this.router.navigate(['search-result'])
            })
          } else {
            this.router.navigate(['search-result']);
          }
        } else {
          console.log('non ho il risultato')
        }
      })
    }, 500);
  }

  reset() {
    this.show = false;
    this.searchQuery = '';
    this.showSearchResults.next(false);
  }


}
