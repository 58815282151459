<ion-grid>
  <ion-row>
    <ion-col size="4"></ion-col>
    <ion-col size="4" class="ion-text-center">
      <ion-item lines="none" class="item">
        <ion-label id="label">{{codiceIntestazione}}</ion-label>
        <ion-button slot="end" color="primary" mode="ios" size="small" (click)="copia()">{{this.translate.instant('088')}}</ion-button>
      </ion-item>
    </ion-col>
    <ion-col size="4">
      <ion-button mode="ios" color="primary" (click)="nuovaIntestazione()"><ion-icon name="add-outline"></ion-icon>{{this.translate.instant('014')}}</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>


<ion-content>
  <ion-textarea name="message" id="message" rows="10" cols="60" [(ngModel)]="linguePerViewer[linguaEdit][getNomeDescrizione(index, linguaEdit)]"></ion-textarea>
  <ion-button mode='ios' color="primary"  (click)="salvaDescrizione(getNomeDescrizione(index, linguaEdit), linguePerViewer[linguaEdit][getNomeDescrizione(index, linguaEdit)])">Salva</ion-button>
</ion-content>
