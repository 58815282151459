import { Injectable } from '@angular/core';
import { testateTipologie } from '../strutture/testateTipologie.strutture';
import { CommonFunctionService } from './common-function.service';

@Injectable({
  providedIn: 'root'
})
export class CodificatoreService {

  constructor(
    private common: CommonFunctionService
  ) { }

  //* tipologie
  async getTestateTipologie(){
    const testate = await this.common.getTestateTipologie();
    console.log('testate', testate);
    return testate;
  }

  async doTestataTipologia(nome_tipologia: string){
    const response = await this.common.doTestataTipologia(nome_tipologia);
    console.log('response nuova testata', response)
  }

  async getTipologia(cod_tipologia: string){
    const tipologia = await this.common.getTipologia(cod_tipologia);
    //console.log('tipologia', tipologia);
    return tipologia;
  }

  async copiaTipologia(cod_tipo_start: string,cod_tipo_end: string){
    const response = await this.common.copiaTipologia(cod_tipo_start, cod_tipo_end);
    console.log('response copia tipologia', response)
  }

  async doNuovaRigaTipologia(cod_tipologia:string, cod: string, des: string, valore_in_codice: string, valore_in_descrizione: string){
    const response = await this.common.doNuovaRigaTipologia(cod_tipologia, cod, des, valore_in_codice, valore_in_descrizione)
    console.log('response do nuova riga', response)
  }

  async getRigaTipologia(cod_tipologia: string, cod_riga: string){
    const riga = await this.common.getRigaTipologia(cod_tipologia, cod_riga);
    console.log('riga', riga)
    return riga;
  }

  async cancellaRiga(cod_tipologia: string, cod_riga: string){
    const response = await this.common.cancellaRigaTipologia(cod_tipologia, cod_riga);
    console.log('response cancella', response)
  }

  async modificaRiga(cod_tipologia: string, codice: string, descrizione: string, valore_in_codice: string, valore_in_descrizione: string){
    const response = await this.common.modificaRigaTipologia(cod_tipologia, codice, descrizione, valore_in_codice, valore_in_descrizione)
    console.log('response modifica riga', response)
  }

  //* regole
  async nuovaRegola(cod_regola: string, regola_string: string[]){
    const response = await this.common.nuovaRegola(cod_regola, regola_string)
    console.log('response nuova regola', response)
  }

  async cancellaRegola(cod_regola: string){
    const response = await this.common.cancellaRegola(cod_regola)
    console.log('response cancella regola', response)
  }

  //* codifica
  async getCodiciCodificatore(cod_regola: string){
    const codici = await this.common.getCodiciCodificatore(cod_regola);
    console.log('response get codici codificatore', codici);
    return codici;
  }

  async creaCodiceCodificatore(codice_regola: string, row_data: string){
    const response = await this.common.creaCodiceCodificatore(codice_regola, row_data);
    console.log('response crea codice codificatore', response)
  }

  async importaRegolaCodificatore(codice_regola: string){
    const response = await this.common.importaRegolaCodificatore(codice_regola);
    console.log('response importa regola codificatore', response)
  }

}
