import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalVariableService } from './global-variable.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService implements OnDestroy {

  isMobile$ = new BehaviorSubject(false);

  private destroy$ = new Subject();

  constructor(public breakpointObserver: BreakpointObserver,private globalVar:GlobalVariableService) {
    this.breakpointObserver
      .observe('(max-width: 991px)')
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        console.log('state', state);
      //  this.isMobile$.next(state.matches);


      this.globalVar.setIS_MOBILE(state.matches);
      if(this.globalVar.getIS_MOBILE() === true) {

        console.log('l\'app è mobile')
        this.isMobile$ = new BehaviorSubject(true);

      }
      else
      {
        console.log('l\'app è desktop')
        this.isMobile$ = new BehaviorSubject(false);


      }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
