<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" (click)="close()" ></ion-icon>
    <ion-title>{{this.translate.instant('182')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="6" class="ion-text-center">
        <ion-button mode='ios'  color="secondary" class="btn" text-wrap (click)="importa()">{{this.translate.instant('041')}}</ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6" class="ion-text-center">
        <ion-button mode='ios'  color="secondary" class="btn" text-wrap (click)="esporta()">{{this.translate.instant('184')}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
