/* eslint-disable @typescript-eslint/naming-convention */
export class nazioniStruct {
  public CODICE_NAZIONE: string;
  public DESCRIZIONE_NAZIONE: string;
  public STATE_FLAG: number;

  constructor(
    CODICE_NAZIONE: string,
    DESCRIZIONE_NAZIONE: string,
    STATE_FLAG: number
  ) {
    this.CODICE_NAZIONE = CODICE_NAZIONE;
    this.DESCRIZIONE_NAZIONE = DESCRIZIONE_NAZIONE;
    this.STATE_FLAG = STATE_FLAG;
  }
}
