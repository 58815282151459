<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>{{this.translate.instant('101')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-grid>
  <ion-row>
    <ion-col>
      <form [formGroup]="fg">
        <ion-textarea formControlName="listino" [autoGrow]="true">
        </ion-textarea>
      </form>

    </ion-col>
  </ion-row>
</ion-grid>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button (click)="modifica()">{{this.translate.instant('117')}}</ion-button>
  </ion-toolbar>
</ion-footer>
