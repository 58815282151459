import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { macroTestateStruct } from 'src/app/strutture/macroTestate.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-nuova-testata',
  templateUrl: './nuova-testata.component.html',
  styleUrls: ['./nuova-testata.component.css']
})
export class NuovaTestataComponent implements OnInit {
  fg: FormGroup;
  modalDataResponse: any;

  cod_macro: string;

  constructor(public translate: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private modal: ModalController,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      cod_macro: ['', Validators.required],
      des_macro: [''],
      codalt: [''],
      diml: [''],
      dima: [''],
      dimp: [''],
      colonne: ['', Validators.required]
    });
  }

  containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  async doTestata(){
    let colonne = this.fg.get('colonne').value.split(',');
    let hasSameElement = (new Set(colonne)).size !== colonne.length;
    console.log('result', hasSameElement)
    if(hasSameElement === true){
      const alert = await this.alertCtrl.create({
        mode: 'ios',
          header: this.translate.instant('A004'),
          message: this.translate.instant('A022'),
          buttons: [
            {
              text: this.translate.instant('001')
            }
          ]
      })
      await alert.present();
    } else {
      console.log('ho passato il controllo, vado avanti')
      if(this.containsSpecialChars(this.fg.get('cod_macro').value)){
        console.log('contiene caratteri speciali, alert')
        const alert = await this.alertCtrl.create({
          mode: 'ios',
          header: this.translate.instant('A004'),
          message: this.translate.instant('A010'),
          buttons: [
            {
              text: this.translate.instant('001')
            }
          ]
        });

        (await alert).present();
      } else {
        const testata = new macroTestateStruct(
          this.fg.get('cod_macro')?.value,
          this.fg.get('colonne')?.value,
          this.fg.get('des_macro')?.value,
          this.fg.get('diml')?.value,
          this.fg.get('dima')?.value,
          this.fg.get('dimp')?.value,
          this.fg.get('codalt')?.value,
          1
        );
        console.log('payload testata da passare', testata)
        this.router.navigate(['macro-righe'], {state: {codice_testata: this.fg.get('cod_macro')?.value, testata: testata}})
        this.modal.dismiss()
      }
    }




  }


}
