<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>{{this.translate.instant('158')}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col size="12" text-center>
      <form [formGroup]="fg">
        <ion-item>
          <ion-label>{{this.translate.instant('030')}}</ion-label>
          <ion-input labelPlacement="stacked" readonly [value]="codice_articolo"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>{{this.translate.instant('098')}}</ion-label>
          <ion-input labelPlacement="stacked" type="number" formControlName="qta"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>{{this.translate.instant('099')}}</ion-label>
          <ion-input labelPlacement="stacked" type="text" formControlName="colonna"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>{{this.translate.instant('100')}}</ion-label>
          <ion-select interface="popover" mode="ios" formControlName="operatore">
            <ion-select-option>+</ion-select-option>
            <ion-select-option>-</ion-select-option>
            <ion-select-option>=</ion-select-option>
          </ion-select>
        </ion-item>
      </form>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' type="submit" [disabled]="!fg.valid" (click)="editRiga()" color="primary">{{this.translate.instant('117')}}</ion-button>
  </ion-toolbar>
</ion-footer>
