import { Component, Input, OnInit } from '@angular/core';
import { PreferenzaCatalogo, PreferenzaInPrint, PreferenzeService } from '../../servizi/preferenze.service';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { printLog } from 'src/app/lib';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-aggiungi-preferenza',
  templateUrl: './aggiungi-preferenza.component.html',
  styleUrls: ['./aggiungi-preferenza.component.scss'],
})
export class AggiungiPreferenzaComponent implements OnInit {

  fg: FormGroup;

  @Input() preferenzeInPrint: PreferenzaInPrint[];
  @Input() preferenzeCatalogo: PreferenzaCatalogo[];

  arrayTempCatalogo = []
  elementCatalogo;

  abilitaForm: boolean = false;
  textarea: boolean = false;
  text: boolean = false;
  timezone: boolean = false;
  list1: boolean = false;

  arrayFromString: any;
  valoreToAdd: string = '';

  preferenzaSelezionata: PreferenzaInPrint;

  constructor(public translate: TranslateService,private modalCtr: ModalController, private fb: FormBuilder, private preferenzeService: PreferenzeService) { }

  ngOnInit() {

    this.fg = this.fb.group({
      valore: ['', Validators.required]
    })

    this.preferenzeCatalogo.forEach(el => {
      this.arrayTempCatalogo.push(el.COD_PREFERENZA)
    })

    this.preferenzeInPrint = this.preferenzeInPrint.filter(item => !this.arrayTempCatalogo.includes(item.COD_PREFERENZA))


  }

  async aggiungi(preferenza: PreferenzaInPrint){
    console.log(preferenza)
      this.abilitaForm = true;
      this.preferenzaSelezionata = preferenza;
      switch(preferenza.GET_TIPO_GEST){
        case 'TEXTAREA':
          this.textarea = true;
        break;
        case 'TEXT':
          this.text = true;
        break;
        case 'TIMEZONE':
          this.timezone = true;
        break;
        case 'LIST1':
          this.list1 = true;
        break;
      }
  }

  async doAggiungiPreferenza(){
    let valore = this.fg.get('valore').value;
    console.log('valore', valore)
    //console.log('valori da passare', this.preferenzaSelezionata.COD_PREFERENZA, valore, this.preferenzaSelezionata.MODULO ?? 'HOME')
    let valoreDaPassare = JSON.stringify(valore)
    console.log('valoreDaPassare', valoreDaPassare)

    let arrayToStringify = [valore];
    console.log('arrtostring', arrayToStringify);
    await this.preferenzeService.setPreferenzaCatalogo(this.preferenzaSelezionata.COD_PREFERENZA, JSON.stringify(arrayToStringify), this.preferenzaSelezionata.MODULO ?? 'HOME', this.preferenzaSelezionata.SET_TIPO_GEST).then(()=>{this.close()})
  }

  async close(){
    this.modalCtr.dismiss()
  }

  async aggiungiValore(){
      this.arrayFromString.push(this.valoreToAdd)
      let stringToPass = this.arrayFromString.join(',');
      console.log('string to pass', stringToPass)
      this.valoreToAdd = ''
      let array = [stringToPass];
      console.log('json str of arr', JSON.stringify(array))

      const modulo = this.preferenzaSelezionata.MODULO ?? 'HOME';
      await this.preferenzeService.setPreferenzaCatalogo(this.preferenzaSelezionata.COD_PREFERENZA, JSON.stringify(array), modulo, this.preferenzaSelezionata.SET_TIPO_GEST).then(()=>{this.modalCtr.dismiss()})
  }
}
