/* eslint-disable @typescript-eslint/naming-convention */
export class errataSingolaStruct {
  public ID_CATALOGO_ERRATA: string;
  public ERRATA_JOB: string;
  public DATA_IN: number;
  public DATA_OUT: number | null;
  public FOLDER: number;
  public RESPOS: string;
  public NOTA: string;
  public ID_ROW_EC: number;
  public UUID_IN: string;
  public UUID_OUT: string | null;
  public UUID_IN_MAIL: string;
  public UUID_IN_COGNOME: string;
  public UUID_IN_NOME: string;
  public UUID_OUT_MAIL: string;
  public UUID_OUT_COGNOME: string;
  public UUID_OUT_NOME: string;
  public ML_STRING_RESULT: string | null;
  public ID_CHIARIMENTO: number | null;

  constructor(
    ID_CATALOGO_ERRATA: string,
    ERRATA_JOB: string,
    DATA_IN: number,
    DATA_OUT: number,
    FOLDER: number,
    RESPOS: string,
    NOTA: string,
    ID_ROW_EC: number,
    UUID_IN: string,
    UUID_OUT: string,
    UUID_IN_MAIL: string,
    UUID_IN_COGNOME: string,
    UUID_IN_NOME: string,
    UUID_OUT_MAIL: string,
    UUID_OUT_COGNOME: string,
    UUID_OUT_NOME: string,
    ML_STRING_RESULT: string,
    ID_CHIARIMENTO: number
  ) {
    this.ID_CATALOGO_ERRATA = ID_CATALOGO_ERRATA;
    this.ERRATA_JOB = ERRATA_JOB;
    this.DATA_IN = DATA_IN;
    this.DATA_OUT = DATA_OUT;
    this.FOLDER = FOLDER;
    this.RESPOS = RESPOS;
    this.NOTA = NOTA;
    this.ID_ROW_EC = ID_ROW_EC;
    this.UUID_IN = UUID_IN;
    this.UUID_OUT = UUID_OUT;
    this.UUID_IN_MAIL = UUID_IN_MAIL;
    this.UUID_IN_COGNOME = UUID_IN_COGNOME;
    this.UUID_IN_NOME = UUID_IN_NOME;
    this.UUID_OUT_MAIL = UUID_OUT_MAIL;
    this.UUID_OUT_COGNOME = UUID_OUT_COGNOME;
    this.UUID_OUT_NOME = UUID_OUT_NOME;
    this.ML_STRING_RESULT = ML_STRING_RESULT;
    this.ID_CHIARIMENTO = ID_CHIARIMENTO;
  }
}
