/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { OpzioniService } from 'src/app/servizi/opzioni.service';
import { opzioniStruct } from 'src/app/strutture/opzioni.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-do-nuova-opzione',
  templateUrl: './do-nuova-opzione.component.html',
  styleUrls: ['./do-nuova-opzione.component.scss'],
})
export class DoNuovaOpzioneComponent implements OnInit {

  fg: FormGroup;
  codvar: string;

  constructor(public translate: TranslateService,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private os: OpzioniService
  ) { }

  ngOnInit() {

    //! se ho il material mi deve arrivare anche data_material

    this.fg = this.fb.group({
      CODOPZ: ['', Validators.required],
      DESOPZ: ['', Validators.required],
      MATERIAL : ['']
    });
  }


  async close(message = 'Modal Closed') {
    await this.modalCtrl.dismiss(message);
  }

  async doNuovaOpzione(){
    const codopz = this.fg.get('CODOPZ').value;
    const desopz = this.fg.get('DESOPZ').value;
    const material = this.fg.get('MATERIAL').value;
    const data_material = '';
    //? manca data_material quando avrò il picker e la gallery
    this.os.nuovaOpzione(codopz, this.codvar, desopz, material, data_material);
    this.close();
  }
}
