import { Injectable } from '@angular/core';
import { CommonFunctionService } from './common-function.service';

@Injectable({
  providedIn: 'root'
})
export class EulaPrivacyService {

  constructor(
    private common: CommonFunctionService
  ) { }

  async changeEula(uuid: string){
    const response = await this.common.cambiaEula('E', uuid)
    console.log('response cambia eula', response)
  }

  async changePrivacy(uuid: string){
    const response = await this.common.cambiaEula('G', uuid)
    console.log('response cambia privacy', response)
  }

}
