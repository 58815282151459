import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LINGUA } from 'src/app/default-values';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-view-privacy',
  templateUrl: './view-privacy.component.html',
  styleUrls: ['./view-privacy.component.scss'],
})
export class ViewPrivacyComponent implements OnInit {

  utente = this.globalVar.getUTENTE_LOGIN()
  lingua = LINGUA

  constructor(public translate: TranslateService,
    private modalCtrl: ModalController,
    private globalVar: GlobalVariableService
  ) { }

  ngOnInit() {
  }

  async close(){
    await this.modalCtrl.dismiss();
  }
}
