/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataManagerService {

  INPRINT_JSON_DATA = []
  INPRINT_TXT_DATA = ""

  constructor(private http: HttpClient) {}

  errataCorrigeRequestDataJSON(
    API: string,
    CMD: string,
    AlamoDebugPrint: boolean,
    telemetria: boolean,
    userTelemetria: string
  ) {
    return this.http.get(CMD);
  }

  dataRequestRitornoCodice<T>(
    url: string,
    parametri: Map<string, string>,
    userTelemetria: string
  ) {
    const httpOptions = {
      headers: new HttpHeaders({}),
    };

    // const params = new HttpParams({encoder: new HttpUrlEncodingCodec()})

    const par = new FormData();

    parametri.forEach((value, key) => {

      par.append(key, value);
    });

    return this.http.post<T>(url, par, httpOptions);
  }

  public dataRequestAPI(API:string, CMD:string, PARAMETRI_POST:Map<string,any>,modoRisultato:number,vc:any): Observable<boolean> {

    this.initSystemDataVars();

    var errorCode = "";

    let DataQuery = API + CMD;

    console.log(DataQuery);

    return new Observable(observer => {

      this.Alamofire(DataQuery,".post",PARAMETRI_POST).subscribe(risultatoAPIdata =>{
        if(risultatoAPIdata != "") {
          switch(modoRisultato) {
            case 0:
              //JSON
              console.log(risultatoAPIdata)
              this.setINPRINT_JSON_DATA(risultatoAPIdata);
            break;
            case 1:
              //TXT
              this.setINPRINT_TXT_DATA(risultatoAPIdata);
            break;
            default:
          }
        }
        observer.next(true)
    })

    })

  }

  private Alamofire(url:string,method:string,parametri:Map<string,any>): Observable<any> {

    const headers = {
      headers: new HttpHeaders({}),
    };
    let httpResponse:any

    const par = new FormData();

    parametri.forEach((value, key) => {

      par.append(key, value);
    });

    switch(method) {
      case ".post":
        httpResponse = this.http.post<any>(url,par,headers)
      break;
      case ".get":
        httpResponse = this.http.get<any>(url)
      break;
      default:
    }
    return httpResponse
  }


  private initSystemDataVars() {

    this.setINPRINT_JSON_DATA([])
    this.setINPRINT_TXT_DATA("")

  }

  setINPRINT_JSON_DATA(jsonData:[]) {
    this.INPRINT_JSON_DATA = jsonData
  }
  setINPRINT_TXT_DATA(text:string) {
    this.INPRINT_TXT_DATA = text
  }

  getERRATACORRIGE_JSON_DATA() {
    return this.INPRINT_JSON_DATA
  }
  getERRATA_TXT_DATA() {
    return this.INPRINT_TXT_DATA
  }


}




