<ion-tabs>
  <ion-tab-bar class="ion-no-border">
    <ion-tab-button (click)="reload()" *ngIf="this.utente.MULTICAT > 2">
      <ion-icon name="grid-outline"></ion-icon>
    </ion-tab-button>
    <ion-tab-button (click)="goTo('dashboard')">
      <ion-icon name="home-outline"></ion-icon>
    </ion-tab-button>
    <ion-tab-button (click)="goTo('settings')">
      <ion-icon name="cog-outline"></ion-icon>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
