import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PopoverComponent } from 'src/app/popover/popover/popover.component';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { LayoutService } from 'src/app/servizi/layout.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-general-header',
  templateUrl: './general-header.component.html',
  styleUrls: ['./general-header.component.scss']
})
export class GeneralHeaderComponent implements OnInit {

  isMobile$ = this.layoutService.isMobile$;
  catalogoArrivato: string;
  utente;

  showSearch = false;
  resetSearch = new Subject<void>();
  value: string = ''

  private destroy = new Subject();

  constructor(public translate: TranslateService,
    private layoutService: LayoutService,
    private globalvar: GlobalVariableService,
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {
    this.utente = this.globalvar.getUTENTE_LOGIN();
    this.getNomeCatalogo()
    this.value = this.globalvar.getVALUE_RICERCA()
  }

  async getNomeCatalogo() {
    this.globalvar.CATALOGO_ATTIVO$.pipe(takeUntil(this.destroy)).subscribe(
      async (catalogo) => {
        this.catalogoArrivato = catalogo.UTENTE.NOME_CATALOGO;
      }
    );
  }

  async openPopover(e: Event) {
    const popover = await this.popoverCtrl.create({
      mode: 'ios',
      cssClass: 'bluePopover',
      component: PopoverComponent,
      event: e,
      componentProps: {
        utente: this.utente,
        catalogo: this.catalogoArrivato,
      },
    });
    await popover.present();
    await popover.onDidDismiss();
  }

}
