/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { printLog } from '../lib';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';
import { PreferenzaCatalogo } from './preferenze.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalizzazioniLTFORMService {

  //associazioni: BehaviorSubject<PreferenzaCatalogo[]> = new BehaviorSubject([]);

  catalogoAttivo = null;

  private destroy = new Subject();

  constructor(
    private commons: CommonFunctionService,
    private globalVar: GlobalVariableService,
  ) {}

  async getAssociazioni() {
      const associazioniArrivateDaApi = await this.commons.getAssociazioniLTFORM();
      // printLog('load associazioni ltform', associazioniArrivateDaApi);

      return associazioniArrivateDaApi;
  }

  async doAssociazione(catLT: string, colPL: string){
      const result = await this.commons.doAssociazioneLTFORM(catLT, colPL);
      // printLog('result do associazione', result);
  }

  async delAssociazione(catLT: string){
      const result = await this.commons.delAssociazione(catLT);
      // printLog('result del associazione', result);
  }

  async doImportaPreflightLTFORM(file: File, cod_listino: string, sovrascrivi_des: number){
      const responseImporta = await this.commons.doImportaPreflightLTFORM(file, cod_listino, sovrascrivi_des);
      // printLog('response importa preflight LTFORM', responseImporta);
      console.log(responseImporta['MESSAGGI'].length);
      if(responseImporta['MESSAGGI'].length === 0){
        // printLog('non ci sono errori, proseguo con l importazione');
        this.globalVar.setFILE_NAME_IMPORTAZIONE(responseImporta['FILE_IMPORT']);
        console.log('FILENAME set', responseImporta['FILE_IMPORT']);
      } else {
        this.globalVar.setFILE_NAME_IMPORTAZIONE(responseImporta['FILE_IMPORT']);
        // printLog('FILENAME set', responseImporta['FILENAME']);
        this.globalVar.setERRORE_IMPORTAZIONE(responseImporta['MESSAGGI']);
        responseImporta['MESSAGGI'].forEach(element => {
          console.log(element['FLAG_IMPORT']);
          console.log(element['MESSAGGIO']);
          if(element['FLAG_IMPORT'] !== 'C') {
            // printLog('non ci sono errori critici, posso proseguire');
          } else {
            // printLog('ci sono errori critici, blocco tutto');
          }
        });
      }
  }

  
}
