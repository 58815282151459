<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>Modifica riga</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="fg">
    <ion-item>
      <ion-label>Codice</ion-label>
      <ion-input labelPlacement="stacked" formControlName="cod"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Descrizione</ion-label>
      <ion-input labelPlacement="stacked" formControlName="des"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Valore in codice</ion-label>
      <ion-input labelPlacement="stacked" formControlName="valore_in_cod"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Valore in descrizione</ion-label>
      <ion-input labelPlacement="stacked" formControlName="valore_in_des"></ion-input>
    </ion-item>
  </form>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode="ios" color="primary"  (click)="doModificaRiga()">Prosegui</ion-button>
  </ion-toolbar>
</ion-footer>
