import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APPID, LINGUA } from 'src/app/default-values';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { PersonalizzazioneSediolitiService } from 'src/app/servizi/personalizzazione-sedioliti.service';
import { PersonalizzazioniLTFORMService } from 'src/app/servizi/personalizzazioni-ltform.service';
import { Messaggi } from 'src/app/strutture/importa.strutture';

@Component({
  selector: 'app-sedioliti-controllo-file',
  templateUrl: './sedioliti-controllo-file.component.html',
  styleUrls: ['./sedioliti-controllo-file.component.scss'],
})
export class SediolitiControlloFileComponent implements OnInit {

  showProgress = false;
  progressValue: number;

  file;
  codListino: string;

  es;

  prosegui: boolean;

  flag_errore: string;
  msg_errore: string;

  c_error: boolean;
  w_error: boolean;

  errorLabel: string;

  erroriArrivati: Messaggi[] = [];

  file_import: string;

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private personalizzazioniService: PersonalizzazioneSediolitiService,
    private globalVar: GlobalVariableService) { }

    async ngOnInit() {
      await this.fileControllo();
    }

    async close(message = 'Modal Closed') {
      await this.modalCtr.dismiss(message);
    }

    async fileControllo(){
      await this.personalizzazioniService.doImportaPreflightSedioliti(this.file, this.codListino);
      this.erroriArrivati = this.globalVar.getERRORE_IMPORTAZIONE();
      console.log(this.erroriArrivati);
      if(this.erroriArrivati === null){
        this.errorLabel = this.translate.instant('199');
        // printLog('mostro il pulsante per proseguire limportazione');
        this.prosegui = true;
      } else {
        this.erroriArrivati.forEach(element=> {
          this.prosegui = false;
          this.flag_errore = element.FLAG_IMPORT;
          if(this.flag_errore === 'C'){
            this.errorLabel = this.translate.instant('197');
            this.c_error = true;
            this.w_error = false;
          } else {
            this.errorLabel = this.translate.instant('198');
            this.w_error = true;
            this.c_error = false;
            this.prosegui = true;
          }
        });
      }
    }

    async doImporta(){
      this.file_import = this.globalVar.getFILE_NAME_IMPORTAZIONE();
      console.log(this.file_import);
      this.showProgress = true;

      const chiamataApi = 'https://api.price-list.it/inprintapi/api1/pers/sedioliti/doImportaSedioliti.php?APPID=' + APPID + '&ID_CATALOGO=' + this.globalVar.getIdCatalogoAttivo() + '&LINGUA=' + LINGUA +'&FILE_IMPORT=' +this.file_import + '&UUID=' + this.globalVar.getUUID() + '&DEBUG_PROGRESS=0&COD_LISTINO=' + this.codListino;
      this.es = new EventSource(chiamataApi);
      this.es.addEventListener('message', (e)=> {
        const result = JSON.parse(e.data);
        console.log(result);
      });
        this.es.addEventListener('message', (e) => {
          const result = JSON.parse(e.data);
          this.progressValue = result.progress / 100;
          console.log(this.progressValue / 100);
          // printLogresult.message);
      if(e.lastEventId === 'CLOSE') {
          // printLog('Received CLOSE closing');
          this.es.close();
          this.modalCtr.dismiss();
          const pBar = document.getElementById('progressor') as HTMLInputElement;
          pBar.value = pBar.max;
      } else {
          const pBar = document.getElementById('progressor') as HTMLInputElement;
          pBar.value = result.progress;
          const perc = document.getElementById('percentage');
          perc.innerHTML = (result.progress).toFixed(0) + '%';
          perc.style.width = (Math.floor(pBar.clientWidth * (result.progress/100)) + 15) + 'px';
      }
      });

      this.es.addEventListener('error', (e) => {
          // printLog('Error occurred', e);
          this.es.close();
      });
    }

}
