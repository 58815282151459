import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { ListiniService } from 'src/app/servizi/listini.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';
import { LTFORMControlloFileComponent } from '../ltform-controllo-file/ltform-controllo-file.component';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-ltform-importazione',
  templateUrl: './ltform-importazione.component.html',
  styleUrls: ['./ltform-importazione.component.scss'],
})
export class LTFORMImportazioneComponent implements OnInit {

  fileToImport: File;
  showBtn: boolean;

  modalDataResponse: any;

  sceltaListino;
  listini: listiniStruct[] = []
  listinoSelezionato: string;

  sovrascrivides: number = 0;

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private listiniService: ListiniService
  ) { }

  async ngOnInit() {
    this.listini = await this.listiniService.getListini()
  }

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async showButton(){
    if(this.fileToImport !== undefined){
        console.log(this.listinoSelezionato);
        if(this.listinoSelezionato !== undefined){
          console.log(this.listinoSelezionato);
          this.showBtn = true;
        }
      } else {
        this.showBtn = true;
      }
  }

  async selezionaListino($event: any) {
    this.listinoSelezionato = $event.detail.value.COD_LISTINO;
    // printLog('listino selezionato ', this.listinoSelezionato);
    this.showButton();
  }

  async importaFile($event: any){
    this.fileToImport = $event.target.files[0];
    // printLog('file', this.fileToImport);
    this.showButton();
    this.sceltaListino = true;
  }

  async prosegui(){
    const modal = await this.modalCtr.create({
      component: LTFORMControlloFileComponent,
      componentProps: {
        file: this.fileToImport,
        codListino: this.listinoSelezionato,
        sovrascrivi: this.sovrascrivides
      }
    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.modalDataResponse = modalDataResponse.data;
        console.log('Modal Sent Data : '+ modalDataResponse.data);
      }
    });

    return await modal.present();
  }

  async sovrascriviDes(e){
    const state = e.detail.checked
    if (state === true) {
      this.sovrascrivides = 1;
    } else {
      this.sovrascrivides = 0;
    }
  }

}
