import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-import-oexport',
  templateUrl: './import-oexport.component.html',
  styleUrls: ['./import-oexport.component.scss'],
})
export class ImportOExportComponent implements OnInit {

  constructor(public translate: TranslateService,
    private modalCtr: ModalController
  ) { }

  ngOnInit() {}

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async importa(){
    // const modal = await this.modalCtr.create({
    //   component: ImportaFileComponent,
    //   componentProps: {tipoImportazione}
    // });
    // modal.onDidDismiss().then((modalDataResponse) => {
    //   if (modalDataResponse !== null) {
    //     this.modalDataResponse = modalDataResponse.data;
    //     console.log('Modal Sent Data : '+ modalDataResponse.data);
    //   }
    // });
    // this.modalCtr.dismiss();
    // return await modal.present();
  }

  async esporta(){
    // console.log('importa file in ', tipoImportazione);
    // const modal = await this.modalCtr.create({
    //   component: ImportaFileComponent,
    //   componentProps: {tipoImportazione}
    // });
    // modal.onDidDismiss().then((modalDataResponse) => {
    //   if (modalDataResponse !== null) {
    //     this.modalDataResponse = modalDataResponse.data;
    //     console.log('Modal Sent Data : '+ modalDataResponse.data);
    //   }
    // });
    // this.modalCtr.dismiss();
    // return await modal.present();
  }

}
