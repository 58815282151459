/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { acquistiStruct } from '../strutture/acquisti.strutture';
import { catalogoSingoloStruct } from '../strutture/catalogoSingolo.strutture';
import { changeLogStruct } from '../strutture/changeLog.strutture';
import { chiarimentiStruct } from '../strutture/chiarimenti.strutture';
import { comuniStruct } from '../strutture/comuni.strutture';
import { errataProgettoStruct } from '../strutture/errataProgetto.strutture';
import { errataSingolaStruct } from '../strutture/errataSingola.strutture';
import { inPrintLogStruct } from '../strutture/inPrintLog.strutture';
import { invitoStruct } from '../strutture/invito.strutture';
import { invitoConCheckStruct } from '../strutture/invitoConCheck.strutture';
import { listiniStruct } from '../strutture/listini.strutture';
import { macroTestateStruct } from '../strutture/macroTestate.strutture';
import { nazioniStruct } from '../strutture/nazioni.strutture';
import { stringheStruct } from '../strutture/stringhe.strutture';
import { utenteComponentsStruct } from '../strutture/utenteComponents.strutture';
import { utenteInPrint } from '../strutture/utenteInPrint.strutture';
import { utenteErrataConCatalogoStruct } from '../strutture/utenteErrataConCatalogo.strutture';

@Injectable({
  providedIn: 'root',
})
export class CodificatoreStruttureService {
  constructor() {}

  codificaCatalogo(json: any) {
    return new catalogoSingoloStruct(
      String(json['ID_CATALOGO']),
      String(json['NOME_CATALOGO']),
      String(json['ID_SHOP'] ?? ''),
      String(json['WHO_SHOP'] ?? ''),
      Number(json['EXPIRE_DATE'] ?? 0),
      Number(json['TIPO_CATALOGO']),
      String(json['OWNER_CATALOGO']),
      Number(json['TIMESTAMP_IN']),
      Number(json['ABILITA'] ?? 0),
      Number(json['TIMESTAMP_OUT'] ?? 0),
      String(json['ID_LISTINO_STORE'] ?? '0')
    );
  }

  codificaErrata(json: any) {
    return new errataSingolaStruct(
      String(json['ID_CATALOGO_ERRATA']),
      String(json['ERRATA_JOB']),
      Number(json['DATA_IN']),
      Number(json['DATA_OUT']),
      Number(json['FOLDER'] ?? 0),
      String(json['RESPOS']),
      String(json['NOTA']),
      Number(json['ID_ROW_EC']),
      String(json['UUID_IN']),
      String(json['UUID_OUT']),
      String(json['UUID_IN_MAIL']),
      String(json['UUID_IN_COGNOME']),
      String(json['UUID_IN_NOME']),
      String(json['UUID_OUT_MAIL']),
      String(json['UUID_OUT_COGNOME']),
      String(json['UUID_OUT_NOME']),
      String(json['ML_STRING_RESULT']),
      Number(json['ID_CHIARIMENTO'])
    );
  }

  codificaProgettoErrata(json: any) {
    return new errataProgettoStruct(
      Number(json['ID_TESTATA_ERRATA']),
      String(json['ID_CATALOGO_ERRATA']),
      String(json['ERRATA_JOB']),
      Number(json['DATA_OPEN']),
      Number(json['DATA_CLOSE']),
      String(json['NOMEFILE']),
      Number(json['FOLDER']),
      String(json['UUID_USER_OPEN']),
      String(json['UUID_USER_CLOSE']),
      String(json['NOTA_TESTATA']),
      String(json['USER_OPEN']),
      String(json['USER_CLOSE']),
      Number(json['CHIARIMENTI_APERTI']),
      Number(json['PERCENTUALE_COMPLETAMENTO'])
    );
  }

  codificatoreStringhe(json: any) {
    return new stringheStruct(
      String(json['ID_STRINGA']),
      String(json['STRINGA']),
      String(json['LINGUA'])
    );
  }

  codificatoreUtente(json: any) {
    return new utenteInPrint(
      String(json['EMAIL']),
      String(json['NOME']),
      String(json['COGNOME']),
      String(json['INDIRIZZO']),
      String(json['CITTA']),
      String(json['CMB_PROVINCIE']),
      String(json['CMB_NAZIONI']),
      String(json['CMB_PROFILO']),
      String(json['TELEFONO']),
      String(json['LINGUA']),
      String(json['LISTINO']),
      String(json['MODVIEW']),
      String(json['HASHPWD']),
      Number(json['SCADENZA']),
      Number(json['PRICELIST_FLAG']),
      Number(json['ABILITA_FLAG']),
      String(json['INVITE_ID']),
      String(json['NUMERO_INDIRIZZO']),
      String(json['INTERNO_INDIRIZZO']),
      String(json['POSTAL_CODE']),
      String(json['ID_UTENTE']),
      String(json['CATALOGO_ERRATA']),
      String(json['ID_CATALOGO_ERRATA']),
      String(json['ABILITA']),
      String(json['OWNER_CATALOGO']),
      String(json['TIPO_CATALOGO']),
      String(json['SALDO_RICARICHE']),
      String(json['FOLDER']),
      String(json['ID_AVATAR']),
      String(json['LIVELLO_NOTIFICHE']),
      String(json['UUID']),
      String(json['TIPO_AUTH']),
      String(json['INI_DB']),
      String(json['NOME_CATALOGO']),
      String(json['ID_SHOP']),
      String(json['WHO_SHOP']),
      String(json['TIMESTAMP_IN']),
      String(json['TIMESTAMP_OUT']),
      String(json['ID_LISTINO_STORE']),
      String(json['ID_CATALOGO']),
      String(json['EXPIRE_DATE']),
      Number(json['TIME_EULA']),
      Number(json['TIME_PRIVACY']),
    );
  }

  codificatoreUtenteCatalogo(json: any) {
    return new utenteErrataConCatalogoStruct(
      String(json['UUID']),
      String(json['ID_CATALOGO_ERRATA']),
      String(json['ABILITA']),
      String(json['EMAIL']),
      String(json['NOME']),
      String(json['COGNOME']),
      String(json['INDIRIZZO']),
      String(json['CITTA']),
      String(json['CMB_PROVINCIE']),
      String(json['CMB_NAZIONI']),
      String(json['CMB_PROFILO']),
      String(json['TELEFONO']),
      String(json['LINGUA']),
      String(json['LISTINO']),
      String(json['MODVIEW']),
      String(json['HASHPWD']),
      Number(json['SCADENZA']),
      Number(json['PRICELIST_FLAG']),
      Number(json['ABILITA_FLAG']),
      String(json['INVITE_ID']),
      String(json['NUMERO_INDIRIZZO']),
      String(json['INTERNO_INDIRIZZO']),
      String(json['POSTAL_CODE']),
      Number(json['ID_AVATAR']),
      Number(json['LIVELLO_NOTIFICHE']),
      String(json['TIPO_AUTH']),
      String(json['NOME_CATALOGO']),
      Number(json['TIPO_CATALOGO']),
      String(json['OWNER_CATALOGO']),
      Number(json['TIMESTAMP_IN']),
      Number(json['TIMESTAMP_OUT']),
      Number(json['EXPIRE_DATE']),
      Number(json['SALDO_RICARICHE']),
      String(json['INI_DB']),
      String(json['REST_KEY'])
    );
  }

  codificatoreInvitoStruct(json: any) {
    return new invitoStruct(
      String(json['ID_INVITO']),
      Number(json['NUMERO_INVITO']),
      String(json['MAIL_INVITO']),
      String(json['NOME']),
      String(json['COGNOME']),
      Number(String(json['TIMESTAMP_IN'])),
      Number(String(json['TIMESTAMP_OUT'] ?? 0)),
      Number(String(json['STATO'])),
      String(json['ID_USER_INVITO']),
      String(json['CATALOGO_ERRATA_INVITO'])
    );
  }

  codificatoreInvitoConCheckStruct(json: any) {
    return new invitoConCheckStruct(
      String(json['ID_INVITO']),
      Number(json['NUMERO_INVITO']),
      String(json['MAIL_INVITO']),
      String(json['NOME']),
      String(json['COGNOME']),
      Number(json['TIMESTAMP_IN']),
      Number(json['TIMESTAMP_OUT'] ?? 0),
      Number(json['STATO']),
      Number(json['ID_USER_INVITO']),
      String(json['ID_CATALOGO_ERRATA']),
      String(json['CHECK_INVITO'])
    );
  }

  codificatoreComuni(json: any) {
    return new comuniStruct(
      String(json['POSTAL_CODE']),
      String(json['COMUNE_DESCRIZIONE']),
      String(json['PROVINCIA']),
      String(json['NAZIONE'])
    );
  }

  codificatoreNazioni(json: any) {
    return new nazioniStruct(
      String(json['CODICE_NAZIONE']),
      String(json['DESCRIZIONE_NAZIONE']),
      Number(json['STATE_FLAG'])
    );
  }

  codificatoreUtenteComponents(json: any) {
    return new utenteComponentsStruct(
      String(json['UTENTE'] ?? ''),
      String(json['FIELD'] ?? ''),
      String(json['CONTENT'] ?? '')
    );
  }

  codificatoreAttivita(json: any) {
    return new inPrintLogStruct(
      Number(json['ID_LOG']),
      String(json['UUID']),
      String(json['LOG_MSG_IT']),
      String(json['LOG_MSG_UK']),
      String(json['LOG_MSG_FR']),
      String(json['LOG_MSG_ES']),
      Number(json['TIMESTAMP_IN']),
      String(json['CATALOGO']),
      String(json['PROGETTO']),
      Number(json['ID_ROW_EC']),
      String(json['SUBSYS']),
      String(json['ID_CATALOGO_ERRATA'])
    );
  }

  codificatoreAcquisti(json: any) {
    return new acquistiStruct(
      String(json['ID_CATALOGO_ERRATA']),
      String(json['CODICE_PAGAMENTO']),
      String(json['CAUSALE'] ?? ''),
      String(json['DATA_FIELD_1'] ?? ''),
      String(json['DESCRIZIONE_OPERAZIONE']),
      Number(json['QUANTITA_ERRATE']),
      Number(json['IMPORTO']),
      Number(json['TIME_OPERAZIONE']),
      String(json['UUID']),
      String(json['ID_OPERAZIONE']),
      String(json['VALUTA']),
      String(json['WHO_SHOP'])
    );
  }

  codificatoreChangeLog(json: any) {
    return new changeLogStruct(
      String(json['VERSIONE']),
      Object(json['DATA_RILASCIO']),
      String(json['NOTE_RILASCIO']),
      Number(json['FLAG_MAIL'] ?? 0),
      Number(json['NUMERO_VERSIONE']),
      Number(json['TIPO'])
    );
  }

  codificatoreChiarimenti(json: any) {
    return new chiarimentiStruct(
      Number(json['ID']),
      Number(json['ID_ROW_EC']),
      String(json['UUID_IN']),
      String(json['UUID_OUT']),
      String(json['TESTO']),
      Number(json['TIME_IN']),
      Number(json['UTENTE_OUT']),
      Number(json['TIME_OUT']),
      String(json['DESTINATARIO_CHIARIMENTO']),
      String(json['TELEFONO_DESTINATARIO']),
      String(json['MAIL_UTENTE_IN']),
      String(json['NOME_UTENTE_IN']),
      String(json['COGNOME_UTENTE_IN']),
      String(json['MAIL_UTENTE_OUT']),
      String(json['COGNOME_UTENTE_OUT']),
      String(json['NOME_UTENTE_OUT'])
    );
  }

  codificatoreListini(json: any){
    return new listiniStruct(
      String(json['COD_LISTINO']),
      String(json['DESCRIZIONE_LISTINO']),
      String(json['NOME_TABELLA_SQL']),
      Number(json['BACKUP_FLAG']),
      String(json['CUSTOM_FIELD_1']),
      String(json['CUSTOM_FIELD_2']),
      String(json['CUSTOM_FIELD_3']),
      Number(json['DATA_VALIDITA'])
    );
  }

  codificatoreMacroTestate(json: any){
    return new macroTestateStruct(
      String(json['COD_MACRO']),
      String(json['COLONNE_MACRO']),
      String(json['DES']),
      Number(json['DIML']),
      Number(json['DIMA']),
      Number(json['DIMP']),
      String(json['CODALT']),
      Number(json['ABILITA'])
    );
  }
}
