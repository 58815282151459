import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CodificatoreService } from 'src/app/servizi/codificatore.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-codificatore-nuova-testata-tipologia',
  templateUrl: './codificatore-nuova-testata-tipologia.component.html',
  styleUrls: ['./codificatore-nuova-testata-tipologia.component.scss'],
})
export class CodificatoreNuovaTestataTipologiaComponent implements OnInit {

  fg: FormGroup;

  constructor(public translate: TranslateService,
    private fb: FormBuilder,
    private cs: CodificatoreService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      nome: ['', Validators.required]
    })
  }

  async doTestata(){
    await this.cs.doTestataTipologia(this.fg.get('nome').value)
    this.close()
  }

  close(){
    this.modalCtrl.dismiss()
  }
}
