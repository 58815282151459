import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MacroService } from 'src/app/servizi/macro.service';
import { macroTestateStruct } from 'src/app/strutture/macroTestate.strutture';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-edit-macro',
  templateUrl: './edit-macro.component.html',
  styleUrls: ['./edit-macro.component.css']
})
export class EditMacroComponent implements OnInit {

  @Input() macro: macroTestateStruct;
  fg: FormGroup;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private macroService: MacroService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    console.log(this.macro)
    this.fg = this.fb.group({
      des_macro: [this.macro.DES],
      codalt: [this.macro.CODALT],
      diml: [this.macro.DIML],
      dima: [this.macro.DIMA],
      dimp: [this.macro.DIMP],
      colonne: [this.macro.COLONNE_MACRO]
    })
  }

  async editTestata(){
      const des_macro = this.fg.get('des_macro').value;
      const codalt = this.fg.get('codalt').value;
      const diml = this.fg.get('diml').value;
      const dima = this.fg.get('dima').value;
      const dimp = this.fg.get('dimp').value;
      const colonne = this.fg.get('colonne').value;
      await this.macroService.editTestataMacro(this.macro.COD_MACRO, des_macro, diml, dima, dimp, colonne, codalt).then(()=>{
        this.modalCtrl.dismiss();
      })
  }

}
