import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { PreferenzeService, PreferenzaCatalogo } from 'src/app/servizi/preferenze.service';
import { righeComposizioni } from 'src/app/strutture/righeComposizioni.strutture';
import { righeMacroStruct } from 'src/app/strutture/righeMacro.strutture';
import { RicercaArticoliComponent } from '../ricerca-articoli/ricerca-articoli.component';

@Component({
  selector: 'app-modifica-riga-composizioni',
  templateUrl: './modifica-riga-composizioni.component.html',
  styleUrls: ['./modifica-riga-composizioni.component.scss'],
})
export class ModificaRigaComposizioniComponent implements OnInit {
  fg: FormGroup;
  numero_colonne: number[] = [];

  colonneCatalogo: any;


  codice: boolean = false;
  colonne_arrivate: string[] = []


  @Input() cod_testata: string;
  @Input() riga_compo: number;
  @Input() codice_arrivato: string;
  @Input() riga: righeComposizioni;

  constructor(
    public translate: TranslateService,
    private modalCtrl: ModalController,
    private preferenzeService: PreferenzeService,
    private fb: FormBuilder,
    private globalVar: GlobalVariableService
  ) {}

  ngOnInit() {
    console.log('colonne', this.riga.COLONNE.split(','))
    this.colonne_arrivate = this.riga.COLONNE.split(',');


    this.fg = this.fb.group({
      qta: [this.riga.QUANTITA],
      colonne: this.fb.array(this.colonne_arrivate.map(value => this.fb.control(value))),
      diml: [this.riga.DIML],
      dima: [this.riga.DIMA],
      dimp: [this.riga.DIMP],
      um: [this.riga.UM],
      d1: [this.riga.RDATA1],
      d2: [this.riga.RDATA2],
      d3: [this.riga.RDATA3],
      d4: [this.riga.RDATA4],
      d5: [this.riga.RDATA5]
    });

    this.getNumeroColonne();
    this.getColonneAttive();
  }

  async getColonneAttive() {
    let preferenzeCatalogo =
      await this.preferenzeService.getPreferenzeCatalogo();
    //console.log('preferenze catalogo',preferenzeCatalogo);
    preferenzeCatalogo.forEach(preferenza => {
      if(preferenza.COD_PREFERENZA == 'COLONNE_PRICELIST'){
        let colonne = preferenza.VALORE_PREFERENZA
        //console.log('colonne catalogo', colonne)

        let col = colonne.split(',')
        //console.log('colonne divise', this.colonneCatalogo)



        const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })
        this.colonneCatalogo = col.sort(sortAlphaNum)
      }
    })

  }

  async getNumeroColonne() {
    let preferenze: PreferenzaCatalogo[] =
      await this.preferenzeService.getPreferenzeCatalogo();
    preferenze.forEach((el) => {
      if (el.COD_PREFERENZA === 'QTA_COLONNE_COMPOSIZIONI') {
        console.log('colonne arrivate', el.VALORE_PREFERENZA);
        this.numero_colonne = Array.from(
          { length: Number(el.VALORE_PREFERENZA) },
          (_, index) => index + 1
        );
        //console.log('numero colonne arrivate', this.numero_colonne);

        let colonneDaAggiungere = this.numero_colonne.length - this.colonne_arrivate.length

        for (let i = 0; i < colonneDaAggiungere; i++) {
          this.addColonne();
        }
      }
    });
  }

  get campiColonneArray() {
    return this.fg.get('colonne') as FormArray;
  }

  addColonne() {
    const col = this.fb.control('');
    this.campiColonneArray.push(col);
  }

  async close() {
    const closeModal = 'Modal Closed';
    await this.modalCtrl.dismiss(closeModal);
  }

  async prepareNuovaRiga() {

    if (this.fg.valid) {
      console.log('Dati arrivati:', this.fg.value);

    } else {
      console.log('Form non valida');
    }

    // // printLog('form nuovaR', nuovaR);
  }

  editRiga(){
    this.cod_testata = this.globalVar.getCODICE_COMPO_T();
    console.log('codice testata', this.cod_testata);
    const nuovaR = new righeComposizioni(
      this.cod_testata,
      this.riga_compo,
      this.codice_arrivato,
      this.fg.get('qta').value,
      this.fg.get('colonne').value,
      this.fg.get('diml').value,
      this.fg.get('dima').value,
      this.fg.get('dimp').value,
      this.fg.get('um').value,
      this.fg.get('d1').value,
      this.fg.get('d2').value,
      this.fg.get('d3').value,
      this.fg.get('d4').value,
      this.fg.get('d5').value
    );
    console.log('payload riga', nuovaR);
    this.modalCtrl.dismiss(nuovaR)
  }


}
