<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root" [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>Nuovo modello</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="fg" (ngSubmit)="doNuovoModello()">

    <ion-item>
      <ion-label>Codice</ion-label>
      <ion-input labelPlacement="stacked" formControlName="CODMOD"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Descrizione</ion-label>
      <ion-input labelPlacement="stacked" formControlName="DES"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Data 1</ion-label>
      <ion-input labelPlacement="stacked" formControlName="DATA1"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Data 2</ion-label>
      <ion-input labelPlacement="stacked" formControlName="DATA2"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Data 3</ion-label>
      <ion-input labelPlacement="stacked" formControlName="DATA3"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Data 4</ion-label>
      <ion-input labelPlacement="stacked" formControlName="DATA4"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Data 5</ion-label>
      <ion-input labelPlacement="stacked" formControlName="DATA5"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Colonna</ion-label>
      <ion-input labelPlacement="stacked" formControlName="COMB"></ion-input>
    </ion-item>

    <ion-button mode='ios' type="submit" color="primary" [disabled]="!fg.valid">Prosegui</ion-button>
  </form>
</ion-content>
