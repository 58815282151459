/* eslint-disable @typescript-eslint/naming-convention */
export class composizioniStruct {
  public COD_COMPO: string;
  public DES: string;
  public DIML: number;
  public DIMA: number;
  public DIMP: number;
  public ABILITA: number;
  public DATA1: any;
  public DATA2: any;
  public DATA3: any;
  public DATA4: any;
  public DATA5: any;

  constructor(
    COD_COMPO: string,
    DES: string,
    DIML: number,
    DIMA: number,
    DIMP: number,
    ABILITA: number,
    DATA1: any,
    DATA2: any,
    DATA3: any,
    DATA4: any,
    DATA5: any
  ) {
    this.COD_COMPO = COD_COMPO;
    this.DES = DES;
    this.DIML = DIML;
    this.DIMA = DIMA;
    this.DIMP = DIMP;
    this.ABILITA = ABILITA;
    this.DATA1 = DATA1;
    this.DATA2 = DATA2;
    this.DATA3 = DATA3;
    this.DATA4 = DATA4;
    this.DATA5 = DATA5;
  }
}
