/* eslint-disable @typescript-eslint/naming-convention */
export class invitoDaInviareStruct {
  public ID_INVITO_SYS: string;
  public INVITE_ID: string;
  public TIMESTAMP_IN: number;
  public TIMESTAMP_OUT: number;
  public STATO: number;
  public ID_CATALOGO: string;
  public UUID_INVITO_IN: string;

  constructor(
    ID_INVITO_SYS: string,
    INVITE_ID: string,
    TIMESTAMP_IN: number,
    TIMESTAMP_OUT: number,
    STATO: number,
    ID_CATALOGO: string,
    UUID_INVITO_IN: string
  ) {
    this.ID_INVITO_SYS = ID_INVITO_SYS;
    this.INVITE_ID = INVITE_ID;
    this.TIMESTAMP_IN = TIMESTAMP_IN;
    this.TIMESTAMP_OUT = TIMESTAMP_OUT;
    this.STATO = STATO;
    this.ID_CATALOGO = ID_CATALOGO;
    this.UUID_INVITO_IN = UUID_INVITO_IN;
  }
}
