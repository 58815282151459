import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CodificatoreService } from 'src/app/servizi/codificatore.service';
import { rigaTipologieStruct } from 'src/app/strutture/riga-tipologia.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-codificatore-modifica-riga',
  templateUrl: './codificatore-modifica-riga.component.html',
  styleUrls: ['./codificatore-modifica-riga.component.scss'],
})
export class CodificatoreModificaRigaComponent implements OnInit {

  fg: FormGroup
  @Input() testata: string
  @Input() riga: rigaTipologieStruct

  constructor(public translate: TranslateService,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private cs: CodificatoreService
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      cod: [this.riga.cod, Validators.required],
      des: [this.riga.des, Validators.required],
      valore_in_cod: [this.riga.valore_in_codice, Validators.required],
      valore_in_des: [this.riga.valore_in_descrizione, Validators.required]
    })
  }

  async doModificaRiga(){
    const codice = this.fg.get('cod').value
    const descrizione = this.fg.get('des').value
    const valore_in_cod = this.fg.get('valore_in_cod').value
    const valore_in_des = this.fg.get('valore_in_des').value

    console.log('dati da passare', this.testata + ' | ' + codice + ' | ' + descrizione + ' | ' + valore_in_cod + ' | ' + valore_in_des);
    await this.cs.modificaRiga(this.testata, codice, descrizione, valore_in_cod, valore_in_des)
    this.modalCtrl.dismiss();
  }

  async close(){
    this.modalCtrl.dismiss()
  }

}
