/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { ENCRKEY } from 'src/app/default-values';
import * as shajs from 'sha.js';
// import { printLog } from 'src/app/lib';
import { utenteErrataStruct } from 'src/app/strutture/utenteErrataStruct.strutture';
import { CommonFunctionService } from 'src/app/servizi/common-function.service';
import { utenteInPrint } from 'src/app/strutture/utenteInPrint.strutture';
import { TeamService } from 'src/app/servizi/team.service';
import { NazioniProvinceService } from 'src/app/servizi/nazioni-province.service';
import { nazioniStruct } from 'src/app/strutture/nazioni.strutture';
import { provinceStruct } from 'src/app/strutture/province.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-crea-utente',
  templateUrl: './crea-utente.component.html',
  styleUrls: ['./crea-utente.component.scss'],
})
export class CreaUtenteComponent implements OnInit {

  fg: FormGroup;
  stringHashed: string;

  nazioni: nazioniStruct[];
  province: provinceStruct[];

  nazione_select;
  attiva: boolean;

  cmb_province: string;

  constructor(public translate: TranslateService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private common: CommonFunctionService,
    private alertCtrl: AlertController,
    private teamService: TeamService,
    private npService: NazioniProvinceService
  ) { }

  async ngOnInit() {
    this.fg = this.formBuilder.group({
      mail: ['', Validators.required],
      nome: ['', Validators.required],
      cognome: ['', Validators.required],
      indirizzo: [''],
      numero_indirizzo: [''],
      int_indirizzo: [''],
      citta: [''],
      cap: [''],
      provincia: [''],
      nazione: [''],
      regione: ['']
    });

    this.nazioni = await this.npService.getNazioni();
  }

  async getProvince(){
    console.log('nazione selezionata : ', this.nazione_select)
    if(this.nazione_select === 'IT'){
      this.province = await this.npService.getProvince('IT')
    } else {
      this.province = await this.npService.getProvince('US')
    }

    this.attiva = true;

  }

  async doNuovoUtente(){


      if(this.nazione_select === 'IT'){
        this.cmb_province = this.fg.get('regione').value + '|' + this.fg.get('provincia').value;
      } else {
        this.cmb_province = this.fg.get('provincia').value
      }

      let nazioneDaPassare = ''
      const nazione = this.fg.get('nazione').value
      console.log('nazione', nazione)
      if(nazione === undefined){
        nazioneDaPassare = ''
      } else {
        nazioneDaPassare = nazione
      }


      const utente = new utenteErrataStruct(
        this.fg.get('mail').value,   //EMAIL
        this.fg.get('nome').value,   //NOME
        this.fg.get('cognome').value,    //COGNOME
        this.fg.get('indirizzo').value,   //INDIRIZZO
        this.fg.get('citta').value ?? '',   //CITTA
        this.cmb_province,   //CMB_PROVINCIE
        nazioneDaPassare,   //CMB_NAZIONI
        'U',   //CMB_PROFILO
        '',   //TELEFONO
        '',   //LINGUA
        '',   //LISTINO
        '',   //MODVIEW
        '1234',    //HASHPWD
        0,    //SCADENZA
        0,    //PRICELIST_FLAG
        0,    //ABILITA_FLAG
        '',   //INVITE_ID
        this.fg.get('numero_indirizzo').value,   //NUMERO_INDIRIZZO
        this.fg.get('int_indirizzo').value,   //INTERNO_INDIRIZZO
        this.fg.get('cap').value,   //POSTAL_CODE
        0,    //MULTICAT
        0,    //ID_AVATAR
        0,    //LIVELLO_NOTIFICHE
        '',   //UUID
        'CLASS'   //TIPO_AUTH
        );
      console.log('dati arrivati da form', JSON.stringify(utente));
      const response = await this.teamService.doNewUser(utente)
      this.close()
      // printLog('response nuovo utente', response);

  }

  async close(message = 'Modal Closed') {
    await this.modalCtrl.dismiss(message);
  }

}
