import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArticoliService } from '../../servizi/articoli.service';
import { GlobalVariableService } from '../../servizi/global-variable.service';
import { articoliStruct } from '../../strutture/articoli.strutture';
// import { printLog } from '../../lib';
import { ListiniService } from '../../servizi/listini.service';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-copia-articolo',
  templateUrl: './copia-articolo.component.html',
  styleUrls: ['./copia-articolo.component.scss'],
})
export class CopiaArticoloComponent implements OnInit {

  @Input()
  codiceArticolo = null;

  formDoCopiaArticolo: FormGroup;

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private formBuilder: FormBuilder,
    private articoliService: ArticoliService,
    private alertCtrl: AlertController,
    private router: Router,
    private globalvar: GlobalVariableService
  ) { this.router.routeReuseStrategy.shouldReuseRoute = () => {
    return false;
  };}

  ngOnInit(): void {
    this.formDoCopiaArticolo = this.formBuilder.group({
      codEnd: ['', Validators.required]
    });
  }

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async doCopiaArticolo(){
    const codEnd = this.formDoCopiaArticolo.get('codEnd').value;
    console.log('codice finale', codEnd)
    await this.articoliService.copiaArticolo(this.codiceArticolo, codEnd).catch(async errore => {
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: errore.message,
        buttons: [
          {
            text : 'Close',
            role: 'close',
          }
        ]
      });
      await alert.present();
      await this.close();
    }).then(()=>{
      this.globalvar.setCODICE_ARTICOLO(codEnd);
      this.modalCtr.dismiss()
    })

  }
}
