/* eslint-disable @typescript-eslint/naming-convention */
export class acquistiStruct {
  public ID_CATALOGO_ERRATA: string;
  public CODICE_PAGAMENTO: string;
  public CAUSALE: string;
  public DATA_FIELD_1: string;
  public DESCRIZIONE_OPERAZIONE: string;
  public IMPORTO: number;
  public QUANTITA_ERRATE: number;
  public TIME_OPERAZIONE: number;
  public UUID: string;
  public ID_OPERAZIONE: string;
  public VALUTA: string;
  public WHO_SHOP: string;

  constructor(
    ID_CATALOGO_ERRATA: string,
    CODICE_PAGAMENTO: string,
    CAUSALE: string,
    DATA_FIELD_1: string,
    DESCRIZIONE_OPERAZIONE: string,
    IMPORTO: number,
    QUANTITA_ERRATE: number,
    TIME_OPERAZIONE: number,
    UUID: string,
    ID_OPERAZIONE: string,
    VALUTA: string,
    WHO_SHOP: string
  ) {
    this.ID_CATALOGO_ERRATA = ID_CATALOGO_ERRATA;
    this.CODICE_PAGAMENTO = CODICE_PAGAMENTO;
    this.CAUSALE = CAUSALE;
    this.DATA_FIELD_1 = DATA_FIELD_1;
    this.DESCRIZIONE_OPERAZIONE = DESCRIZIONE_OPERAZIONE;
    this.IMPORTO = IMPORTO;
    this.QUANTITA_ERRATE = QUANTITA_ERRATE;
    this.TIME_OPERAZIONE = TIME_OPERAZIONE;
    this.ID_OPERAZIONE = ID_OPERAZIONE;
    this.UUID = UUID;
    this.VALUTA = VALUTA;
    this.WHO_SHOP = WHO_SHOP;
  }
}
