<ion-header>
  <ion-toolbar>
    <ion-title>Eula</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
      <iframe *ngIf="lingua === 'IT'" #iframe src="https://www.iubenda.com/termini-e-condizioni/64982479" id="iframe"></iframe>
      <iframe *ngIf="lingua === 'UK'" #iframe src="https://www.iubenda.com/terms-and-conditions/69647388" id="iframe"></iframe>
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-text-center">
    <ion-button (click)="accetta()">{{this.translate.instant('257')}}</ion-button>
  </ion-toolbar>
</ion-footer>
