import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from '../search/search.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { GeneralHeaderComponent } from '../general-header/general-header.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    SearchComponent,GeneralHeaderComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule
  ],
  exports: [SearchComponent, GeneralHeaderComponent]
})
export class SharedComponentsModule { }
