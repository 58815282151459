import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LINGUA } from 'src/app/default-values';
import { EulaPrivacyService } from 'src/app/servizi/eulaPrivacy.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PRIVACYComponent implements OnInit {
  @Input() uuid: string;


  lingua = LINGUA

  constructor(public translate: TranslateService,
    private service: EulaPrivacyService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  async accetta(){
    await this.service.changePrivacy(this.uuid)
    this.modalCtrl.dismiss()
  }
}
