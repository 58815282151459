/* eslint-disable @typescript-eslint/naming-convention */
export class utenteErrataConCatalogoStruct {
  public UUID: string;
  public ID_CATALOGO_ERRATA: string;
  public ABILITA: string;
  public EMAIL: string;
  public NOME: string;
  public COGNOME: string;
  public INDIRIZZO: string;
  public CITTA: string;
  public CMB_PROVINCIE: string;
  public CMB_NAZIONI: string;
  public CMB_PROFILO: string;
  public TELEFONO: string;
  public LINGUA: string;
  public LISTINO: string;
  public MODVIEW: string;
  public HASHPWD: string;
  public SCADENZA: number;
  public PRICELIST_FLAG: number;
  public ABILITA_FLAG: number;
  public INVITE_ID: string;
  public NUMERO_INDIRIZZO: string;
  public INTERNO_INDIRIZZO: string;
  public POSTAL_CODE: string;
  public ID_AVATAR: number;
  public LIVELLO_NOTIFICHE: number;
  public TIPO_AUTH: string;
  public NOME_CATALOGO: string;
  public TIPO_CATALOGO: number;
  public OWNER_CATALOGO: string;
  public TIMESTAMP_IN: number;
  public TIMESTAMP_OUT: number;
  public EXPIRE_DATE: number;
  public SALDO_RICARICHE: number;
  public INI_DB: string;
  public REST_KEY: string;

  constructor(
    UUID: string,
    ID_CATALOGO_ERRATA: string,
    ABILITA: string,
    EMAIL: string,
    NOME: string,
    COGNOME: string,
    INDIRIZZO: string,
    CITTA: string,
    CMB_PROVINCIE: string,
    CMB_NAZIONI: string,
    CMB_PROFILO: string,
    TELEFONO: string,
    LINGUA: string,
    LISTINO: string,
    MODVIEW: string,
    HASHPWD: string,
    SCADENZA: number,
    PRICELIST_FLAG: number,
    ABILITA_FLAG: number,
    INVITE_ID: string,
    NUMERO_INDIRIZZO: string,
    INTERNO_INDIRIZZO: string,
    POSTAL_CODE: string,
    ID_AVATAR: number,
    LIVELLO_NOTIFICHE: number,
    TIPO_AUTH: string,
    NOME_CATALOGO: string,
    TIPO_CATALOGO: number,
    OWNER_CATALOGO: string,
    TIMESTAMP_IN: number,
    TIMESTAMP_OUT: number,
    EXPIRE_DATE: number,
    SALDO_RICARICHE: number,
    INI_DB: string,
    REST_KEY: string
  ) {
    this.UUID = UUID;
    this.NOME = NOME;
    this.COGNOME = COGNOME;
    this.INDIRIZZO = INDIRIZZO;
    this.CITTA = CITTA;
    this.CMB_PROVINCIE = CMB_PROVINCIE;
    this.CMB_NAZIONI = CMB_NAZIONI;
    this.CMB_PROFILO = CMB_PROFILO;
    this.TELEFONO = TELEFONO;
    this.LINGUA = LINGUA;
    this.LISTINO = LISTINO;
    this.MODVIEW = MODVIEW;
    this.HASHPWD = HASHPWD;
    this.SCADENZA = SCADENZA;
    this.PRICELIST_FLAG = PRICELIST_FLAG;
    this.ABILITA_FLAG = ABILITA_FLAG;
    this.NUMERO_INDIRIZZO = NUMERO_INDIRIZZO;
    this.INTERNO_INDIRIZZO = INTERNO_INDIRIZZO;
    this.POSTAL_CODE = POSTAL_CODE;
    this.ID_CATALOGO_ERRATA = ID_CATALOGO_ERRATA;
    this.ABILITA = ABILITA;
    this.OWNER_CATALOGO = OWNER_CATALOGO;
    this.TIPO_CATALOGO = TIPO_CATALOGO;
    this.SALDO_RICARICHE = SALDO_RICARICHE;
    this.ID_AVATAR = ID_AVATAR;
    this.LIVELLO_NOTIFICHE = LIVELLO_NOTIFICHE;
    this.EXPIRE_DATE = EXPIRE_DATE;
    this.TIMESTAMP_IN = TIMESTAMP_IN;
    this.EMAIL = EMAIL;
    this.INVITE_ID = INVITE_ID;
    this.TIPO_AUTH = TIPO_AUTH;
    this.NOME_CATALOGO = NOME_CATALOGO;
    this.TIMESTAMP_OUT = TIMESTAMP_OUT;
    this.INI_DB = INI_DB;
    this.REST_KEY = REST_KEY;
  }
}
