/* eslint-disable @typescript-eslint/naming-convention */
export class listiniStruct {
  public COD_LISTINO: string;
  public DESCRIZIONE_LISTINO: string;
  public NOME_TABELLA_SQL: string;
  public BACKUP_FLAG: number;
  public CUSTOM_FIELD_1: string;
  public CUSTOM_FIELD_2: string;
  public CUSTOM_FIELD_3: string;
  public DATA_VALIDITA: number;

  constructor(
    COD_LISTINO: string,
    DESCRIZIONE_LISTINO: string,
    NOME_TABELLA_SQL: string,
    BACKUP_FLAG: number,
    CUSTOM_FIELD_1: string,
    CUSTOM_FIELD_2: string,
    CUSTOM_FIELD_3: string,
    DATA_VALIDITA: number
  ) {
    this.COD_LISTINO = COD_LISTINO;
    this.DESCRIZIONE_LISTINO = DESCRIZIONE_LISTINO;
    this.NOME_TABELLA_SQL = NOME_TABELLA_SQL;
    this.BACKUP_FLAG = BACKUP_FLAG;
    this.CUSTOM_FIELD_1 = CUSTOM_FIELD_1;
    this.CUSTOM_FIELD_2 = CUSTOM_FIELD_2;
    this.CUSTOM_FIELD_3 = CUSTOM_FIELD_3;
    this.DATA_VALIDITA = DATA_VALIDITA;
  }
}
