export const APPID = '2389045723';
export const INPRINT_API = 'https://api.price-list.it/inprintapi/api1/';
export const VAULT_API = 'https://api.price-list.it/inprintapi/api1/vault/';
export const MILLA_API = 'https://api.price-list.it/inprintapi/api1/milla/';
export const MILLA_SERVER = 'https://milla.pricelistserver.com/milla_api/';
export const REST_API = 'https://api.price-list.it/inprintapi/api1/rest/';
export const DEEPSPACE_API = 'https://api.price-list.it/inprintapi/api1/deepSpace/';
export const INPRINT_STORAGE = 'https://api.price-list.it/avatar';
export const LINGUA = 'IT'; //Todo : hardcoded, va valutata la lingua nel browser in apertura
export const ENCRKEY = '1812741234';


//* BUILD NUMBER - DA MODIFICARE AD OGNI BUILD!!!!
export const NUMERO_VERSIONE = 119;
