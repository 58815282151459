/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { ModelliService } from 'src/app/servizi/modelli.service';
import { modelliStruct } from 'src/app/strutture/modelli.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-do-nuovo-modello',
  templateUrl: './do-nuovo-modello.component.html',
  styleUrls: ['./do-nuovo-modello.component.scss'],
})
export class DoNuovoModelloComponent implements OnInit {

  fg: FormGroup;

  constructor(public translate: TranslateService,
    private fb: FormBuilder,
    private ms: ModelliService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      CODMOD: ['', Validators.required],
      DES: ['', Validators.required],
      DATA1: [''],
      DATA2: [''],
      DATA3: [''],
      DATA4: [''],
      DATA5: [''],
      COMB: ['']
    });
  }

  async close(message = 'Modal Closed') {
    await this.modalCtrl.dismiss(message);
  }

  async doNuovoModello(){
    const m = new modelliStruct(
      this.fg.get('CODMOD')?.value,
      this.fg.get('DES')?.value,
      this.fg.get('DATA1')?.value,
      this.fg.get('DATA2')?.value,
      this.fg.get('DATA3')?.value,
      this.fg.get('DATA4')?.value,
      this.fg.get('DATA5')?.value,
      this.fg.get('COMB')?.value,
      ''
    );
    console.log('modelli payload', JSON.stringify(m));
    await this.ms.setModello(m);
    const alert = await this.alertCtrl.create({
mode: 'ios',
      header: this.translate.instant('A008'),
      buttons: [
        {
          text : this.translate.instant('001'),
          role: 'confirm',
        }
      ]
    });
    await alert.present();
    this.modalCtrl.dismiss();
  }

}
