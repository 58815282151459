/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { modelliStruct } from 'src/app/strutture/modelli.strutture';
import { CommonFunctionService } from './common-function.service';


@Injectable({
  providedIn: 'root',
})
export class ModelliService {
  constructor(private common: CommonFunctionService) {}

  async setModello(m_payload: modelliStruct) {
    const setModello = await this.common.setModello(m_payload);
    console.log('response set modello', setModello);
    if(setModello['RESULT']=== '200'){
      console.log('ricarico');
      this.getModelli();
    }
  }

  async getModelli(){
    const modelli = await this.common.getModelli();
    //console.log('get modelli', modelli)
    return modelli;
  }

  async getModello(cod_mod: string){
    const modello = await this.common.getModello(cod_mod);
    console.log('get modello', modello);
    return modello;
  }

  async getAttivazioniVarOpzModello(cod_mod: string, codvar: string){
    const attivazioni = await this.common.getAttivazioniVarOpzModello(cod_mod, codvar);
    console.log('get attivazioni varopz modello', attivazioni);
    return attivazioni;
  }

  async setAttivazioniVarOpz(cod_mod: string, codvar: string, codopz: string){
    const setAttivazioni = await this.common.setAttivazioniVarOpz(cod_mod, codvar, codopz);
    console.log('response set attivazioni var opz modello', setAttivazioni);
  }

  async delAttivazioniVarOpzModello(cod_mod: string, codvar: string, codopz: string){
    const delAttivazioni = await this.common.delAttivazioniVarOpzModello(cod_mod, codvar, codopz);
    console.log('response del attivazioni varopz modello', delAttivazioni);
  }

  async setInitVarModello(cod_mod: string, codvar: string, codopz: string){
    const setInit = await this.common.setInitVarModello(cod_mod, codvar, codopz);
    console.log('response set init var modello', setInit);
  }

  async setArticoloNelModello(cod_modello: string, codart: string){
    const setArticoloModello = await this.common.setArticoloNelModello(cod_modello, codart);
    console.log('response set articolo nel modello', setArticoloModello);
  }

  async delArticoloNelModello(cod_modello: string, codart: string){
    const delArticoloModello = await this.common.delArticoloNelModello(cod_modello, codart);
    console.log('response del articolo nel modello', delArticoloModello);
  }

  async getVarianti(){
    const varianti = await this.common.getVarianti();
    console.log('varianti arrivate', varianti);
    return varianti;
  }
}
