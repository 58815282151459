<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root"
      [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>Nuova riga</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col size="12" text-center>
      <form [formGroup]="fg" (ngSubmit)="prepareNuovaRiga()">
        <ion-item *ngIf="this.codice === false;">
          <ion-button mode='ios' (click)="ricercaArticoli()">Seleziona codice articolo
          </ion-button>
        </ion-item>
        <ion-item *ngIf="this.codice === true;">
          <ion-label>Codice</ion-label>
          <ion-input labelPlacement="stacked" [value]="this.codice_arrivato" readonly>{{this.codice_arrivato}}</ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Quantità</ion-label>
          <ion-input labelPlacement="stacked" type="number" formControlName="qta"></ion-input>
        </ion-item>

        <div formArrayName="colonne">
          <ion-item *ngFor="let colonna of campiColonneArray.controls; let i = index;">
            <ion-label>Colonna {{i+1}}</ion-label>
            <ion-select interface="popover" mode="ios" [formControlName]="i">
              <ion-select-option *ngFor="let col of colonneCatalogo">{{col}}</ion-select-option>
            </ion-select>
          </ion-item>
        </div>
        <ion-item>
          <ion-label>Larghezza</ion-label>
          <ion-input labelPlacement="stacked" type="number" formControlName="diml"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Altezza</ion-label>
          <ion-input labelPlacement="stacked" type="number" formControlName="dima"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Profondità</ion-label>
          <ion-input labelPlacement="stacked" type="number" formControlName="dimp"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Unità di misura</ion-label>
          <ion-select interface="popover" mode="ios" formControlName="um">
            <ion-select-option value="PZ">Pezzo</ion-select-option>
            <ion-select-option value="M2">Metro quadro (m<sup>2</sup>)</ion-select-option>
            <ion-select-option value="M3">Metro cubo (m<sup>3</sup>)</ion-select-option>
            <ion-select-option value="ML">Metro lineare</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label>Data 1</ion-label>
          <ion-textarea formControlName="d1"></ion-textarea>
        </ion-item>
        <ion-item>
          <ion-label>Data 2</ion-label>
          <ion-textarea formControlName="d2"></ion-textarea>
        </ion-item>
        <ion-item>
          <ion-label>Data 3</ion-label>
          <ion-textarea formControlName="d3"></ion-textarea>
        </ion-item>
        <ion-item>
          <ion-label>Data 4</ion-label>
          <ion-textarea formControlName="d4"></ion-textarea>
        </ion-item>
        <ion-item>
          <ion-label>Data 5</ion-label>
          <ion-textarea formControlName="d5"></ion-textarea>
        </ion-item>
        <ion-row class="ion-text-center"><ion-col><ion-button mode='ios' type="submit" [disabled]="!fg.valid"
              color="primary">Crea</ion-button></ion-col></ion-row>
      </form>
    </ion-col>
  </ion-row>
</ion-content>
