/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { APPID } from 'src/app/default-values';
// import { printLog } from 'src/app/lib';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { ImportaService } from 'src/app/servizi/importa.service';
import { ListiniService } from 'src/app/servizi/listini.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-esportazione',
  templateUrl: './esportazione.component.html',
  styleUrls: ['./esportazione.component.scss'],
})
export class EsportazioneComponent implements OnInit {
  listini: listiniStruct[] = [];
  listinoSelezionato: string;

  showBtn: boolean;
  showListini: boolean;

  MODO: string;

  catalogoAttivo;
  uuid;

  checked;

  modoCampoPage;

  showCampoPage: boolean = true;

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private listiniService: ListiniService,
    private importService: ImportaService,
    private globalVar: GlobalVariableService,
    private alertCtrl: AlertController
  ) {}

  async ngOnInit() {
    this.catalogoAttivo = this.globalVar.getIdCatalogoAttivo();
    this.uuid = this.globalVar.getUUID();
    this.listini = await this.listiniService.getListini();
  }

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async reset(modo: string) {
    await this.importService.resetCampoPage(modo);
  }
  async selezionaListino($event: any) {
    this.listinoSelezionato = $event.detail.value.COD_LISTINO;
    this.showBtn = true;
    this.showCampoPage = false;
  }

  modo(modo: string) {
    console.log('modo', modo);
    if (modo === 'all') {
      this.showListini = true;
      this.MODO = 'ALL';
      console.log(this.MODO);
    } else {
      this.showListini = false;
      this.MODO = 'ONLY_DES';
      this.listinoSelezionato = '';
      console.log(this.MODO);
      this.showBtn = true;
      this.showCampoPage = false;
    }
  }

  async prosegui() {
    console.log('listino selezionato', this.listinoSelezionato);
    console.log('modo selezionato', this.MODO);
    await this.importService
      .esportaART(this.listinoSelezionato, this.MODO)
      .then(async (response) => {
        console.log(response);
        const risp = JSON.stringify(response).split(':').pop().replace('}', '');
        if (risp === '"E409"') {
          console.log('Campo PAGE non alimentato, non posso proseguire');
          const alert = await this.alertCtrl.create({
            mode: 'ios',
            message: this.translate.instant('201'),
            buttons: ['OK'],
          });
          await alert.present();
        } else {
          console.log('posso proseguire');
          const alert = await this.alertCtrl.create({
            mode: 'ios',
            header: 'L\'esportazione è in corso',
            message:
              'Attendere la notifica via e-mail per scaricarla dall\'area download dei Files',
            buttons: [
              {
                text: this.translate.instant('001'),
              },
            ],
          });
          await (await alert).present();
          await (await alert).onDidDismiss();
        }
      });
    this.showBtn = true;
    this.modalCtr.dismiss();
  }

}
