<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>Modifica utente</ion-title>
    <ion-button mode='ios' slot="end" (click)="modalCtrl.dismiss()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row *ngIf="this.utente.time_agree > 0" class="green ion-text-center">
    <ion-icon name="checkmark-circle-outline" color="success"></ion-icon>
    L'utente ha confermato l'invito
  </ion-row>
  <ion-row *ngIf="this.utente.time_agree === 0" class="red ion-text-center">
    <ion-icon name="close-circle-outline" color="danger"></ion-icon>
    L'utente non ha confermato l'invito
  </ion-row>

  <ion-row>
    <ion-col size="6">
      <ion-item>
        <ion-input labelPlacement="stacked" label="Nome" labelPlacement="stacked" [(ngModel)]="nome"></ion-input>
      </ion-item>
    </ion-col>
    <ion-col size="6">
      <ion-item>
        <ion-input labelPlacement="stacked" label="Cognome" labelPlacement="stacked" [(ngModel)]="cognome"></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <ion-item>
        <ion-input labelPlacement="stacked" label="Mail" labelPlacement="stacked" [(ngModel)]="mail"></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6">
      <ion-item>
        <ion-input labelPlacement="stacked" label="Listino" labelPlacement="stacked" [value]="this.utente.listino" readonly></ion-input>
      </ion-item>
    </ion-col>
    <ion-col size="2">
      <ion-item>
        <ion-input labelPlacement="stacked" label="Lingua" labelPlacement="stacked" [value]="this.utente.lingua" readonly></ion-input>
      </ion-item>
    </ion-col>
    <ion-col size="4">
      <ion-item>
        <ion-input labelPlacement="stacked" label="Data di validità" labelPlacement="stacked" [value]="this.utente.data_validita_listino * 1000 | date: 'dd/MM/yyyy'" readonly></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6">
        <ion-item>
          <ion-input labelPlacement="stacked" label="Data attivazione" labelPlacement="stacked" [value]="this.utente.time_in * 1000 | date: 'dd/MM/yyyy hh:mm'" readonly></ion-input>
        </ion-item>
    </ion-col>
    <ion-col size="6">
        <ion-item>
          <ion-input labelPlacement="stacked" label="Data scadenza" labelPlacement="stacked" [value]="this.utente.time_out * 1000 | date: 'dd/MM/yyyy hh:mm'" readonly></ion-input>
        </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-item>
        <ion-input labelPlacement="stacked" label="Link di invito" labelPlacement="stacked" id="link_invito" [value]="this.site" readonly></ion-input>
        <ion-icon slot="end" name="copy-outline" (click)="copy()"></ion-icon>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="4">
      <ion-item>
        <ion-input labelPlacement="stacked" label="Codice" labelPlacement="stacked" [value]="this.utente.code" [value]="this.utente.code" readonly></ion-input>
      </ion-item>
    </ion-col>
    <ion-col size="8">
      <ion-button expand="block" (click)="resend()" fill="outline">Rimanda invito
        <ion-icon slot="end" name="send"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-button mode="ios" expand="block" type="submit" mode="ios"
      (click)="doEditUser()">Salva</ion-button>
  </ion-toolbar>
</ion-footer>
