import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PersonalizzazioniSantaluciaService } from 'src/app/servizi/personalizzazioni-santalucia.service';

@Component({
  selector: 'app-santalucia-nuova-associazione',
  templateUrl: './santalucia-nuova-associazione.component.html',
  styleUrls: ['./santalucia-nuova-associazione.component.scss'],
})
export class SantaluciaNuovaAssociazioneComponent implements OnInit {
  associazioni;

  CAT_LT: string;
  COL_PL: string;

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private personalizzazioniService: PersonalizzazioniSantaluciaService
  ) { }

  async ngOnInit() {
    this.associazioni = await this.personalizzazioniService.getAssociazioni()
  }

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async doAssociazione(){
    console.log('dati inseriti : CAT_LT = ' + this.CAT_LT + ' E COL_PL = ' + this.COL_PL);
    await this.personalizzazioniService.doAssociazione(this.CAT_LT, this.COL_PL).then(()=>{
      this.modalCtr.dismiss();
    });
  }
}
