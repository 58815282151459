<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root" [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>Nuova variante</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="fg" (ngSubmit)="doNuovaVariante()">

    <ion-item>
      <ion-label>Codice</ion-label>
      <ion-input labelPlacement="stacked" formControlName="CODVAR"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Descrizione</ion-label>
      <ion-input labelPlacement="stacked" formControlName="DESVAR"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Alias</ion-label>
      <ion-input labelPlacement="stacked" formControlName="ALIAS"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Traduzione gestionale</ion-label>
      <ion-input labelPlacement="stacked" formControlName="TRADGEST"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Tipo variante</ion-label>
      <ion-input labelPlacement="stacked" formControlName="TIPO_VARIANTE"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Nota</ion-label>
      <ion-input labelPlacement="stacked" formControlName="NOTA"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Ordinamento</ion-label>
      <ion-input labelPlacement="stacked" formControlName="ORDINAMENTO"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Opzioni fissate</ion-label>
      <ion-input labelPlacement="stacked" formControlName="OPZ_FIXED"></ion-input>
    </ion-item>

    <ion-button mode='ios' type="submit" color="primary" [disabled]="!fg.valid">Prosegui</ion-button>
  </form>
</ion-content>
