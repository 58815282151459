// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  devPassword: '8947260a95b4511c21b4ce7aeb470d113c05dda7020beb3514250a9334a10bc7',
  printDebug: true,
  firebase: {
    apiKey: "config data from general tab",
    authDomain: "config data from general tab",
    databaseURL: "config data from general tab",
    projectId: "config data from general tab",
    storageBucket: "config data from general tab",
    messagingSenderId: "config data from general tab",
    appId: "config data from general tab",
    measurementId: "config data from general tab",
    vapidKey: "generated key pair on Cloud Messaging tab"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
