<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-title>{{this.translate.instant('183')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid>
    <!-- <ion-row>
      <ion-col>
        <ion-text *ngIf="showCampoPage === true">{{this.translate.instant('201')}}</ion-text>
      </ion-col>
    </ion-row> -->
    <ion-row class="ion-text-center">
      <ion-col>
        <ion-button mode='ios'  color="secondary" class="btn" text-wrap (click)="modo('all')">{{this.translate.instant('192')}}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-text-center">
      <ion-col>
        <ion-button mode='ios'  color="secondary" class="btn" text-wrap (click)="modo('onlyDes')">{{this.translate.instant('193')}}
        </ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>


  <ion-grid *ngIf="showListini === true">
    <ion-row>
      <ion-col size="12">
        <ion-select interface="popover" placeholder="Seleziona il listino" (ionChange)="selezionaListino($event)">
          <ion-select-option [value]="listino" *ngFor="let listino of listini">{{ listino.COD_LISTINO }}</ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>
<ion-footer class="ion-no-border">
  <ion-row>
    <ion-col class="ion-text-center" *ngIf="showCampoPage === true">
      <ion-button color="primary" class="btn" mode="ios" (click)="reset('ART')">{{this.translate.instant('194')}}</ion-button>
      <!-- <ion-label for="checkbox">Reset campo page *</ion-label>
      <ion-checkbox id="checkbox" color="secondary" (ionChange)="reset($event, 'ART')" required>Reset campo page</ion-checkbox> -->
    </ion-col>
  </ion-row>
  <ion-row *ngIf="showBtn === true" class="ion-text-center">
    <ion-col size="12">
      <ion-button mode='ios' class="btn" color="primary"  (click)="prosegui()">{{this.translate.instant('160')}}</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
