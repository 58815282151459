import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
// import { printLog } from '../lib';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';
import { ricercaStruct } from '../strutture/ricerca.strutture';

@Injectable({
  providedIn: 'root'
})
export class RicercaService {

  risultati: BehaviorSubject<ricercaStruct> = new BehaviorSubject(null);

  private destroy = new Subject();

  constructor(
    private commons: CommonFunctionService,
    private globalVar: GlobalVariableService
  ) {}

  async ricerca(text: string) {
    const responseRicerca = await this.commons.ricerca(text);

    this.risultati.next(responseRicerca);

    return responseRicerca;
  }


}
