/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { IntestazioniService } from 'src/app/servizi/intestazioni.service';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
@Component({
  selector: 'app-intestazioni-lingua-insert',
  templateUrl: './intestazioni-lingua-insert.component.html',
  styleUrls: ['./intestazioni-lingua-insert.component.scss'],
})
export class IntestazioniLinguaInsertComponent implements OnInit {

  data;
  index;
  linguaEdit;
  linguePerViewer;
  codiceIntestazione;

  modalDataResponse: any;

  roleMessage: ''

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private intestazioniService: IntestazioniService,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private router: Router,
    private globalvar: GlobalVariableService
  ) { }

  ngOnInit() {}

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async salvaDescrizione(codiceDescrizione: string, valore_des: string) {
    console.log(valore_des);
    const response = await this.intestazioniService.setDescrizioneIntestazione(this.codiceIntestazione, codiceDescrizione, valore_des); //200
  }

  getNomeDescrizione(index: any, lingua: string) {
    return lingua + '_DES' + (index === 0 ? '' : index);
  }

  async copia() {
    const alert = await this.alertCtrl.create({
    mode: 'ios',
      header: this.translate.instant('A003'),
      buttons: [{
        text: 'Salva',
        handler: data =>{
        this.roleMessage = data;
        }
    }],
      inputs: [
        {
          placeholder: this.translate.instant('264')
        }
      ]
    });
    await alert.present();

    const {role} = await alert.onDidDismiss();
    console.log(this.roleMessage[0]);
    const cod_end = this.roleMessage[0];
    const doCopia = await this.intestazioniService.doCopiaintestazione(this.codiceIntestazione, cod_end);
  }

  copy(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.codiceIntestazione;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.presentToast()
  }


  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: 'Articolo copiato!',
      duration: 1500,
      position: 'top',
      mode:'ios'
    });

    await toast.present();
  }

  async nuovaIntestazione() {
    this.globalvar.setBACK(true)
    this.router.navigate(['nuova-intestazione'])
  }

}
