/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ENCRKEY } from 'src/app/default-values';
// import { printLog } from 'src/app/lib';
import { CommonFunctionService } from 'src/app/servizi/common-function.service';
import * as shajs from 'sha.js';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
})
export class PasswordRecoveryComponent implements OnInit {

  MAIL_TO_CHECK: string;
  CODICE_RECOVERY: number;
  NUOVA_PASSWORD: string;
  CONFERMA_PASSWORD: string;

  mail: boolean;
  codice: boolean;
  password: boolean;

  stringHashed: string;

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private commons: CommonFunctionService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    this.mail = true;
  }

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async mailCheck(){
    const response = await this.commons.checkUserExist(this.MAIL_TO_CHECK);
    // printLog('mail', this.MAIL_TO_CHECK);
    const risp = JSON.stringify(response).split(':').pop().replace('}', '');
    if(risp === '"200"'){
      // printLog('risp', risp);
      //! la mail non esiste
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: this.translate.instant('A014'),
        subHeader: 'controlla che l\'indirizzo inserito sia corretto',
        buttons: [{ text: this.translate.instant('001')}]
      });
      await (await alert).present();

    } else {
      //! la mail esiste, proseguo
      this.codSentCheck();
      this.mail = false;
      this.codice = true;
    }
  }

  async codSentCheck(){
    const response = await this.commons.inviaNumRecoveryPassword(this.MAIL_TO_CHECK);
    const risp = JSON.stringify(response).split(':').pop().replace('}', '');
    console.log('rispo', risp)
  }

  async codCheck(){
    const response = await this.commons.checkNumRecovery(this.MAIL_TO_CHECK, this.CODICE_RECOVERY)
    console.log(response)
    const risp = JSON.stringify(response).split(':').pop().replace('}', '');
    console.log('controllo codice', risp)
    if(risp === '"200"'){
      this.mail = false;
      this.codice = false;
      this.password = true;
    }
  }

  async passwordCheck(){
    if(this.NUOVA_PASSWORD === this.CONFERMA_PASSWORD){
      console.log('password', this.NUOVA_PASSWORD)
      console.log('le password corrispondono, posso proseguire con l\'api');
      const response = await this.commons.doChangePassword(this.NUOVA_PASSWORD, this.MAIL_TO_CHECK)
      console.log('response password', response)
      window.location.reload();
    } else {
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: 'Le password non corrispondono',
        buttons: [{ text: 'ok'}]
      });
      await (await alert).present();

    }
  }
}
