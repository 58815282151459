import { Injectable } from '@angular/core';
import { nazioniStruct } from '../strutture/nazioni.strutture';
import { provinceStruct } from '../strutture/province.strutture';
import { CommonFunctionService } from './common-function.service';

@Injectable({
  providedIn: 'root'
})
export class NazioniProvinceService {

  constructor(
    private common: CommonFunctionService
  ) { }

  async getNazioni(){
    const nazioni = await this.common.ritornaNazioni();
    console.log('nazioni', nazioni)
    return nazioni;
  }

  async getProvince(nazione: string){
    const province = await this.common.ritornaStati(nazione);
    console.log('province / stati', province)
    return province;
  }

}
