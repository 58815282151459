import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonFunctionService } from 'src/app/servizi/common-function.service';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { utenteInPrint } from 'src/app/strutture/utenteInPrint.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-tabs-menu',
  templateUrl: './tabs-menu.component.html',
})
export class TabsComponent implements OnInit {

  private destroy$ = new Subject();

  utente;

  constructor(public translate: TranslateService,
    private router: Router,
    private globalVar: GlobalVariableService
  ) { }

  async ngOnInit() {
    this.utente = this.globalVar.getUTENTE_LOGIN()
  }

  goTo(route: string) {
    this.router.navigate([route]);
  }

  reload(){
    window.location.reload();
  }
}
