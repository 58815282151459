import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { APPID } from 'src/app/default-values';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { ImportaService } from 'src/app/servizi/importa.service';
import { EsportazioneComponent } from '../esportazione/esportazione.component';
import { ListiniService } from 'src/app/servizi/listini.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-scelta-esportazione',
  templateUrl: './scelta-esportazione.component.html',
  styleUrls: ['./scelta-esportazione.component.scss'],
})
export class SceltaEsportazioneComponent implements OnInit {

  modalDataResponse: any;

  handlerMessage = '';

  catalogoAttivo;
  uuid;

  winnerSelected: boolean = false;
  proseguiBtn: boolean = false;
  listini: listiniStruct[] = []
  listinoSelezionato: string = ''

  constructor(public translate: TranslateService,
    private globalVar: GlobalVariableService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private importService: ImportaService,
    private listiniService: ListiniService
  ) {}

  ngOnInit() {
    this.catalogoAttivo = this.globalVar.getIdCatalogoAttivo();
    this.uuid = this.globalVar.getUUID();
  }

  async close(message = 'Modal Closed') {
    await this.modalCtrl.dismiss(message);
  }

  async articoli() {
    const modal = await this.modalCtrl.create({
      component: EsportazioneComponent
    });
    modal.onDidDismiss().then((modalDataResponse) => {
      if(modalDataResponse !== null){
        this.modalDataResponse = modalDataResponse.data;
        console.log('Modal Sent Data : ' + modalDataResponse.data);
      }
    });
    return await modal.present();
  }

  async reset(modo: string){
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      header: this.translate.instant('200'),
      buttons: [
        {
          text : this.translate.instant('002'),
          role: 'annulla',
        },
        {
          text : this.translate.instant('155'),
          role: 'confirm',
          handler: () => {this.handlerMessage = 'cancel';}
        }
      ]
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if(role === 'confirm'){
      await this.importService.resetCampoPage(modo);
      this.intestazioni();
    }
  }

  async intestazioni() {
    await this.importService.esportaINT()
    this.modalCtrl.dismiss();
  }

  async winner(){
    this.winnerSelected = true;
    this.getListini()
  }

  async getListinoSelezionato(e){
    this.listinoSelezionato = e.detail.value
    console.log('selezionato listino', this.listinoSelezionato)
    this.proseguiBtn = true;
  }

  async prosegui(){
    await this.importService.esportaWINNER(this.listinoSelezionato).then(()=>{
      this.modalCtrl.dismiss()
    })
  }

  async getListini(){
    this.listini = await this.listiniService.getListini()
  }
}
