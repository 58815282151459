import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { PersonalizzazioniDvoService } from 'src/app/servizi/personalizzazioni-dvo.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-dvo-esportazione',
  templateUrl: './dvo-esportazione.component.html',
  styleUrls: ['./dvo-esportazione.component.css']
})
export class DvoEsportazioneComponent implements OnInit {

  showBtn: boolean = false;

  fg: FormGroup

  constructor(public translate: TranslateService,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private dvoService: PersonalizzazioniDvoService
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      da: ['', Validators.required],
      a: ['', Validators.required]
    })
  }

  async prosegui(){
    let da = this.fg.get('da').value;
    let a = this.fg.get('a').value;
    console.log('da :' + da + ' a : ' + a)
    await this.dvoService.esportazioneDVO(da, a).then(() =>{
      this.modalCtrl.dismiss()
    })
  }

  close(){
    this.modalCtrl.dismiss()
  }

}
