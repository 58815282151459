/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GlobalVariableService } from './global-variable.service';
import { takeUntil } from 'rxjs/operators';
// import { printLog } from '../lib';
import { CommonFunctionService } from './common-function.service';
import { CodificatoreStruttureService } from './codificatore-strutture.service';
import { listiniStruct } from '../strutture/listini.strutture';
import { AlertController } from '@ionic/angular';
import { StringheService } from './stringhe.service';
import { LINGUA } from '../default-values';
import { TranslateService } from '@ngx-translate/core';
import { calcoliStruct } from '../strutture/calcoli.strutture';

@Injectable({
  providedIn: 'root',
})
export class ListiniService {
  catalogoAttivo = null;
  handlerMessage = '';

  private destroy = new Subject();

  constructor(
    private commons: CommonFunctionService,
    private globalVars: GlobalVariableService,
    private codificatore: CodificatoreStruttureService,
    private alertCtrl: AlertController,
    private translate: TranslateService
  ) {}

  async getListini() {
    const listini = await this.commons.getListini();
    console.log('listini arrivati', listini);
    return listini;
  }

  async getListino(cod_listino: string){
    const listino = await this.commons.getListino(cod_listino);
    console.log('listino arrivato', listino);
    return listino;
  }

  async getListinoPerPagina(cod_listino: string, pagina: number, step_pagine: number, codart: string){
    const listinoPerPagina = await this.commons.getListinoPerPagina(cod_listino, pagina, step_pagine, codart)
    console.log('listino per pagina', listinoPerPagina)
    return listinoPerPagina;
  }

  async nuovoListino(cod_listino: string, des_listino: string) {
    const responseNuovoListino = await this.commons.nuovoListino(
      cod_listino,
      des_listino
    );
    console.log('responseNuovoListino', responseNuovoListino);
    const risp = JSON.stringify(responseNuovoListino)
      .split(':')
      .pop()
      .replace('}', '')
      .slice(1, -1);
    // printLog('risposta do nuovo listino', risp);
    if (risp === '200') {
      await this.getListini();
    } else {
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: this.translate.instant('A004'),
        message: this.translate.instant(risp),
        buttons: ['Ok'],
      });
      await alert.present();
    }
  }

  async setValiditaListino(cod_listino: string, data_validita: number) {
    const responseSetValidita = await this.commons.setValiditaListino(cod_listino, data_validita);
    console.log('ripsosta set validita listino', responseSetValidita)
  }

  async svuotaPrezzi(cod_listino: string) {
    const responseSvuotaPrezzi = await this.commons.svuotaPrezziListino(
      cod_listino
    );
    const risp = JSON.stringify(responseSvuotaPrezzi)
      .split(':')
      .pop()
      .replace('}', '')
      .slice(1, -1);
    // printLog('ripsosta svuota prezzi', risp);
    if (risp === '200') {
      await this.getListini();
    } else {
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: this.translate.instant('A004'),
        message: this.translate.instant(risp),
        buttons: ['Ok'],
      });
      await alert.present();
    }
  }

  async eliminaListino(cod_listino: string) {
    const responseEliminaListino = await this.commons.eliminaListino(
      cod_listino
    );
    const risp = JSON.stringify(responseEliminaListino)
      .split(':')
      .pop()
      .replace('}', '')
      .slice(1, -1);
    // printLog('risposta elimina listino', risp);
    if (risp === '200') {
      await this.getListini();
    } else {
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: this.translate.instant('A004'),
        message: this.translate.instant(risp),
        buttons: ['Ok'],
      });
      await alert.present();
    }
  }

  async duplicaListino(
    cod_listino_start: string,
    cod_listino_end: string,
    des_listino_end: string
  ) {
    const responseDuplicaListino = await this.commons.duplicaListino(
      cod_listino_start,
      cod_listino_end,
      des_listino_end
    );
    const risp = JSON.stringify(responseDuplicaListino)
      .split(':')
      .pop()
      .replace('}', '')
      .slice(1, -1);
    // printLog('risposta duplica listino', risp);
    if (risp === '200') {
      await this.getListini();
    } else {
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: this.translate.instant('A004'),
        message: this.translate.instant(risp),
        buttons: ['Ok'],
      });
      await alert.present();
    }
  }

  async doListinoCalcolatoBg(calcoli_payload: calcoliStruct){
    const response = await this.commons.doListinoCalcolatoBg(calcoli_payload)
    console.log('response listino calcolato', response)
    const risp = JSON.stringify(response).split(':').pop().replace('}', '');
        console.log('risp in then',risp)
        if (risp === '"E413"') {
          const alert = await this.alertCtrl.create({
            mode: 'ios',
            header: this.translate.instant('E001'),
            message: this.translate.instant('E413'),
            buttons: [
              {
                text: 'ok'
              }
            ]
          })
          await alert.present()
        } else {
          const alert = await this.alertCtrl.create({
            mode: 'ios',
            header: this.translate.instant('A020'),
            message:
              "Attendere la notifica via e-mail per visualizzarlo dall'area listini",
            buttons: [
              {
                text: 'ok',
              },
            ],
          });
          await (await alert).present();
        }
  }
}
