import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListiniService } from 'src/app/servizi/listini.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-set-validita-listino',
  templateUrl: './set-validita-listino.component.html',
  styleUrls: ['./set-validita-listino.component.scss'],
})
export class SetValiditaListinoComponent implements OnInit {

  cod_listino: string;
  timestampToPass: number;

  constructor(
    public translate: TranslateService,
    public listiniService: ListiniService,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  getDate(e: any){
    let date =  e.detail.value;
    this.timestampToPass = new Date(date).getTime() / 1000;
    console.log('timestamp to pass', this.timestampToPass);
  }

  async saveDate(){
    await this.listiniService.setValiditaListino(this.cod_listino, this.timestampToPass).then(()=>{
      this.modalCtrl.dismiss();
    });
  }

}
