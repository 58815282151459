export class codificatoreStruct {
  public CODICE: string;
  public DESCRIZIONE_GESTIONALE: string;
  public DIML: number;
  public DIMA: number;
  public DIMP: number;
  public PESOL: number;
  public PESON: number;
  public VOL: number;
  public COLLI: number;
  public DATA1: string;
  public DATA2: string;
  public DATA3: string;
  public DATA4: string;
  public COMPONENTI: string;
  public FLAG: number;

  constructor(
    CODICE: string,
    DESCRIZIONE_GESTIONALE: string,
    DIML: number,
    DIMA: number,
    DIMP: number,
    PESOL: number,
    PESON: number,
    VOL: number,
    COLLI: number,
    DATA1: string,
    DATA2: string,
    DATA3: string,
    DATA4: string,
    COMPONENTI: string,
    FLAG: number
  ) {
    this.CODICE = CODICE;
    this.DESCRIZIONE_GESTIONALE = DESCRIZIONE_GESTIONALE;
    this.DIML = DIML;
    this.DIMA = DIMA;
    this.DIMP = DIMP;
    this.PESOL = PESOL;
    this.PESON = PESON;
    this.VOL = VOL;
    this.COLLI = COLLI;
    this.DATA1 = DATA1;
    this.DATA2 = DATA2;
    this.DATA3 = DATA3;
    this.DATA4 = DATA4;
    this.COMPONENTI = COMPONENTI;
    this.FLAG = FLAG;
  }

}
