import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/servizi/loader.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit{

  isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(public translate: TranslateService,private loaderService: LoaderService) {

  }
  ngOnInit(): void {
    console.log('i am loading')
  }


}
