import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctionService } from 'src/app/servizi/common-function.service';
import { MacroService } from 'src/app/servizi/macro.service';
import { macroTestateStruct } from 'src/app/strutture/macroTestate.strutture';

@Component({
  selector: 'app-copia-testata',
  templateUrl: './copia-testata.component.html',
  styleUrls: ['./copia-testata.component.css']
})
export class CopiaTestataComponent implements OnInit {
  @Input() cod_start: string;
  fg: FormGroup;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private macroService: MacroService,
    private modalCtrl: ModalController,
    private common: CommonFunctionService
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      cod_end: ['', Validators.required],
      des_macro: ['', Validators.required],
      codalt: ['', Validators.required],
      diml: ['', Validators.required],
      dima: ['', Validators.required],
      dimp: ['', Validators.required]
    })
  }

  async copiaTestata(){
    const cod_end = this.fg.get('cod_end').value;
      const des_macro = this.fg.get('des_macro').value;
      const codalt = this.fg.get('codalt').value;
      const diml = this.fg.get('diml').value;
      const dima = this.fg.get('dima').value;
      const dimp = this.fg.get('dimp').value;

      this.common.copiaTestataMacro(this.cod_start, cod_end, des_macro, codalt, diml, dima, dimp).subscribe((completamento)=>{
        if(completamento){
          console.log('ho copiato la macro')
          this.modalCtrl.dismiss();
        } else {
          console.log('errore nell copia')
        }
      })

  }

}
