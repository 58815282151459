import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChangelogService } from 'src/app/servizi/changelog.service';
import { changeLogStruct } from 'src/app/strutture/changeLog.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent implements OnInit {

  changelog: changeLogStruct[]

  text;

  constructor(public translate: TranslateService,
    private cs: ChangelogService,
    private modalCtrl: ModalController
  ) { }

  async ngOnInit() {
    this.changelog = await this.cs.getChangelog();
    this.text = this.changelog[0].NOTE_RILASCIO
  }

  close(){
    this.modalCtrl.dismiss()
  }

}
