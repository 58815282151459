/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { opzioniStruct } from '../strutture/opzioni.strutture';
import { CommonFunctionService } from './common-function.service';

@Injectable({
  providedIn: 'root'
})
export class OpzioniService {

  opzioni: BehaviorSubject<opzioniStruct[]> = new BehaviorSubject([]);

  constructor(
    private common: CommonFunctionService
  ) {
        // todo: quando passi in inPrint aggiungi il controllo per il catalogo attivo
   }

   async nuovaOpzione(codopz: string, codvar: string, desopz: string, material: string, data_material: string){
    const responseNuovaOpzione = await this.common.nuovaOpzione(codopz, codvar, desopz, material, data_material);
    console.log('response nuova opzione', responseNuovaOpzione);
   }

   async editOpzione(cod_o: string, cod_v: string, campo_o: string, valore_o: string){
    const responseEditOpzione = await this.common.editOpzione(cod_o, cod_v, campo_o, valore_o);
    console.log('response edit opzione', responseEditOpzione);
   }

   async editInsValoreOpzione(cod_o: string, cod_v: string, col: string, val: string){
    const responseEditInsValoreOpzione = await this.common.editInsValoreOpzione(cod_o, cod_v, col, val);
    console.log('response edit ins val opz', responseEditInsValoreOpzione);
   }
}
